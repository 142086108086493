import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions/Settings";
import "react-datepicker/dist/react-datepicker.css";
import RichTextEditor from 'react-rte';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import convert from 'htmr';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function AddNewEmailItem(props) {
  useEffect(() => {
    if (props.addCommonStatus === 1) {
      setTimeout(function () {
        props.setFieldValues("addCommonStatus", 0);
        props.setFieldValues("email_modal_show", false);
        props.reset_common_info();
      }, 2000);
    }
  }, [props.addCommonStatus]);

  function uploadImageCallBack(file) {
    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://api.imgur.com/3/image');
        xhr.setRequestHeader('Authorization', 'Client-ID ##clientid###');
        const data = new FormData();
        data.append('image', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          console.log(response)
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          console.log(error)
          reject(error);
        });
      }
    );
  }
  
  const addNewEmailItem = (event) => {
    event.preventDefault();
    if (validItemInfo()) {
      props.addNewEmailItem({
        email_tag: props.email.email_tag,
        email_content: props.email.email_content,
      });
    }
  };

  const validItemInfo = () => {
    let valid = true;
    let errors = props.addCommonErrors;
    if (!props.email.email_tag) {
      valid = false;
      errors.email_tag = "Cannot be Empty";
    } else {
      errors.email_tag = "";
    }
    if (!props.email.email_content) {
      valid = false;
      errors.email_content = "Cannot be Empty";
    } else {
      errors.email_content = "";
    }
    props.updateCommonSettingErrors(errors);
    return valid;
  };

  const [email, setEmail] = useState('')

  const onChange = (value) => {
    setEmail(value);
    props.setFieldValues("email", {
      ...props.email,
      email_content: draftToHtml(convertToRaw(value.getCurrentContent())),
    });
  };

  return (
    <Modal
      show={props.show}
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      size="lg"
      aria-hidden="true"
      id="newcurrency"
      aria-labelledby="newcurrency"
      tabIndex={-1}
      onHide={() => props.setFieldValues("email_modal_show", false)}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="10f7a9cc-2e4c-4d9f-ad5b-66d2c71b3896_title"
            >
              Create New
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div>
                <form
                  method="POST"
                  action="https://crm2.gbsc.geneasol-apps.info/dashboard/setting/%7Bsetting%7D/emailtemplate"
                  acceptCharset="UTF-8"
                  className="form-horizontal"
                  id="form"
                >
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="F3AwN3mnzZZGeheR5gRty4f9fSSfjanNtr2WbpNg"
                  />
                  {props.addCommonStatus ? (
                    <div
                      className={`alert ${props.addCommonStatus === 1
                        ? "alert-success"
                        : "alert-danger"
                        }`}
                      role="alert"
                    >
                      {props.addCommonStatus === 1
                        ? "Item added successfully"
                        : props.message}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label
                      htmlFor="description"
                      className="control-label col-sm-2 required"
                    >
                      Email Tag
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        required="required"
                        name="description"
                        type="text"
                        id="description"
                        value={props.email.email_tag}
                        onChange={(e) => {
                          props.setFieldValues("email", {
                            ...props.email,
                            email_tag: e.target.value,
                          });
                        }}
                      />
                      {props.addCommonErrors.email_tag &&
                        props.addCommonErrors.email_tag.length > 0 ? (
                        <span className="text-danger">
                          {props.addCommonErrors.email_tag}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label
                      htmlFor="emailtemplate"
                      className="control-label col-sm-2 required"
                    >
                      Email Content
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        rows={6}
                        required="required"
                        name="emailtemplate"
                        cols={50}
                        id="emailtemplate"
                        value={props.email.email_content}
                        onChange={(e) => {
                          props.setFieldValues("email", {
                            ...props.email,
                            email_content: e.target.value,
                          });
                        }}
                      />
                      {props.addCommonErrors.email_content &&
                      props.addCommonErrors.email_content.length > 0 ? (
                        <span className="text-danger">
                          {props.addCommonErrors.email_content}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="emailtemplate" className="control-label col-sm-2 required">Email
                            Content</label>
                    <div className="col-sm-10">

                      <Editor
                        editorState={email}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="demo-wrapper"
                        editorStyle={{ border: "1px solid",height: "250px" }} 
                        onEditorStateChange={onChange}
                     
                      />

                      {/* <RichTextEditor
                        value={email}
                        onChange={onChange}
                        toolbarConfig={toolbarConfig}
                      /> */}
                    </div>
                    <div className="form-group"></div>
                    <div className="form-group"></div>
                  </div>
                  <button
                    className="btn form-control btn-success"
                    type="button"
                    value="new"
                    name="action"
                    onClick={(e) => {
                      addNewEmailItem(e);
                    }}
                  >
                   <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                className="btn form-control"
                id="cbd09425-d9bf-4e91-8deb-c13eaf814bd4"
                data-dismiss="modal"
                onClick={() => {props.setFieldValues("email_modal_show", false);
                  window.location.reload();}}
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.Settings.email_modal_show,
    email: state.Settings.email,
    addCommonErrors: state.Settings.addCommonErrors,
    addCommonStatus: state.Settings.addCommonStatus,
    message: state.Settings.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (f, v) => dispatch(actions.settings_data(f, v)),
    addNewEmailItem: (data) => dispatch(actions.addNewEmailItem(data)),
    updateCommonSettingErrors: (data) =>
      dispatch(actions.updateCommonSettingErrors(data)),
    reset_common_info: () => dispatch(actions.reset_common_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewEmailItem);
