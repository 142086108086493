import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";
import * as share from "../../../actions/clientDetails/ShareRegister";
import DatePicker from "react-datepicker";
import { s3_file_upload } from "../../../actions/common/S3_Upload";
import moment from "moment";
import { changeClientInfo } from "../../../actions/Client";

function ReSendRequest(props) {
  const [selectCategory, setSelectCategory] = useState("");

  useEffect(() => {
    const getCategory = () => {
      setSelectCategory("");

      console.log("getCategory: ", selectCategory);
      const category = localStorage.getItem("select_category");

      setSelectCategory(category);
      console.log("selectCategory :", category);
    };

    getCategory();
  }, []);

  useEffect(() => {
    props.getShareHolderDirectorList(1);
  }, []);

  const fileName = (filePath) => {
    console.log("filePath: ", filePath);

    let file = filePath.split("/");

    return file[file.length - 1];
  };

  useEffect(() => {
    if (props.multiple_document_id.length) {
      props.getDocPath(props.multiple_document_id);
    }
  }, [props.multiple_document_id]);

  const [filename, setFilename] = useState([]);
  const [fieldValue, setFieldValue] = useState({
    tittle: "",
    body: "",
    deadline1_date: "",
    deadline2_date: "",
    deadline3_date: "",
    deadline1_status: "",
    deadline2_status: "",
    deadline3_status: "",
  });

  useEffect(() => {
    let add = [...props.shareHolderList];
    props.shareHolderList.map((share) => {
      if (share.person_copied_status != 1) {
        share.check = false;
      } else {
        share.check = true;
      }
    });
    props.sendValues("shareHolder", add);
  }, [props.shareHolderList]);

  useEffect(() => {
    let add = [...props.shareHolder];
    let fill = props.shareHolder.filter((share) => {
      if (share.person_copied_status != 1) {
        if (share.check == true) {
          return share;
        }
      }
    });
    props.sendValues("filterValue", fill);
  }, [props.shareHolder]);

  // console.log(props.reSendMailDetails.length > 0 ?props.reSendMailDetails[0].mail_to: "","akdjlfkjasldfk")
  const checkChangeFunc = (e) => {
    let change = [...props.shareHolder];
    props.shareHolder.map((val) => {
      if (val.email == props.reSendMailDetails[0].to) {
        val.check = e.target.checked;
        console.log(
          props.reSendMailDetails[0].mail_to,
          "shareholderDirectorsValue"
        );
      }
    });
    props.sendValues("shareHolder", change);

    console.log(
      props.reSendMailDetails[0].mail_to,
      "shareholderDirectorsValue"
    );
  };

  const selectAllFunc = (e) => {
    let change = [...props.shareHolder];
    props.shareHolder.map((val) => {
      if (val.person_copied_status != 1) {
        val.check = e.target.checked;
      }
    });
    props.sendValues("shareHolder", change);
  };

  const removeAttchFile = (idx) => {
    let nme = [...filename];
    let lnk = [...props.attachment];
    nme.splice(idx, 1);
    lnk.splice(idx, 1);
    setFilename(nme);
    props.attachmentArray(lnk);
  };

  const uploadImage = async (val) => {
    let response = {
      status: 1,
    };
    var userData = JSON.parse(localStorage.getItem("userData"));
    let data = {
      company_id: userData.company_id,
      client_id: localStorage.getItem("personalId"),
      active_tab: props.pageSelected,
      attachment: val,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    let file = response.original_link ? response.original_link : "";
    setFilename([...filename, val.name]);
    if (file != "") {
      props.attachmentArray(file);
    } else {
      props.changeClientInfo("clientApiStatus", 2);
      props.changeClientInfo("message", "File not upload");
      setTimeout(() => {
        props.changeClientInfo("clientApiStatus", 0);
        props.changeClientInfo("message", "");
      }, 2500);
    }
  };

  const sendDocumentFunc = (e) => {
    e.preventDefault();

    let error = false;
    console.log("mail", props.filterValue);
    let mail = props.filterValue.map((fill) => {
      return fill.email;
    });
    let mail_id = props.filterValue.map((fill) => {
      return fill.id;
    });
    let fillMail = props.shareHolder.filter((fill) => {
      if (fill.person_copied_status == 1) {
        return fill;
      }
    });
    console.log("ccMail", fillMail, props.shareHolder);
    let ccMail = fillMail.map((m) => {
      return m.email;
    });
    let ccMail_id = fillMail.map((m) => {
      return m.id;
    });

    props.shareHolder.filter((fill) => {
      if (fill.person_copied_status == 1) {
        return fill;
      }
    });

    let deadline_list = [];
    if (fieldValue.deadline1_status != "") {
      if (fieldValue.deadline1_date != "") {
        let val = {
          deadline_date: moment(fieldValue.deadline1_date).format("YYYY-MM-DD"),
          deadline_type_id: fieldValue.deadline1_status,
        };
        deadline_list.push(val);
      } else {
        error = true;
        props.changeClientInfo("clientApiStatus", 2);
        props.changeClientInfo("message", "Please Fill Deadline 1 Date Field");
        setTimeout(() => {
          props.changeClientInfo("clientApiStatus", 0);
          props.changeClientInfo("message", "");
        }, 2500);
      }
    }
    if (fieldValue.deadline2_status != "") {
      if (fieldValue.deadline2_date != "") {
        let val = {
          deadline_date: moment(fieldValue.deadline2_date).format("YYYY-MM-DD"),
          deadline_type_id: fieldValue.deadline2_status,
        };
        deadline_list.push(val);
      } else {
        error = true;
        props.changeClientInfo("clientApiStatus", 2);
        props.changeClientInfo("message", "Please Fill Deadline 2 Date Field");
        setTimeout(() => {
          props.changeClientInfo("clientApiStatus", 0);
          props.changeClientInfo("message", "");
        }, 2500);
      }
    }
    if (fieldValue.deadline3_status != "") {
      if (fieldValue.deadline3_date != "") {
        let val = {
          deadline_date: moment(fieldValue.deadline3_date).format("YYYY-MM-DD"),
          deadline_type_id: fieldValue.deadline3_status,
        };
        deadline_list.push(val);
      } else {
        error = true;
        props.changeClientInfo("clientApiStatus", 2);
        props.changeClientInfo("message", "Please Fill Deadline 3 Date Field");
        setTimeout(() => {
          props.changeClientInfo("clientApiStatus", 0);
          props.changeClientInfo("message", "");
        }, 2500);
      }
    }
    if (mail.length != 0 && error == false) {
      let input = {
        mail_title: fieldValue.tittle,
        mail_body: fieldValue.body,
        deadline_list: deadline_list,
        document_type_id: props.selectDocumentType,
        document_id: props.document_id,
        multiple_document_id: props.multiple_document_id,
        to: mail,
        cc: ccMail,
        mail_id: mail_id,
        ccMail_id: ccMail_id,
        attach_doc: props.attachment,
        view_doc: "view",
      };

      props.sendDocumentInMail(input, props.tabSelected);
    } else if (mail.length == 0) {
      props.changeClientInfo("clientApiStatus", 2);
      props.changeClientInfo("message", "Please Add Send Mail First");
      setTimeout(() => {
        props.changeClientInfo("clientApiStatus", 0);
        props.changeClientInfo("message", "");
      }, 2500);
    }
  };

  // const saveChangesFunc  =(e)=>{
  //   e.preventDefault();

  // let input ={
  //   document_type:props.selectDocumentType,
  //   // generate_doc:true,
  //   // file_url:props.docPreview,
  //   user_id:2,
  //   client_id:localStorage.getItem("personalId"),
  //   status:5,
  //   placeholder_list:obj,
  // };
  //   props.sendAndSaveDocument(input);

  // };
  //   console.log(props.reSendMailDetails[0].mail_title,"reSendMailDetails")

  return (
    <Modal
      show={props.reSendRequestModal}
      id="myModal"
      className="modal fade in"
      style={{ display: "block" }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialog modal-xl"
    >
      <div className="modal-content ">
        <div className="modal-header text-center">
          {/* <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() =>
            props.sendValues("sendRequestModal", false)
          }>X</button> */}
          <h4 className="modal-title blue-text">Re-Send the Document</h4>
        </div>
        <div className="modal-body pb-0">
          <div className="row">
            <div className="col-sm-6 border-right-gray">
              <div className="share-list-box-wrapper">
                <div className="sl-head-wrapper">
                  <h6>TO</h6>
                  <div className="name-list">
                    {props.filterValue.map((fill, idx) => {
                      return <span key={idx}>{fill.name},</span>;
                    })}
                    {/* <input /> */}
                  </div>
                  <div className="border-line" />
                </div>
                <div className="sl-body-wrapper">
                  <h5>Shareholder &amp; Director list</h5>
                  <ul>
                    {props.shareHolderList &&
                      props.shareHolderList.map((lis, idx) => {
                        // Check if props.reSendMailDetails and its first element are defined
                        const reSendMailDetails =
                          props.reSendMailDetails && props.reSendMailDetails[0];

                        // Check if reSendMailDetails is defined and has 'mail_to' property
                        const mailTo =
                          reSendMailDetails && reSendMailDetails.mail_to;

                        const isChecked = mailTo && mailTo.includes(lis.id);

                        return (
                          <li key={idx}>
                            <div className="user-details">
                              <div className="user-img" />
                              <h6>
                                {/* {lis.name}
                                    {lis.desgnames ? "-" + lis.desgnames : ""} */}
                                {lis.name}
                                {" " + " - " + " "}
                                {lis.desgnames}
                              </h6>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id={lis.id}
                                name={lis.id}
                                checked={isChecked}
                                // onChange={(e) => checkChangeFunc(e, lis.id)}
                              />
                            </div>
                          </li>
                        );
                      })}

                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li>
                        <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                  </ul>
                </div>
                <div className="sl-footer-wrapper">
                  <h6>
                    <span>{props.filterValue.length} personnal</span> selected
                  </h6>
                  <div>
                    <h6>Select All</h6>
                    <input
                      type="checkbox"
                      id="Jane"
                      name="Jane"
                      defaultValue="Jane"
                      onClick={selectAllFunc}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 border-right-gray">
              <div className="form-group">
                <label
                  htmlFor="registrationno"
                  className="control-labelrequired"
                >
                  Document Category
                </label>
                <select
                  className="form-control mb-10"
                  id="document_type_id"
                  name="document_type_id"
                  defaultValue={1}
                  readOnly
                >
                  <option value selected="selected">
                    === Select ===
                  </option>
                  <option value={1}>Secreterial</option>
                  <option value={2}>Others</option>
                  <option value={3}>Financial statement</option>
                  <option value={4}>Tax computation</option>
                  <option value={5}>GST</option>
                  <option value={6}>CERTIFICATE OF RESIDENCE </option>
                  <option value={7}>PERSONAL TAXES</option>
                  <option value={8}>Vds</option>
                  <option value={9}>xyz</option>
                </select>
                <input
                  className="form-control  mb-10"
                  required="required"
                  name="tittle"
                  type="text"
                  id="registrationno"
                  defaultValue={
                    props.reSendMailDetails.length > 0
                      ? props.reSendMailDetails[0].mail_title
                      : ""
                  }
                  readOnly
                />
                <textarea
                  className="form-control"
                  rows={10}
                  name="body"
                  cols={50}
                  id="remarks"
                  defaultValue={
                    props.reSendMailDetails.length > 0
                      ? props.reSendMailDetails[0].mail_body
                      : ""
                  }
                  readOnly
                />
                <p className="help-block">
                  A link to the document will be automatically included with
                  your message
                </p>
              </div>
              <div className="view-attach mb-0">
                <h6>
                  {" "}
                  <span className="glyphicon glyphicon-paperclip" />
                  <label for="myview">
                    <span className="txt">View</span>
                    <div
                      id="myview"
                      name="myfile"
                      style={{ display: "none" }}
                    ></div>
                  </label>
                </h6>
              </div>
              <div className="view-attach mb-20">
                <div>
                  {props.reSendMailDetails?.map((data, i) => {
                    return (
                      <div>
                        <a
                          target={"_blank"}
                          href={`https://api-gbs.smitiv.co${data.file_path}`}
                          className="txt"
                        >
                          {fileName(data?.file_path)}
                        </a>
                        <br />
                      </div>
                    );
                  })}
                  {/* <div>
                        <a
                          target={"_blank"}
                          href={`https://docs.google.com/viewer?url=http://13.214.35.227:4444${props.reSendMailDetails ?props.reSendMailDetails[0].file_path:""}&embedded=true`}
                          className="txt"
                        >
                          {fileName(props.reSendMailDetails?.file_path)}
                        </a>
                        <br />
                      </div> */}
                </div>
              </div>
            </div>

            <div className="col-sm-12 mt-20">
              {props.clientApiStatus ? (
                <div
                  className={`alert ${
                    props.clientApiStatus === 1 ? "" : "alert-danger"
                  }`}
                  role="alert"
                >
                  {props.clientApiStatus === 1 ? "" : props.message}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="col-sm-12 pt-20 pb-20 mt-20 flex-center-space"
              style={{ background: "rgba(92, 132, 149, 0.32)" }}
            >
              <button
                className="btn transparent-btn"
                onClick={() => props.sendValues("reSendRequestModal", false)}
              >
                Cancel
              </button>
              <button className="btn blue-btn" onClick={sendDocumentFunc}>
                Send Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    transferOfShareModal,
    docPreview,
    defaultFields,
    selectDocumentType,
    reSendRequestModal,
    attachment,
    shareHolder,
    filterValue,
    document_id,
    multiple_document_id,
    reSendMailDetails,
    docPath,
  } = state.Document;

  return {
    transferOfShareModal,
    docPreview,
    defaultFields,
    selectDocumentType,
    reSendRequestModal,
    reSendMailDetails,
    shareHolder,
    filterValue,
    attachment,
    document_id,
    multiple_document_id,
    shareHolderList: state.ShareReg.shareHolderList,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    tabSelected: state.Document.tabSelected,
    docPath: state.Document.docPath,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentPreview: (val) => dispatch(actions.getDocumentPreview(val)),
    getShareHolderDirectorList: (data) =>
      dispatch(share.getShareHolderDirectorList(data)),
    getDocPath: (data) => dispatch(actions.getDocumentPath(data)),
    attachmentArray: (data) => dispatch(actions.attachmentArray(data)),
    sendDocumentInMail: (input, tab) =>
      dispatch(actions.sendDocumentInMail(input, tab)),
    changeClientInfo: (k, v) => dispatch(changeClientInfo(k, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReSendRequest);
