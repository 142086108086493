import React, { Fragment, useEffect,useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import { connect } from "react-redux";
import AddNewDocument from "../client/modals/DocumentType";
import AddDocumentSection from "../client/modals/AddDocumentSection";
// import AddDocumentNew from "../client/modals/AddDocument";
import * as actions1 from "../../actions/clientDetails/Document";
import * as clientactions from "../../actions/Client";
import Deletemodal from "./modals/DeleteModal";
import * as actions from "../../actions/CompanySetups";
import Add from "../client/modals/AddNewDocument";
import * as settings from "../../actions/Settings";
import "./CompanySetups.css";
import moment from "moment";
import $, { valHooks } from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function DocumentSetup(props) {
  var table;
  useEffect(()=>{
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getGlobaldocumentList();
    props.getDocumentList();

    return () => {
      table.destroy(true);
    };
  },[props.docId]);

  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getGlobaldocumentList();
    props.getDocumentList();

    return () => {
      table.destroy(true);
    };
  }, []);
 
  console.log("deep", props.companyList)

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");

  useEffect(() => {
    function format(d) {
      console.log("did", d);
      return (
        `<tr id="detail-view-${d?.id}"><td colspan="7" style="padding:0px;"><table id="detail-view-${d?.id}"   >` +
        "<tr>" +
        "<td>Remark:</td>" +
        "<td>" +
        `${(() => {
          if (d.remarks != undefined) {
            return d?.remarks
          } else {
            return ''
          }
        })()}`
        +
        "</td>" +
        "</tr>" +
        `</table></td></tr>`
      );
    }
    // $("#documentTable").on("click", ".notes_text", function () {
    //   var id=this.name;
    //   var val = $(this).val()
    //   //props.saveDocumentNotes(1,val,id);
    //   var text=this.id
    //   //props.saveDocumentNotes(2,val,id);
    //   props.changeClientInfo("updated_comment", text);
    //   props.changeClientInfo("updated_status", 1);
    //   props.changeClientInfo("updated_doc_id", this.name);
    //   props.changeClientInfo("document_comment_modal_show", true);
     
  
    
    // });
    $("#documentTable").on("click", ".btnEdit", function () {
      console.log("value", JSON.parse(this.value));
      let getVal = JSON.parse(this.value);
      let chg = getVal[1].replace("D:", "") && getVal[1].replace("E:", "");
      let dat = chg.replace(/\\/g, "/");
      let arr = dat.split("/");
      let file = arr[arr.length - 1];
      // let editDoc = props.documentList.filter((item) => item === getVal[0]);
      props.sendValues("document_id", this.name);
      // props.sendValues("editDoc", editDoc);
      props.sendValues("docPreview", file);
      props.getDefaultPlaceHolder(getVal[0]);
      props.sendValues("selectDocumentType", getVal[0]);
      props.sendValues("editDocumentModal", true);
      props.sendValues("transferOfShareModal", true);
    });
    // $("#documentTable").on("click", ".notes_text1", function () {
    //   var id=this.name;
    //   var val = $(this).val()
    //   var text=this.id
    //   //props.saveDocumentNotes(2,val,id);
    //   props.changeClientInfo("updated_comment", text);
    //   props.changeClientInfo("updated_status", 2);
    //   props.changeClientInfo("updated_doc_id", this.name);
  
    //   props.changeClientInfo("document_comment_modal_show", true);
    // });
    $("#documentTable").on("click", ".btnSend", function () {
      props.sendValues("document_id", this.name);
      props.sendValues("selectDocumentType", this.value);
      props.sendValues("sendRequestModal", true);
    });

    $("#documentTable").on("click", ".btnDel", function () {
      props.sendValues("document_id", this.name);
      props.sendValues("deleteDocumentModal", true);
    });

    $("#documentTable").on("click", ".btnReject", function () {
      props.sendValues("document_id", this.name);
      props.sendValues("rejectDocumentModal", true);
    });
    $("#documentTable").on("click", ".btnChangeStatus", function () {
      props.sendValues("document_id", this.name);
      props.sendValues("changestatusDocumentModal", true);
    });
    $("#documentTable").on("click", ".btnAccept", function () {
      console.log("this", this.name);
      props.sendValues("document_id", this.name);
      props.sendValues("acceptDocumentModal", true);
    });

    $.fn.DataTable.ext.pager.numbers_length = 10;
    $(document).ready(function () {

      table = $("#resolution").DataTable({
        
        pageLength:25,
        lengthMenu: [ 25, 50 ],
        scrollCollapse: true,
        paging: true,

        destroy: true,
     

        data: props.companyList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            orderable: true,
            searchable: true,
            className: " col-xs-12 col-sm-12 col-md-12 col-lg-12",
            render: function (data, type, row) {
              return `<span/>${row.date}</span>`;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#0075b0");
          }
        },
        createdRow: function (row, data, dataIndex) {
          $("td:eq(0)", row).attr("colspan", 2).css("color", "white");
          $("td:eq(1)", row).css("display", "none").css("width", "75px");
          $("td:eq(2)", row).css("display", "none").css("width", "75px");
        
        },
      });

      table.rows().every(function () {
        var rowdata = this.data();
        var dataChild = rowdata.list;
        dataChild && 
        this.child(
          dataChild.map((item) => {
            console.log("items",item.length)
            let clr = '';
            if (item.is_approved == 0) {
              clr = '#f44242'
            } else {
              clr = '<span style="margin-left: 30px;">---</span>';
            }

            
            return $(
              `<tr style="background-color: ${clr}" >` +
             
              "<td class=''>" +
              `${(() => {
                if (item.id != '' && item.id != null && item.id != undefined) {
                  return item.attachment.split('/').pop().split('?')[0].split('#')[0];
                }

              })()}
              ${(() => {
                if (item.id != '' && item.id != null && item.id != undefined  ) {
                  return'<a target="_blank" href="'+item.attachment+'"><span class="document glyphicon glyphicon-paperclip" id="'+item.docids+'" /></a>';
                } 
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (
                  item.created_date != null
                ) {
                  return moment(item.created_date).format("DD/MM/YYYY");
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}` +
              "</td>" +
              
              "</tr>"
            );
          })
        ).show();
      });
    });
  }, [props.companyList]);
  const handleRemove = (idx) => {
    console.log("resid", idx)
    props.changeClientInfo("deleteCompany", true);
    props.changeClientInfo("docId", idx);
    props.changeClientInfo("docFlag", 2);
    
}

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
      <div id="share-resolution-tab" className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                <i class="glyphicon glyphicon-folder-open"  style={{padding:"15px"}}></i> &nbsp;&nbsp; Documents
                
                <a
                    className="new-document-link mr-20"
                    onClick={() =>
                      props.sendValues("addNewDocumentModal", true)
                    }
                    style={{padding:"10px",float:"right"}}
                  >
                    Add New Template
                  </a>
                  <a
                    className="new-document-link mr-20"
                    onClick={() =>
                      props.sendValues("selectaddDocumentModal1", true)
                    }
                    style={{padding:"10px",float:"right"}}
                  >
                    Add Document Category
                  </a>
                </div>
              </nav>
              <div className="box-tools pull-right" />
             
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="share-action_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
              
              {
                            props.DocumentTypeListnew && props.DocumentTypeListnew.map((res,index)=>{
                                var linkgen="/document_by_id/"+res.id
                                return (
                                <>
                                <tr>
                                <td style={{padding:"10px"}}>
                                <a class="folder-name" href={linkgen} >
                                <i class="glyphicon glyphicon-folder-open"></i> &nbsp;&nbsp;&nbsp;
                                  { res.name}
                                  </a></td><td><button  class="btnDel btn btn-primary btn-s"   id="test" title="Delete" name="test" onClick={() => handleRemove(res.id)} ><span class="glyphicon glyphicon-trash" /></button>
                                  </td>
                                  
                                  <br />
                                  </tr>
                                </>
                              )
                            })

                          }
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
      </section></div>
      
      <Footer />
      </div>
      <Deletemodal />
      <AddNewDocument />
      <AddDocumentSection />
      <Add />

      {/* ./wrapper */}
    
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
  } = state.ShareReg;
  return {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
    deleteModal: state.Deadline.deleteModal,
    processModal:state.Client.processModal,
    companyList: state.CompanySetups.companyList,
    DocumentTypeListnew: state.Settings.DocumentTypeListnew,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions1.documentFunction(k, v)),
    getDocumentList: () => dispatch(settings.getDocumentClassification_new()),
    getGlobaldocumentList: () => dispatch(actions.getGlobaldocumentList()),
    changeCompanyInfo: (f, e) => dispatch(actions.changeCompanyInfo(f, e)),
    addCompany: (data) => dispatch(actions.addCompany(data)),
    adddocumentcat: (data) => dispatch(actions.adddocumentcat(data)),
    
    updateCompanyErrors: (data) => dispatch(actions.updateCompanyErrors(data)),
    reset_company_info: () => dispatch(actions.reset_company_info()),
    // getDefaultPlaceHolder: (type) =>
    //   dispatch(actions1.getDefaultPlaceHolder(type)),
      changeClientInfo: (field, value) =>
      dispatch(clientactions.changeClientInfo(field, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSetup);
