var siteUrl = "https://api-gbs.smitiv.co/";
// var siteUrl = "http://localhost:4444/";



var config = {
  siteUrl :"https://api-gbs.smitiv.co/",
  // siteUrl :"http://localhost:4444/",



  company_list: siteUrl + "api/v1/company/list",
  updatestatus:siteUrl +"api/v1/company/updatestatus",
  getdocument_list_by_otp: siteUrl + "api/v1/document/document_list",
  getclients:siteUrl + "api/v1/getclients",
  check_otpvalidation:siteUrl+"api/v1/document/check_otpvalidation",
  send_otp:siteUrl+"api/v1/document/send_otp",
  validate_otp:siteUrl+"api/v1/document/validate_otp",
  pending_docs_user: siteUrl + "api/v1/document/pending_docs_user",
  completed_docs: siteUrl + 'api/v1/getCompleted_Docs',
  pending_docs: siteUrl + "api/v1/getPending_Docs",
  deletelistFolder: siteUrl + "api/v1/document/DeletedocumentFolder/",
  global_document_list: siteUrl + "api/v1/globaldocument/list",
  document_management_list: siteUrl + "api/v1/document/managedocument",
  add_company: siteUrl + "api/v1/company/add",
  add_category: siteUrl + "api/v1/company/add_category",
  personal_list: siteUrl + "api/v1/latest_articles",
  login: siteUrl + "api/v1/login",
  forgetpassword: siteUrl + "api/v1/forgetpassword",
  changepassword: siteUrl + "api/v1/changepassword",
  getbyidentification_no:
    siteUrl + "api/v1/officebearer/getbyidentification_no",
  add_client: siteUrl + "api/v1/addclient",
  add_bulk_client: siteUrl + "api/v1/bulk-upload",
  delete_client: siteUrl + "api/v1/deleteclient",
  client_list: siteUrl + "api/v1/getclients",
  otpsend: siteUrl + "api/v1/document/send_otp",
  generatepdf_client: siteUrl + "api/v1/generatepdf_client",
  approve_client: siteUrl + "api/v1/approveclient",
  identificationType: siteUrl + "api/v1/identificationtypes",
  getDesignationList: siteUrl + "api/v1/designation",
  getSalutationList: siteUrl + "api/v1/salutation",
  getNationalitiesList: siteUrl + "api/v1/nationalities",
  getFlagList: siteUrl + "api/v1/flag",
  getPersonalList: siteUrl + "api/v1/officebearer/list",
  setPrimary: siteUrl + "api/v1/officebearer/update_primary_status/",
  setMergeUrl: siteUrl + "api/v1/officebearer/update_merge_status/",
  add_personal: siteUrl + "api/v1/officebearer/add",
  Edit_personal: siteUrl + "api/v1/officebearer/edit",
  delete_Personal: siteUrl + "api/v1/officebearer/delete",
  settings_currency_list: siteUrl + "api/v1/currencies",
  settings_email_template_list: siteUrl + "api/v1/emailtemplate/list",
  settings_nationality_list: siteUrl + "api/v1/nationalities",
  settings_employee_type_list: siteUrl + "api/v1/employeetypes",
  settings_share_type_list: siteUrl + "api/v1/sharetypes",
  get_share_lot: siteUrl + "api/v1/shareregister/get_no_of_shares_by_client_id",
  settings_task_setting_list: siteUrl + "api/v1/tasksettings",
  deadline_Drop: siteUrl + "api/v1/tasksettings",
  settings_invoice_category_list: siteUrl + "api/v1/invoicetypes",
  settings_service_list: siteUrl + "api/v1/services",
  settings_id_type_list: siteUrl + "api/v1/idtypes",
  settings_salutation_list: siteUrl + "api/v1/salutation",
  settings_document_classification_list:
    siteUrl + "api/v1/documentclassification",
  add_currency: siteUrl + "api/v1/currencies/add",
  edit_currency: siteUrl + "api/v1/currencies/edit",
  add_email_template: siteUrl + "api/v1/emailtemplate/add",
  edit_email_template: siteUrl + "api/v1/emailtemplate/edit",
  add_nationality: siteUrl + "api/v1/nationalities/add",
  edit_nationality: siteUrl + "api/v1/nationalities/edit",
  add_employee_type: siteUrl + "api/v1/employeetypes/add",
  edit_employee_type: siteUrl + "api/v1/employeetypes/edit",
  add_share_type: siteUrl + "api/v1/sharetypes/add",
  edit_share_type: siteUrl + "api/v1/sharetypes/edit",
  add_task_setting: siteUrl + "api/v1/tasksettings/add",
  edit_task_setting: siteUrl + "api/v1/tasksettings/edit",
  add_invoice_category: siteUrl + "api/v1/invoicetypes/add",
  edit_invoice_category: siteUrl + "api/v1/invoicetypes/edit",
  add_id_type: siteUrl + "api/v1/idtypes/add",
  edit_id_type: siteUrl + "api/v1/idtypes/edit",
  add_salutation: siteUrl + "api/v1/salutation/add",
  edit_salutation: siteUrl + "api/v1/salutation/edit",
  add_document_classification: siteUrl + "api/v1/documentclassification/add",
  edit_document_classification: siteUrl + "api/v1/documentclassification/edit",
  add_service: siteUrl + "api/v1/services/add",
  edit_service: siteUrl + "api/v1/services/edit",
  Edit_client: siteUrl + "api/v1/editclient",
  dw_client_list: siteUrl + "api/v1/getclientslistexcel",
  approve_Charges: siteUrl + "api/v1/clientcharges/approve",
  delete_Charges: siteUrl + "api/v1/clientcharges/delete",
  delete_resolution: siteUrl + "api/v1/shareregister/delete",
  approve_Personal: siteUrl + "api/v1/officebearer/approve",
  add_charges: siteUrl + "api/v1/clientcharges/add",
  edit_document_old: siteUrl + "api/v1/clientcharges/editdocument_old",
  add_document_old: siteUrl + "api/v1/clientcharges/adddocument_old",
  get_charges_list: siteUrl + "api/v1/clientcharges/list",
  edit_charges: siteUrl + "api/v1/clientcharges/edit",
  add_deadline: siteUrl + "api/v1/deadline/add",
  edit_deadline: siteUrl + "api/v1/deadline/edit",
  get_deadline_list: siteUrl + "api/v1/deadline/list",
  approve_dealine: siteUrl + "api/v1/deadline/approve",
  delete_dealine: siteUrl + "api/v1/deadline/delete",
  reminders_list: siteUrl + "api/v1/reminder/list",
  user_permissions_list: siteUrl + "api/v1/userpermissions/list",
  getUsersList: siteUrl + "api/v1/user/list",
  addNewUser: siteUrl + "api/v1/user/add",
  edit_user: siteUrl + "api/v1/user/edit",
  delete_user: siteUrl + "api/v1/user/delete",
  assign_userRole: siteUrl + "api/v1/user/assignrole",
  assign_company_to_user: siteUrl + "api/v1/user/assigncompany",
  remove_company_from_user: siteUrl + "api/v1/user/removecompany",
  getRolesList: siteUrl + "api/v1/role/list",
  userPermissonList: siteUrl + "api/v1/userpermissions/list",
  permissionList: siteUrl + "api/v1/permissions/list",
  add_permission: siteUrl + "api/v1/userpermissions/add",
  get_completedDeadline_list: siteUrl + "api/v1/deadline/completed",
  add_shareRegister: siteUrl + "api/v1/shareregister/add",
  get_share_reg_list: siteUrl + "api/v1/shareregister/list",
  share_based_values: siteUrl + "api/v1/splitshare/get_share_type_values",
  transfer_based_values: siteUrl + "api/v1/splitshare/get_transfer_type_values",
  add_splitShare: siteUrl + "api/v1/splitshare/create",
  share_type_list: siteUrl + "api/v1/sharetype/list",
  share_holder_list: siteUrl + "api/v1/shareholder/list",
  shareholderanddirecor_list:
    siteUrl + "api/v1/shareholder/shareholderanddirecor_list",
  share_value_list: siteUrl + "api/v1/valuepershare/list",
  share_history_list: siteUrl + "api/v1/sharehistory/list",
  approve_resolution_share: siteUrl + "api/v1/resolutionshareregister/approve",
  add_transferShare: siteUrl + "api/v1/transfershare",
  add_reduceShare: siteUrl + "api/v1/reduceshare",
  add_convertShare: siteUrl + "api/v1/convertshare",
  document_upload_to_s3: siteUrl + "api/v1/s3_image_upload",
  s3_upload_docs: siteUrl + "api/v1/s3_image_upload_ocr",
  add_newTask: siteUrl + "api/v1/deadline/task/add",
  edit_newTask: siteUrl + "api/v1/deadline/task/edit",
  delete_newTask: siteUrl + "api/v1/deadline/task/delete",
  edit_shareRegister: siteUrl + "api/v1/shareregister/edit",
  update_permission: siteUrl + "api/v1/user/assignpermission",
  documentTypeList: siteUrl + "api/v1/documenttype/list",
  getDocumentPreview: siteUrl + "api/v1/getpreviewdoc",
  getDefaultPlaceHolderValues: siteUrl + "api/v1/getpreviewplaceholders",
  sendAndSaveDocument: siteUrl + "api/v1/generatedoc",
  otherSendAndSaveDocument: siteUrl + "api/v1/generate_other_doc",

  approveDocument: siteUrl + "api/v1/document/approve",
  rejectDocument: siteUrl + "api/v1/document/reject",
  changestatusDocument: siteUrl + "api/v1/document/changestatusDocument",
  deleteDocument: siteUrl + "api/v1/document/delete",
  sendDocumentMail: siteUrl + "api/v1/document/saveemailtemplate",
  sendDocumentMail_multiple: siteUrl + "api/v1/document/saveemailtemplate_multiple",
  deleteDocument: siteUrl + "api/v1/document/delete",
  editDocument: siteUrl + "api/v1/editdoc",
  editDate : siteUrl + "api/v1/editdate",
  resendMail: siteUrl + "api/v1/resendmail",
  addNewDocument: siteUrl + "api/v1/createtemplate",
  signDoc: siteUrl + "api/v1/signdoc",
  signDocnew: siteUrl + "api/v1/signdoc_new",
  signdoc_new_user: siteUrl + "api/v1/signdoc_new_user",
  docList: siteUrl + "api/v1/document/documentforview",
  docList_byId: siteUrl + "api/v1/document/documentforview_byid",
  agreeList_byId: siteUrl + "api/v1/document/agreeforview_byid",
  signatureList: siteUrl + "api/v1/document/signatureList",
  signatureList_Byuser: siteUrl +"api/v1/document/signatureList_byuser",
  listdocs: siteUrl + "api/v1/getdocument/list",
  getDocPath: siteUrl + "api/v1/get-document-path",
  savenotes: siteUrl + "api/v1/getdocument/savenotes",
  savedescription: siteUrl + "api/v1/getdocument/savedescription",
  getnotes: siteUrl + "api/v1/getdocument/getnotes",
  list_alldocs_byclient: siteUrl + "api/v1/getdocument/list/byclient",
  list_alldocs_byclient_docs: siteUrl + "api/v1/getdocument/list/byclient_documents",
  document_category:siteUrl+'api/v1/document/types',
  settings_client_status_list:siteUrl+'api/v1/document/clientstatus',
  client_id_list:siteUrl+'api/v1/companyname',
  settings_document_status_list:siteUrl+'api/v1/document/documentStatus',
  settings_idendification_types_list:siteUrl+'api/v1/document/get_idendificationtypes',
  settings_flag_list:siteUrl+'api/v1/flag',
  add_client_status:siteUrl+'api/v1/document/add_clientstatus',
  edit_client_status:siteUrl+'api/v1/document/add_clientstatus',
  add_document_status:siteUrl+'api/v1/document/add_documentStatus',
  edit_document_status:siteUrl+'api/v1/document/add_documentStatus',
  add_idendification_types:siteUrl+'api/v1/document/add_idendificationtypes',
  edit_idendification_types:siteUrl+'api/v1/document/add_idendificationtypes',
  add_flag:siteUrl+'api/v1/document/add_flag',
  edit_flag:siteUrl+'api/v1/document/add_flag',
  document_categorybyId:siteUrl+'api/v1/document/typesbyid/',
  document_categorybyIdnew:siteUrl+'api/v1/document/listdocumentbyidnew/',
  filterDocument:siteUrl+'api/v1/document/filterdocument',
  document_countbyId:siteUrl+'api/v1/document/listdocumentcount/',
  deletedocumentbyid:siteUrl+'api/v1/document/deletedocumentbyid/',
  listdocumentbyid:siteUrl+'api/v1/document/listdocumentbyid/',
  deletelistdocumentbyid:siteUrl+'api/v1/document/deletelistdocumentbyidnew/',
  uploadStatement: `${siteUrl}api/v1/upload-statement`,
  getStatementTable: `${siteUrl}api/v1/get-statement-table`,
  getStatements: `${siteUrl}api/v1/get-statements`,
  downloadStatementDoc: `${siteUrl}api/v1/download-statement-document`,
  downloadStatementSample: `${siteUrl}api/v1/download-statement-sample`,
  deleteStatement: `${siteUrl}api/v1/deleteStatement`,
  exportByCompany: `${siteUrl}api/v1/deadline/listinexcel`,
  sendDocAfterSign: `${siteUrl}api/v1/finaldocumentsend`
};
export default config;
