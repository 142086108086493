// import React from "react";
// import {
//   DocumentEditorContainerComponent,
//   Toolbar,
// } from "@syncfusion/ej2-react-documenteditor";
// // import { TitleBar } from './title-bar';
// // import { isNullOrUndefined } from "@syncfusion/ej2-base";
// // import { handleInputChange } from "react-select/dist/index-ea9e225d.cjs.prod";
// // import DefaultData from "./data"
// import { registerLicense } from "@syncfusion/ej2-base";
// // Registering Syncfusion license key.
// registerLicense(
//   "ORg4AjUWIQA/Gnt2V1hhQlJAfVddX2JWfFN0RnNYfVR0dl9EYEwgOX1dQl9gSXlRdEVjXX1bdHBRTmY="
// );

// DocumentEditorContainerComponent.Inject(Toolbar);

// export default class DocumentEditor extends React.Component {
//   // titleBar: TitleBar;
//   // container: DocumentEditorContainerComponent;
//   container;
//   constructor(props) {
//     super(props);
//     this.state = {
//       contentChanged: false,
//     };
//   }

//   // componentDidMount() {
//   //   ('componentDidMount:   ');

//   //   this.openTemplate();
//   // }

//   componentDidUpdate(prevProps) {
//     ("componentDidUpdate: ");

//     if (this.props.rendr) {
//       this.openTemplate();
//     }
//   }

//   openTemplate() {
//     ("openTemplate: ");
//     var dataContext = this;
//     (
//       "chinnu this.props?.fileJson ============>  1",
//       this.props?.fileJson
//     );
//     ("Object.keys(this.props?.fileJson).length: ");

//     if (Object.keys(this.props?.fileJson).length > 0) {
//       dataContext.container.documentEditor.open(this.props?.fileJson);
//     }
//     this.onContentChange();
//     this.props.setRendr(false);
//   }

//   onContentChange() {
//     ('onContentChange: ');
//     // this.setState({
//     //   contentChanged: true,
//     // });
//     // ("contentChanged",this.state.contentChanged)
//     // if (this.contentChanged) {

//     //You can save the document as below
//     // this.container.documentEditor.saveAsBlob("Docx").then(async(blob = Blob) => {
//     //   ("Saved sucessfully");
//     //   let exportedDocument = await blob.text();

//     ("this.props:============> 2 ", this.props);

//     this.container.documentEditor.saveAsBlob("Docx").then((blob = Blob) => {
//

//

//       // let exportedDocument = blob;
//       let fname = this.props.temName ? this.props.temName : "document";
//       const myFile = new File([blob], `${fname}.docx`, {
//         type: blob.type,
//       });

//       // let formData = new FormData();
//       // formData.append("upload_file", "sample.docx");
//       // formData.append("upload_file", exportedDocument);

//       this.props.setTemplateData(myFile);

//       //Now, save the document where ever you want.
//       /* tslint:disable */
//       // var req = new XMLHttpRequest();
//       // // Replace your running Url here
//       // req.open(
//       //   "POST",
//       //   "http://54.251.142.179:6007/api/v1/s3_image_upload",
//       //   true
//       // );
//       // req.onreadystatechange = () => {
//       //   if (req.readyState === 4) {
//       //     if (req.status === 200 || req.status === 304) {
//       //     }
//       //   }
//       // };
//       // req.send(formData);
//     });
//     this.contentChanged = false;
//     // }
//   }

//   render() {
//     return (
//       <div>
//         <div id="default_title_bar" className="e-de-ctn-title"></div>
//         <DocumentEditorContainerComponent
//           id="container"
//           ref={(scope) => {
//             this.container = scope;
//           }}
//           serviceUrl={
//             "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
//           }
//           height={"600px"}
//           width={"100%"}
//           style={{ width: "50%" }}
//           showPropertiesPane={false}
//           contentChange={this.onContentChange.bind(this)}
//         />
//       </div>
//     );
//   }
// }









import React from "react";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { registerLicense } from "@syncfusion/ej2-base";

// Register Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2V1hhQlJAfVddX2JWfFN0RnNYfVR0dl9EYEwgOX1dQl9gSXlRdEVjXX1bdHBRTmY="
);

DocumentEditorContainerComponent.Inject(Toolbar);

export default class DocumentEditor extends React.Component {
  container = null;

  constructor(props) {
    super(props);
    this.state = {
      contentChanged: false,
    };
  }

  componentDidMount() {
    ("componentDidMount:  -------> ");
    this.openTemplate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rendr !== prevProps.rendr) {
    }

    if (this.props.rendr && this.props.rendr !== prevProps.rendr) {
      this.openTemplate();
    }
  }

  openTemplate() {
    if (this.container && this.props.fileJson) {
      const { fileJson } = this.props;



      try {
        // Ensure fileJson is valid and has properties
        if (fileJson && Object.keys(fileJson).length > 0) {
        
          this.container.documentEditor.open(fileJson);
        } else {
          console.warn("fileJson is empty or invalid");
          // Optionally trigger a re-fetch or alternative logic
        }
      } catch (error) {
        console.error("Error opening fileJson:", error);
        this.rerenderDocument();
      }

      this.onContentChange();
      this.props.setRendr(false);
    } else {
      console.warn("Container or fileJson is not ready");
    }
  }

  rerenderDocument() {
    console.log("Attempting to rerender the document...");
  }

  // onContentChange = () => {
  //   if (this.container) {
  //     try {


  //    let blobFile =   this.container.documentEditor.saveAsBlob("Docx")
  //       .then((blob) => {
  //         const fileName = this.props.temName || "document";
  //         const file = new File([blob], `${fileName}.docx`, {
  //           type: blob.type,
  //         });

         

  //         if (file) {
  //           this.props.setTemplateData(file);
  //         }
  //       });

  //    console.log('blobFile: ', blobFile);


  //     } catch (err) {
  //       console.error("Error saving as Blob:", err);
  //     }
  //   } else {
  //     console.warn("Container is not ready for saving");
  //   }
  // };


  onContentChange = () => {

    const file = null ;
    console.log("this.container", this.container);
    if (this.container) {
      let blobFile = this.container.documentEditor.saveAsBlob("Docx");
      console.log('blobFile:========================= ', blobFile);
  
      blobFile
        .then(blob => {
          console.log("blob: ", blob);
          const fileName = this.props.temName ;
           file = new File([blob], `${fileName}.docx`, {
            type: blob.type,
          });
  
          if (file) {
            this.props.setTemplateData(file);
          }
        })
        .catch(err => {
          console.error("Error saving as Blob:", err);
        })
        .finally(() => {
          // Show file and status after operation
          console.log("Execution completed for blob save operation.");
          if (file) {
            console.log("File:", file);
          } else {
            console.warn("File could not be created");
          }
        });
    } else {
      console.warn("Container is not ready for saving");
    }
  };
  
  
  render() {
    return (
      <div>
        <div id="default_title_bar" className="e-de-ctn-title"></div>
        <DocumentEditorContainerComponent
          id="container"
          ref={(scope) => {
            this.container = scope;
          }}
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          height="600px"
          width="100%"
          style={{ width: "50%" }}
          showPropertiesPane={false}
          contentChange={this.onContentChange}
        />
      </div>
    );
  }
}
