import React, { Fragment, useEffect,useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import { connect } from "react-redux";
import * as actions from "../../actions/CompanySetups";
import * as actions1 from "../../actions/clientDetails/Document";
import * as actions3 from "../../actions/Client";
import Deletemodal from "./modals/DeleteModal";
import * as settings from "../../actions/Settings";
import AddNewDocument from "../client/modals/DocumentType";
import AddDocumentNew from "../client/modals/AddDocument";
import "./CompanySetups.css";
import moment from "moment";
import $, { valHooks } from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function DocumentId(props) {
  var table;
  var item= [];
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });
    props.getdocumentTypeListnew(props.match.params.id)
    return () => {
      table.destroy(true);
    };
  }, []);

  useEffect(()=>{
    props.getdocumentTypeListnew(props.match.params.id)
  },[props.docId]);

  useEffect(()=>{
    props.getdocumentTypeListcount(props.match.params.id)
  },[props.docId]);

  console.log("deep", props.companyList)

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState([]);

  
  
  // function handleRemove(id,i) {
  //   console.log("resid", id,i)
  //   props.DocumentTypeListnew.map((res,index)=>{
  //     // console.log("ressss", res, index);
  //     let row = [];
  //      if(res.id===id){
  //     row.splice(id,index,1);
  //     console.log("rowss", row);
  //       }
  //   })
    // const newDelete = delet.filter((item) => item.id !== id,i);
 
    // setDelet(newDelete);
  // }

  const handleRemove = (idx) => {
        console.log("resid", idx)
        props.changeClientInfo("docId", idx);
        props.changeClientInfo("docFlag", 1);
        
}
const callFilterAPI= ()=>{
  let data={
    id:props.match.params.id,
    directorcount:props.directorcount,
    shareholdercount:props.shareholdercount,
    
    secratorycount:props.secratorycount
  }
  props.filterdocument(data)
}

  useEffect(() => {
    
    function format(d) {
      console.log("did", d);
      return (
        `<tr id="detail-view-${d?.id}"><td colspan="7" style="padding:0px;"><table id="detail-view-${d?.id}"   >` +
        "<tr>" +
        "<td>Remark:</td>" +
        "<td>" +
        `${(() => {
          if (d.remarks != undefined) {
            return d?.remarks
          } else {
            return ''
          }
        })()}`
        +
        "</td>" +
        "</tr>" +
        `</table></td></tr>`
      );
    }

    $.fn.DataTable.ext.pager.numbers_length = 10;
    $(document).ready(function () {
      $("#example").on("click", ".btnDel", function () {
        var data = table.row(this).data();
        props.changeClientInfo("company_id", this.name);
        props.changeClientInfo("deleteCompany", true);
      });
      table = $("#resolution").DataTable({
        
        pageLength:25,
        lengthMenu: [ 25, 50 ],
        scrollCollapse: true,
        paging: true,

        destroy: true,
     

        data: props.companyList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            orderable: true,
            searchable: true,
            className: " col-xs-12 col-sm-12 col-md-12 col-lg-12",
            render: function (data, type, row) {
              return `<span/>${row.date}</span>`;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#0075b0");
          }
        },
        createdRow: function (row, data, dataIndex) {
          $("td:eq(0)", row).attr("colspan", 2).css("color", "white");
          $("td:eq(1)", row).css("display", "none").css("width", "75px");
          $("td:eq(2)", row).css("display", "none").css("width", "75px");
        
        },
      });

      table.rows().every(function () {
        var rowdata = this.data();
        var dataChild = rowdata.list;
        dataChild && 
        this.child(
          dataChild.map((item) => {
            console.log("items",item.length)
            let clr = '';
            if (item.is_approved == 0) {
              clr = '#f44242'
            } else {
              clr = '<span style="margin-left: 30px;">---</span>';
            }

            
            return $(
              `<tr style="background-color: ${clr}" >` +
             
              "<td class=''>" +
              `${(() => {
                if (item.id != '' && item.id != null && item.id != undefined) {
                  return item.attachment.split('/').pop().split('?')[0].split('#')[0];
                }

              })()}
              ${(() => {
                if (item.id != '' && item.id != null && item.id != undefined  ) {
                  return'<a target="_blank" href="'+item.attachment+'"><span class="document glyphicon glyphicon-paperclip" id="'+item.docids+'" /></a>';
                } 
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (
                  item.created_date != null
                ) {
                  return moment(item.created_date).format("DD/MM/YYYY");
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}` +
              "</td>" +
              
              "</tr>"
            );
          })
        ).show();
      });
    });
  }, [props.companyList]);


  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
      <div id="share-resolution-tab" className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                <i class="glyphicon glyphicon-folder-open"></i> &nbsp;&nbsp; Documents         
                </div>
              </nav>
              <div className="box-tools pull-right" > 
                  </div>
              
            </div>
            <div />
          </div>
          <div class="drop-down">
          <div>
          <label for="director">Director Count</label>
<br/>
              <select class= "form-control" name="director" id="director" onChange={(e) =>
                            props.changeClientInfo("directorcount", e.target.value)
                          } >
                <option value="0">All</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              </div>
              <div>
          <label for="secretary">Secretary Count</label>
<br/>
              <select class= "form-control" name="secretary" id="secretary"  onChange={(e) =>
                            props.changeClientInfo("secratorycount", e.target.value)
                          }>
                <option value="0">All</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              </div>
              <div>
          <label for="Shareholder">Shareholder Count</label>
<br/>
              <select class= "form-control" name="Shareholder" id="Shareholder"  onChange={(e) =>
                            props.changeClientInfo("shareholdercount", e.target.value)
                          } >
                <option value="0">All</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              </div>
              <br/>
              <button class="btn form-control btn-success" style={{width:"100px",marginTop:"11px"}} type="submit" id="addnew" value="new"  onClick={(e)=>callFilterAPI()}>Submit</button>
</div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="share-action_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                {
                    props.DocumentTypeListnew &&   props.DocumentTypeListnew.length <= 0 && "No Templates Found"
                }
              <table id="example">
              { 
              
                            props.DocumentTypeListnew && props.DocumentTypeListnew.map((res,index)=>{
                                console.log("res", res);
                                var linkgen="https://api-gbs.smitiv.co/var/www/html/new-setup/aactt-node/common/predefinedTemplates/"+res.code+".docx"
                                return (
                                <>
                                <tr>
                                  <td style={{padding:"10px"}}>
                                <a class="folder-name" href={linkgen} >
                                <i class="glyphicon glyphicon-folder-open"></i> &nbsp;&nbsp;&nbsp;
                                  { res.Name}
                                </a> </td><td><button  class="btnDel btn btn-primary btn-s"   id="test" title="Delete" name="test" onClick={() => handleRemove(res.id)}><span class="glyphicon glyphicon-trash" /></button>
                                  </td>
                                </tr>
                                </>
                              )
                            })

                          }
              </table>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
      </section></div>
      <AddNewDocument />
      <AddDocumentNew />
      <Deletemodal />
      <Footer />
      </div>

      {/* ./wrapper */}
    
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
  } = state.ShareReg;
  console.log('state', state.Settings)
  return {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
    deleteModal: state.Deadline.deleteModal,
    docId:state.Client.docId,
    directorcount:state.Client.directorcount,
    secratorycount:state.Client.secratorycount,
    shareholdercount:state.Client.shareholdercount,
    processModal:state.Client.processModal,
    companyList: state.CompanySetups.companyList,
    DocumentTypeListnew: state.Settings.DocumentTypeListnew,
    DocumentTypeListcount: state.Settings.DocumentTypeListcount,
    DocumentTypeList: state.Settings.DocumentTypeList,
    
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeClientInfo: (field, value) =>
    dispatch(actions3.changeClientInfo(field, value)),
    sendValues: (k, v) => dispatch(actions1.documentFunction(k, v)),
    getdocumentTypeListnew: (data)=> dispatch(settings.getDocumentClassificationbyidnew(data)),
    filterdocument: (data)=> dispatch(settings.getDocumentClassificationbyidFilter(data)),
    
    getdocumentTypeListcount: (data)=> dispatch(settings.getDocumentCountbyidnew(data)),
    getDocumentList: () => dispatch(settings.getDocumentClassification_new()),
    getGlobaldocumentList: () => dispatch(actions.getGlobaldocumentList()),
    changeCompanyInfo: (f, e) => dispatch(actions.changeCompanyInfo(f, e)),
    addCompany: (data) => dispatch(actions.addCompany(data)),
    updateCompanyErrors: (data) => dispatch(actions.updateCompanyErrors(data)),
    reset_company_info: () => dispatch(actions.reset_company_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentId);
