import React, { Fragment, useEffect, useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import history from "../../stores/history";
import Footer from "../Footer";
import { connect } from "react-redux";
import * as actions from "../../actions/CompanySetups";
import * as client from "../../actions/Client";
import "../administration/CompanySetups.css";
import moment from "moment";
import $, { valHooks } from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function PendingDocuments(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
      $("#resolution tbody").on("click", ".document", function (e) {
        var targetid = e.target.id;

        if (targetid != "") {
          var splitidValue = targetid.split("_");
          localStorage.setItem("client_key_id", splitidValue[0]);
          localStorage.setItem("agree_status", splitidValue[1]);

          setTimeout(() => {
            history.push("/signdocument");
          }, 1000);
        }
      });
    });

    //props.getdocumentManagementList();
    var company_ids = localStorage.getItem("all_company");
    var userid = localStorage.getItem("login_userid");
    var status = 1;
    var inputdata = {
      company_ids: company_ids,
      status: status,
      userid: userid,
      office_bearer_id: localStorage.getItem("office_bearer_ids"),
    };
    props.getdocumentpendingList(inputdata);

    return () => {
      table.destroy(true);
    };
  }, []);
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
      $("#resolution tbody").on("click", ".document", function (e) {
        var targetid = e.target.id;

        if (targetid != "") {
          var splitidValue = targetid.split("_");
          localStorage.setItem("client_key_id", splitidValue[0]);
          localStorage.setItem("agree_status", splitidValue[1]);

          setTimeout(() => {
            history.push("/signdocument");
          }, 1000);
        }
      });
    });

    //props.getdocumentManagementList();
    var company_ids = localStorage.getItem("all_company");
    var userid = localStorage.getItem("login_userid");
    var status = 1;
    var inputdata = {
      company_ids: company_ids,
      status: status,
      userid: userid,
      office_bearer_id: localStorage.getItem("office_bearer_ids"),
    };
    props.getdocumentpendingList(inputdata);

    return () => {
      table.destroy(true);
    };
  }, [props.clientApiStatus]);
  console.log("deep", props.companyList);
  console.log("client_key", props.client_key);
  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");

  useEffect(() => {
    function format(d) {
      let approve = "inline-block";
      let words = "";
      let reject = "inline-block";
      // if((d.accepteddata == null || d.accepteddata == "") && (d.paccepteddata == null || d.paccepteddata == "") && (d.rejecteddata == null || d.rejecteddata == "")){
      //   words="No one Accepted Document Yet"
      // }else{
      if (d.accepteddata != null && d.accepteddata != "") {
        words = words + d.accepteddata + " Agreed <br/> ";
      }
      if (d.paccepteddata != null && d.paccepteddata != "") {
        words = words + d.paccepteddata + " Partially Agreed <br/>";
      }
      if (d.rejecteddata != null && d.rejecteddata != "") {
        words = words + d.rejecteddata + " Disagreed <br/>";
      }
      if (d.notagreed != null && d.notagreed != "") {
        words = words + d.notagreed + " not Agreed/Disagreed yet <br/>";
      }

      //}
      return (
        `<tr id="detail-view-${d?.docid}"><td colspan="7" style="padding:0px;" ><table id='detail-view-${d?.docid}'  style="width:100%" >` +
        "<tr>" +
        "<td>Document Status : " +
        words +
        "</td>" +
        "</tr>" +
        "</table></td></tr>"
      );
    }

    $(document).ready(function () {
      table = $("#resolution").DataTable({
        pageLength: 25,
        lengthMenu: [25, 50],
        paging: true,

        destroy: true,

        data: props.companyList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            orderable: true,
            searchable: true,
            className: " col-xs-12 col-sm-12 col-md-12 col-lg-12",
            render: function (data, type, row) {
              return `<span/>${row.company_name}</span>`;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#0075b0");
          }
        },
      });
      $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
        $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
      });

      table.rows().every(function () {
        var rowdata = this.data();
        var dataChild = rowdata.list;
        dataChild &&
          this.child(
            dataChild.map((item) => {
              console.log("item", item);
              let clr = "";
              if (item.is_approved == 0) {
                clr = "#fff";
              } else {
                clr = '<span style="margin-left: 30px;">---</span>';
              }
              return $(
                `<tr style="background-color: ${clr}" class="test " >` +
                  `<td id=expand${item.docid} title=${item.docid} name=${item.docid} class='details-control' >` +
                  // item.id +
                  "</td>" +
                  "<td class=''>" +
                  `${item.docs_classification}` +
                  "</td>" +
                  "<td class=''>" +
                  `${(() => {
                    if (
                      item.attachment != null &&
                      item.attachment != undefined
                    ) {
                      return item.attachment
                        .split("/")
                        .pop()
                        .split("?")[0]
                        .split("#")[0]
                        .replace(/[/\\?%*:|"<>]/g, "-");
                    }
                  })()}
              ${(() => {
                if (item.file_path != "") {
                  return (
                    '<a href="https://api-gbs.smitiv.co' +
                    item.file_path +
                    '"><span class="glyphicon glyphicon-paperclip"  /></a>'
                  );
                }
              })()}` +
                  "</td>" +
                  "<td class=''>" +
                  `${moment(item.created_on).format("DD/MM/YYYY")}` +
                  "</td>" +
                  `${(() => {
                    if (item.countsign > 0) {
                      return `<td class=''>` + item.document_stat + `</td>`;
                    } else {
                      return `<td class=''>
                  <button class="document  btn btn-primary btn-xs" title="signature" id=${
                    item.docid + "_" + 1
                  }>Agree</button>
                  <button class="document  btn btn-primary btn-xs" title="signature" id=${
                    item.docid + "_" + 2
                  }>Partially Agree</button>
                  <button class="document  btn btn-primary btn-xs" title="signature" id=${
                    item.docid + "_" + 3
                  }>DisAgree</button></td>`;
                    }
                  })()}
             ` +
                  "</tr>"
              );
            })
          ).show();
      });

      $("#resolution").on("click", ".btnAccept", function () {
        console.log("this", this.name);
        props.sendValues("document_id", this.name);
        props.sendValues("acceptDocumentModal", true);
      });

      $("#resolution").on("click", ".details-control", function (e) {
        let data;
        props.companyList &&
          props.companyList.map((ite, k) => {
            ite.list &&
              ite.list.map((val, idx) => {
                if (val.docid == this.title) {
                  data = val;
                }
              });
          });
        var tr = $(this).closest("tr");
        var row = table.row(tr);
        console.log("tr", row);
        console.log("rowing", table);

        // if (row.child.isShown()) {
        //   // This row is already open - close it
        //   row.child.hide();
        //   tr.removeClass("shown");
        //   $("tr.shown td.details-control").toggleClass(
        //     "tr.shown td.details-control"
        //   );
        // } else {
        // Open this row

        // document.getElementById(this.title).appendChild($.parseHTML(format(data)))
        console.log(
          "dom",
          $(`#detail-view-${this.title}`).length,
          tr.hasClass("shown")
        );
        // if (!$(`#detail-view-${this.title}`).length) {
        //   $(`#${this.title}`).after(format(data));
        //   tr.addClass("shown");
        // } else {
        //   tr.removeClass("shown");
        //   $(`#detail-view-${this.title}`).remove();
        // }

        if (!tr.hasClass("shown") && !$(`#detail-view-${this.title}`).length) {
          console.log("remove", $(`#detail-view-${this.title}`));
          if (data && Object.keys(data).length > 0) {
            tr.after(format(data));
            tr.addClass("shown");
          }
        } else if (
          !tr.hasClass("shown") &&
          $(`#detail-view-${this.title}`).length
        ) {
          $(`#detail-view-${this.title}`).css("display", "");
        } else {
          console.log("else");
          tr.removeClass("shown");
          $(`#detail-view-${this.title}`).css("display", "none");

          // $(`#detail-view-${this.title}`).remove();
        }
      });
    });
  }, [props.companyList]);

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
            <div id="share-resolution-tab" className="tab-pane fade active in">
              <div className="box box-primary box_align_left" boxHeight>
                <div className="box-header">
                  {" "}
                  <i className="glyphicon glyphicon-folder-open" />
                  <h3 className="box-title">Pending Documents</h3>
                </div>
                <div className="box-body" boxHeight>
                  <div className="table-responsive">
                    <div
                      id="share-action_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                    >
                      <table
                        className="table table-striped table-bordered col-md-12  dataTable no-footer"
                        role="grid"
                        style={{ marginLeft: 0, padding: 0 }}
                        id="resolution"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="details-control col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="Details"
                            ></th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Doc Type
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Filename
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Doc. Date
                            </th>

                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>

                      <div
                        id="share-action_processing"
                        className="dataTables_processing panel panel-default"
                        style={{ display: props.processModal }}
                      >
                        Processing...
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer clearfix no-border"></div>
              </div>
              <p />
            </div>
          </section>
        </div>
        <Footer />
      </div>

      {/* ./wrapper */}
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { shareResolutionList, shareHolderList, ShareTypeList, shareId } =
    state.ShareReg;
  return {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
    deleteModal: state.Deadline.deleteModal,
    processModal: state.Client.processModal,
    companyList: state.CompanySetups.companyList,
    client_key: state.Client.client_key,
    clientApiStatus: state.Client.clientApiStatus,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getdocumentpendingList: (data) =>
      dispatch(actions.getdocumentpendingList(data)),
    getdocumentManagementList: () =>
      dispatch(actions.getdocumentManagementList()),
    changeCompanyInfo: (f, e) => dispatch(actions.changeCompanyInfo(f, e)),
    addCompany: (data) => dispatch(actions.addCompany(data)),
    updateCompanyErrors: (data) => dispatch(actions.updateCompanyErrors(data)),
    reset_company_info: () => dispatch(actions.reset_company_info()),
    setValue: (f, v) => dispatch(client.changeClientInfo(f, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingDocuments);
