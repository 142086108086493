import React, { Fragment, useEffect, useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import history from "../../stores/history";
import Footer from "../Footer";
import { connect } from "react-redux";
import * as actions from "../../actions/CompanySetups";
import * as client from "../../actions/Client";
import "../administration/CompanySetups.css";
import moment from "moment";
import $, { valHooks } from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function CompletedDocument(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getCompleteddocumentList();

    return () => {
      table.destroy(true);
    };
  }, []);

  console.log("deep", props.companyList);

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");

  useEffect(() => {
    function format(d) {
      console.log("did", d);
      return (
        `<tr id="detail-view-${d?.id}"><td colspan="7" style="padding:0px;"><table id="detail-view-${d?.id}"   >` +
        "<tr>" +
        "<td>Remark:</td>" +
        "<td>" +
        `${(() => {
          if (d.remarks != undefined) {
            return d?.remarks;
          } else {
            return "";
          }
        })()}` +
        "</td>" +
        "</tr>" +
        `</table></td></tr>`
      );
    }

    $(document).ready(function () {
      table = $("#resolution").DataTable({
        pageLength: 25,
        lengthMenu: [25, 50],
        paging: true,

        destroy: true,

        data: props.companyList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            orderable: true,
            searchable: true,
            className: " col-xs-12 col-sm-12 col-md-12 col-lg-12",
            render: function (data, type, row) {
              return `<span  style="cursor: pointer;" class="company" name=${row.date}  id=${row.company_id} >${row.date}</span>`;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#0075b0");
          }
        },
        createdRow: function (row, data, dataIndex) {
          if (data && data.list !== undefined && data.list.length > 0) {
            $("td:eq(0)", row).attr("colspan", 6).css("color", "white");
            $("td:eq(1)", row).css("display", "none").css("width", "75px");
            $("td:eq(2)", row).css("display", "none").css("width", "75px");
            $("td:eq(3)", row).css("display", "none").css("width", "75px");
            $("td:eq(4)", row).css("display", "none").css("width", "75px");
            $("td:eq(5)", row).css("display", "none").css("width", "75px");
            $("td:eq(6)", row).css("display", "none").css("width", "75px");
          } else {
            $("td:eq(0)", row).attr("colspan", 6).css("display", "none");
            $("td:eq(1)", row).css("display", "none").css("width", "75px");
            $("td:eq(2)", row).css("display", "none").css("width", "75px");
            $("td:eq(3)", row).css("display", "none").css("width", "75px");
            $("td:eq(4)", row).css("display", "none").css("width", "75px");
            $("td:eq(5)", row).css("display", "none").css("width", "75px");
            $("td:eq(6)", row).css("display", "none").css("width", "75px");
          }
        },
      });
      $("#resolution tbody").on("click", ".company", function (e) {
        console.log("event", e.target.id);
        var company_nam = $("#" + e.target.id).text();

        if (
          localStorage.getItem("permissions") &&
          localStorage.getItem("permissions").includes(18)
        ) {
          localStorage.setItem("personalId", e.target.id);
          console.log(
            company_nam,
            typeof company_nam,
            $("#" + e.target.id).text(),
            "ccccd"
          );
          localStorage.setItem("selectedClient", company_nam);
          setTimeout(() => {
            history.push("/client_individual");
          }, 500);
          // history.push("/client_individual");
        }
      });
      table.rows().every(function () {
        var rowdata = this.data();
        var dataChild = rowdata.list;
        dataChild &&
          this.child(
            dataChild.map((item) => {
              console.log("items", item);

              let clr = "";
              if (item.is_approved == 0) {
                clr = "#f44242";
              } else {
                clr = '<span style="margin-left: 30px;">---</span>';
              }
              return $(
                `<tr style="background-color: ${clr}" >` +
                  "<td class=''>" +
                  `${item.docs_classification}` +
                  "</td>" +
                  "<td class=''>" +
                  `${(() => {
                    if (
                      item.attachment != "" &&
                      item.attachment != null &&
                      item.attachment != undefined
                    ) {
                      return item.attachment
                        .split("/")
                        .pop()
                        .split("?")[0]
                        .split("#")[0]
                        .replace(/[/\\?%*:|"<>]/g, "-");
                    }
                  })()}
              ${(() => {
                if (
                  item.attachment != "" &&
                  item.attachment != null &&
                  item.attachment != undefined
                ) {
                  return (
                    '<a href="' +
                    item.attachment +
                    '"><span class="document glyphicon glyphicon-paperclip" id="' +
                    item.docids +
                    '" /></a>'
                  );
                }
              })()}` +
                  "</td>" +
                  "<td class=''>" +
                  `${moment(item.created_on).format("DD/MM/YYYY")}` +
                  "</td>" +
                  "<td class=''>" +
                  `${(() => {
                    if (item.status == 77) {
                      return "completed";
                    } else {
                      return "completed";
                    }
                  })()}
              ` +
                  "</td>" +
                  `
             ` +
                  "</tr>"
              );
            })
          ).show();
      });
    });
  }, [props.companyList]);

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
            <div id="share-resolution-tab" className="tab-pane fade active in">
              <div className="box box-primary box_align_left" boxHeight>
                <div className="box-header">
                  {" "}
                  <i className="glyphicon glyphicon-folder-open" />
                  <h3 className="box-title">Completed Documents</h3>
                  <div />
                </div>
                <div className="box-body" boxHeight>
                  <div className="table-responsive">
                    <div
                      id="share-action_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                    >
                      <table
                        className="table table-striped table-bordered col-md-12 dataTable no-footer"
                        role="grid"
                        style={{ marginLeft: 0, padding: 0 }}
                        id="resolution"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="details-control col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="Details"
                            >
                              Doc. Type
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Filename
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Doc. Date
                            </th>

                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>

                      <div
                        id="share-action_processing"
                        className="dataTables_processing panel panel-default"
                        style={{ display: props.processModal }}
                      >
                        Processing...
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer clearfix no-border"></div>
              </div>
              <p />
            </div>
          </section>
        </div>
        <Footer />
      </div>

      {/* ./wrapper */}
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { shareResolutionList, shareHolderList, ShareTypeList, shareId } =
    state.ShareReg;
  return {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
    deleteModal: state.Deadline.deleteModal,
    processModal: state.Client.processModal,
    companyList: state.CompanySetups.companyList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getCompleteddocumentList: () =>
      dispatch(actions.getCompleteddocumentList()),
    changeCompanyInfo: (f, e) => dispatch(actions.changeCompanyInfo(f, e)),
    addCompany: (data) => dispatch(actions.addCompany(data)),
    updateCompanyErrors: (data) => dispatch(actions.updateCompanyErrors(data)),
    reset_company_info: () => dispatch(actions.reset_company_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedDocument);
