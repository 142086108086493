import React, { Fragment, useEffect,useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/Client";
import EditDocument from "../modals/EditDocument";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

function AddNewSettingItem(props) {
  const [flag, setFlag] = useState(0);
  useEffect(() => {
    var acttext=props.client_key.replaceAll(/----/g,",");
    var acttext_splited_vals=acttext;
    var splitdata=acttext_splited_vals.split("_").length;
    console.log("splitdata",splitdata)
    if(splitdata > 2){
      setFlag(1)
    }
    // console.log(acttext,"acttext")
    props.allListDocuments_uploaded(acttext)
    
  }, [props.client_key]);

  const deletedocument=(id)=>{
    props.DeleteDocument(id);
    var acttext=props.client_key.replaceAll(/----/g,",");
    props.setFieldValues("attachmentModal", false)
    props.allListDocuments_uploaded(acttext)

  }
  const editdocumentnew=(id)=>{
   
    props.setFieldValues("idshowelement",id);
    props.setFieldValues("attachmentModal", false);
    props.setFieldValues("editdocumentmodel", true)
  }
  
  // console.log("idshowelement",props.idshowelement)
  return (
    <Fragment>
      
    
    <Modal
      show={props.show}
      size="lg"
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      aria-hidden="true"
      id="newcurrency"
      aria-labelledby="newcurrency"
      tabIndex={-1}
      onHide={() => props.setFieldValues("attachmentModal", false)}
    >
       
       
          <div className="modal-dialog" style={{width:"100%"}}>
            <div className="modal-content">
              <div className="modal-header bootstrap-dialog-draggable">
                <div className="bootstrap-dialog-header">
                  <div className="bootstrap-dialog-close-button"><button onClick={() => props.setFieldValues("attachmentModal", false)} className="close" aria-label="close">×</button>
                  </div>
                  <div className="bootstrap-dialog-title" id="878033fa-7bf9-4c61-975e-f83131033c7a_title">Show
                    Documents</div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bootstrap-dialog-body">
                  <div className="bootstrap-dialog-message">
                    <div className="bootstrap-dialog-body">
                        {props.clientApiStatus ? (
                        <div
                          className={`alert ${
                            props.clientApiStatus == 3 ? "alert-success" : "alert-danger"
                          }`}
                          role="alert"
                        >
                          {props.message}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th>Filename</th>
                              <th>Document Type</th>
                              <th>Notes</th>
                              <th>Global/Local</th>
                              <th>Resolution/Document Date</th>
                              <th>Actions</th>
                            </tr>
                            {
                              props.alldocumentclientList_show && props.alldocumentclientList_show.map((val,i)=>{
                                var splitids=val.id;

                                return(

                                  <>
                                  <tr>
                                  <td>
  <a href={val.attachment} target="_blank">
    {decodeURIComponent(val.attachment.split('/').pop())}
  </a>
</td>

                                  <td>{ val.doctypevalue} </td>
                                  <td>{ val.notes} </td>
                                  <td>{ Number(val.globalstatus) == 1 || Number(val.globalstatus) == 0 ? "Global" : "Local"} </td>

                                  <td>{val.resolution_date !== "0000-00-00" ? moment(val.resolution_date).format("DD/MM/YYYY") : "-"}</td>
                                  <td style={{display: "flex"}}>
                                    <button onClick={(e)=>editdocumentnew(val.id)} className="btn btn-primary btn-xs" title="Edit" ><img className="p-0" data-toggle="modal" data-target="#editmodal" src="/assets/img/eye-white.svg" /></button> &nbsp; 
                                    {
                                      flag == 0 &&
                                      <button onClick={()=> deletedocument(val.id)} className="btn btn-primary btn-xs" title="Delete" ><span className="glyphicon glyphicon-trash" /></button>
                                    }  
                                  </td>
                                </tr>
                                  </>
                                )
                                
                              })
                            }
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" style={{display: 'none'}}>
                <div className="bootstrap-dialog-footer" />
              </div>
            </div>
          </div>
    </Modal>
    <EditDocument />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.Client.attachmentModal,
    client_key: state.Client.client_key,
    alldocumentclientList_show: state.Client.alldocumentclientList_show,
    idshowelement: state.Client.idshowelement,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    editdocumentmodel:state.Client.editdocumentmodel,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (field, value) =>
      dispatch(actions.changeClientInfo(field, value)),
      allListDocuments_uploaded: (data) =>
      dispatch(actions.allListDocuments_uploaded(data)),
      DeleteDocument: (data) =>
      dispatch(actions.DeleteDocument(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewSettingItem);
