import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import {
  deadline,
  getDeadlineList,
  getDeadlineDrop,
  getReminderType,
} from "../../../actions/clientDetails/Deadline";
import * as personalActions from "../../../actions/clientDetails/Personal";
import AddDeadline from "../modals/AddDeadline";
import Nothing from "../modals/NothingSelected";
import EditDeadline from "../modals/EditDeadline";
import Approve from "../modals/ApproveDeadline";
import NewTask from "../modals/newTask";
import EditTask from "../modals/EditTask";
import Delete from "../modals/DeleteDeadline";
import $ from "jquery";
import moment from "moment";

function Deadline(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getDeadlineList(localStorage.getItem("personalId"));
    props.getPersonalList(localStorage.getItem("personalId"));
    props.getDeadlineDrop();
    props.getReminderType();

    if (
      localStorage.getItem("permissions") &&
      localStorage.getItem("permissions").includes(41)
    ) {
      setEdit("inline-block");
    }

    if (
      localStorage.getItem("permissions") &&
      localStorage.getItem("permissions").includes(42)
    ) {
      setDelet("inline-block");
    }
  }, []);

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");
  const [task, setTask] = useState(false);
  // console.log("props deadline",props.deadlineList)
  useEffect(() => {
    function format(d) {
      console.log("dis", d);
      let array;
      if (d.tasks.length != 0) {
        array = d.tasks;
      } else {
        array = [];
      }

      table.on("click", "button.expandEdit", function () {
        console.log("edit");
        var data = table.row(this).data();
        array.map((val, idx) => {
          if (val.id == this.name) {
            props.clientdeadline &&
              props.clientdeadline.map((dead) => {
                if (val.task_service_id == dead.id) {
                  props.sendValues("taskDeadline", dead.id);
                }
              });
            if (new Date(val.start_date) != "Invalid Date") {
              props.sendValues("taskStartDate", new Date(val.start_date));
            }
            if (new Date(val.end_date) != "Invalid Date") {
              props.sendValues("taskEndDate", new Date(val.end_date));
            }
            if (new Date(val.deadline_date) != "Invalid Date") {
              props.sendValues("taskDeadlineDate", new Date(val.deadline_date));
            }
            if (new Date(val.financial_year) != "Invalid Date") {
              props.sendValues(
                "taskFinancialYear",
                new Date(val.financial_year)
              );
            }

            props.sendValues("taskDrop", val.task_service_id);
            props.sendValues("taskNotes", val.notes);
            props.sendValues("taskAssigned", val.user_id);
            props.sendValues("taskStatus", val.status);
            props.sendValues("taskStartTime", val.start_time);
            props.sendValues("taskEndTime", val.end_time);
            props.sendValues("editTask", true);
            props.sendValues("task_id", val.id);
          }
        });
      });

      table.on("click", "button.expandDel", function () {
        console.log("del");
        var data = table.row(this).data();
        props.sendValues("task_id", this.name);
        props.sendValues("deleteModal", true);
        props.sendValues("taskDelete", true);
      });

      table.on("click", "button.expandsub", function () {
        props.clientdeadline.map((cli) => {
          if (cli.id == d.client_dedadline_task_id) {
            props.sendValues("deadlineName", cli.Name);
          }
          props.sendValues("taskDeadlineDate", d.deadline_date);
          props.sendValues("taskFinancialYear", d.financial_year);
          props.sendValues("deadline_id", d.id);
          props.sendValues("newTask", true);
          props.sendValues("subTask", true);
        });
      });

      {
        return (
          '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;width:100%;background-color:#FF0000" >' +
          "<tr>" +
          "<th>Task</th>" +
          "<th>Start</th>" +
          "<th>Finish</th>" +
          "<th>Status</th>" +
          "<th>Assigned</th>" +
          "<th>Notes</th>" +
          "<th>Actions</th>" +
          "</tr>" +
          array.map((val) => {
            let user = "";
            let status = "";
            let task = "";
            props.personalList.map((per) => {
              if (per.id == val.user_id) {
                user = per.name;
              }
            });
            if (val.status == 1) {
              status = "Open";
            } else if (val.status == 2) {
              status = "Completed";
            }

            props.clientdeadline &&
              props.clientdeadline.map((dead) => {
                if (val.task_service_id == dead.id) {
                  task = dead.Name;
                }
              });

            if (array.length != 0) {
              return (
                "<tbody>" +
                "<tr>" +
                `<td>${task}</td>` +
                `<td>${moment(val.start_date).format("DD/MM/YYYY")}</td>` +
                `<td>${moment(val.end_date).format("DD/MM/YYYY")}</td>` +
                `<td>${status}</td>` +
                `<td>${user}</td>` +
                `<td>${val.notes}</td>` +
                `<td><button class="expandEdit btn btn-primary btn-xs" id=edit${val.id} title="Edit" name=${val.id}  ><span class="glyphicon glyphicon-pencil" /></button>
      <button class="expandDel btn btn-primary btn-xs" id=delete${val.id} title="Delete"  name=${val.id} onclick="deleteFunc()"  ><span class="glyphicon glyphicon-trash" /></button>
      <button class="expandsub btn btn-primary btn-xs" id=newtask${val.id} title="Add Sub Task"  name=${val.id}  ><span class="glyphicon glyphicon-tasks" /></button></td>` +
                "</tr>" +
                "</tbody>"
              );
            } else {
              return "<tr>No Data</tr>";
            }
          }) +
          "</table>"
        );
      }
    }

    $(document).ready(function () {
      table = $("#deadline").DataTable({
        paging: true,
        destroy: true,
        data: props.deadlineList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
            // "targets": 12,
          },
          {
            targets: 0,
            orderable: false,
            searchable: false,
            className: "details-control col-xs-1 col-sm-1 col-md-1 col-lg-1",
          },
          {
            targets: 1,
            orderable: false,
            searchable: false,
            className: " btnbox col-xs-1 col-sm-1 col-md-1 col-lg-1",
            render: function (data, type, row) {
              if (row.check == true) {
                return `<input class="check" id=check${row.id}  name=""  checked type="checkbox" />`;
              } else {
                return `<input class="check" id=check${row.id}  name=${row.id}   type="checkbox" />`;
              }
            },
          },
          {
            targets: 2,
            orderable: true,
            className: "col-xs-2 col-sm-2 col-md-2 col-lg-1",
            data: "client_dedadline_task_id",
            render: function (data, type, row) {
              if (props.clientdeadline.length != 0) {
                let activeName = props.clientdeadline.filter(
                  (item) => item.id == data
                );
                return `<span>${activeName[0].Name}</span>`;
              }
            },
          },
          {
            targets: 3,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "financial_year",
            render: function (data, type, row) {
              if (
                data != null &&
                data != undefined &&
                moment(data).format("DD/MM/YYYY") != "Invalid date"
              ) {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },
          {
            targets: 4,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "deadline_date",
            render: function (data, type, row) {
              if (
                data != null &&
                data != undefined &&
                moment(data).format("DD/MM/YYYY") != "Invalid date"
              ) {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },
          {
            targets: 5,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "date_submitted",
            render: function (data, type, row) {
              if (
                data != null &&
                data != undefined &&
                moment(data).format("DD/MM/YYYY") != "Invalid date"
              ) {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },
          {
            targets: 6,
            orderable: true,
            className: "col-xs-4 col-sm-4 col-md-4 col-lg-3",
            data: "remarks",
            render: function (data, type, row) {
              if (data != null && data != undefined && data != "") {
                return data;
              } else {
                return '<span style="margin-left: 95px;">---</span>';
              }
            },
          },
          {
            targets: 7,
            orderable: true,
            className: "col-xs-2 col-sm-2 col-md-2 col-lg-2",
            data: "id",
            render: function (data, type, row) {
              return `<button class="btnEdit btn btn-primary btn-xs" style="display:${edit}" id=edit${data} title="Edit" name=${data} onClick="console.log('Hello',title)" ><span class="glyphicon glyphicon-pencil" /></button>
                <button class="btnDel btn btn-primary btn-xs" style="display:${delet}" id=delete${data} title="Delete"  name=${data} data-toggle="modal" data-target="#deletemodal""><span class="glyphicon glyphicon-trash" /></button>
                <button class="btnTask btn btn-primary btn-xs"  id=newtask${data} title="New Task" name=${data}  ><span class="glyphicon glyphicon-tasks" /></button>`;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#f44242");
          } else {
            $(row).css("background-color", "#ffffff");
          }
        },
      });
      $("#deadline tbody").on("click", ".btnEdit", function () {
        var data = table.row(this).data();
        props.deadlineList &&
          props.deadlineList.map((val, idx) => {
            if (val.id == this.name) {
              if (val.is_start_reminder == 1) {
                props.sendValues("clickStart", true);
              } else {
                props.sendValues("clickStart", false);
              }

              if (val.is_completed == 1) {
                props.sendValues("isCompleted", true);
              } else {
                props.sendValues("isCompleted", false);
              }
              props.sendValues("clientDeadline", val.client_dedadline_task_id);
              props.sendValues("frequency", val.frequency);
              if (new Date(val.financial_year) != "Invalid Date") {
                props.sendValues("finYear", new Date(val.financial_year));
              }
              if (new Date(val.remainder_date) != "Invalid Date") {
                props.sendValues("remainderDate", new Date(val.remainder_date));
              }
              if (new Date(val.deadline_date) != "Invalid Date") {
                props.sendValues("deadlineDate", new Date(val.deadline_date));
              }
              if (
                val.receipent_to != null &&
                val.receipent_to != undefined &&
                val.receipent_to != ""
              ) {
                props.sendValues("to", JSON.parse(val.receipent_to));
              }
              if (
                val.receipent_cc != null &&
                val.receipent_cc != undefined &&
                val.receipent_cc != ""
              ) {
                props.sendValues("cc", JSON.parse(val.receipent_cc));
              }
              props.sendValues("remType", val.reminder_type_id);
              props.sendValues("remark", val.remarks);
              props.sendValues("docLink", val.document_link);
              props.sendValues("docId", val.document_id);
              // props.sendValues("attachment", val.attachment);
              props.sendValues("attachmentKey", val.attachment_keyword);
              props.sendValues("docType", val.document_type);
              if (
                val.document_date !== null &&
                val.document_date !== "Invalid Date"
              ) {
                props.sendValues("docDate", new Date(val.document_date));
              }
              props.sendValues("notes", val.notes);
              props.sendValues("taskDelete", false);
              props.sendValues("editDeadlineModal", true);
              props.sendValues("deadline_id", val.id);
              if (
                val.date_submitted !== "0000-00-00" &&
                val.date_submitted !== null &&
                val.date_submitted !== "Invalid Date"
              ) {
                props.sendValues("submitDate", new Date(val.date_submitted));
              }
            }
          });
      });

      $("#deadline tbody").on("click", ".btnDel", function () {
        var data = table.row(this).data();
        props.sendValues("deadline_id", this.name);
        props.sendValues("deleteModal", true);
      });

      $("#deadline tbody").on("click", ".btnTask", function () {
        var data = table.row(this).data();
        props.deadlineList &&
          props.deadlineList.map((val, idx) => {
            if (val.id == this.name) {
              props.clientdeadline.map((cli) => {
                if (cli.id == val.client_dedadline_task_id) {
                  props.sendValues("deadlineName", cli.Name);
                }
                props.sendValues("taskDeadlineDate", val.deadline_date);
                props.sendValues("taskFinancialYear", val.financial_year);
                props.sendValues("deadline_id", this.name);
                props.sendValues("newTask", true);
              });
            }
          });
      });

      $("#deadline tbody").on("click", ".btnbox", function (e) {
        var data = table.row(this).data();

        var allids = "";
        // let arr = [...props.deadlineList];
        props.deadlineList &&
          props.deadlineList.map((key) => {
            // if (key.id == e.target.title) {
            //   key.check = e.target.checked
            // }
            var checkid = "check" + key.id;
            if (document.getElementById(checkid) != null) {
              var checkstatus = document.getElementById(checkid).checked;
              key.check = checkstatus;
              if (checkstatus == true) {
                allids += allids + key.id + ",";
              }
            }
          });
        // arr[table.row(this).index()].check = e.target.checked;
        // props.sendValues("deadlineList", arr);
        if (allids == "") {
          props.sendValues("deadline_id", "");
        } else {
          allids = allids.slice(0, -1);
          props.sendValues("deadline_id", allids);
        }
        // if (e.target.name != "" && e.target.name != undefined && e.target.checked == true) {
        //   props.sendValues("deadline_id", e.target.name);
        // } else {
        //   props.sendValues("deadline_id", "");
        // }
      });

      // Add event listener for opening and closing details
      table.on("click", "td.details-control", function () {
        var tr = $(this).closest("tr");
        var row = table.row(tr);
        if (row.data()) {
          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass("shown");
            $("tr.shown td.details-control").toggleClass(
              "tr.shown td.details-control"
            );
          } else {
            // Open this row
            props.sendValues("deadline_id", row.data().id);
            row.child(format(row.data())).show();
            tr.addClass("shown");
          }
        }
      });
    });
  }, [props.deadlineList, props.personalList, props.clientdeadline]);

  return (
    <Fragment>
      <div id="client-deadline-tab" className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div className="collapse navbar-collapse" id="navigationbar">
                    <ul className="nav navbar-nav">
                      <ul className="nav navbar-nav">
                        <li>
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Deadlines
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                            {localStorage.getItem("permissions") &&
                            localStorage.getItem("permissions").includes(40) ? (
                              <li>
                                <a
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() =>
                                    props.sendValues("addDeadlineModal", true)
                                  }
                                >
                                  Add
                                </a>
                              </li>
                            ) : null}
                            {localStorage.getItem("permissions") &&
                            localStorage.getItem("permissions").includes(43) ? (
                              <li>
                                <a
                                  className="button-approval"
                                  // href="javascript:void()"
                                  data-title="Approve Selection"
                                  data-table="client-deadline"
                                  data-selection="Approve"
                                  onClick={() => {
                                    console.log("check");
                                    if (props.deadline_id !== "") {
                                      props.sendValues("approveModal", true);
                                    } else {
                                      props.setFieldValues(
                                        "nothing_select",
                                        true
                                      );
                                    }
                                  }}
                                >
                                  Approve
                                </a>
                              </li>
                            ) : null}
                          </ul>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="client-deadline_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                {/* <div className="row">
                                                        <div className="col-sm-6" />
                                                        <div className="col-sm-6">
                                                            <div id="client-deadline_filter" className="dataTables_filter">
                                                                <label>Search:<input type="search" className="form-control input-sm" placeholder aria-controls="client-deadline" /></label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      id="deadline"
                      className="table table-striped table-bordered col-md-12 dataTable no-footer"
                      role="grid"
                      style={{ marginLeft: 0, padding: 0 }}
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="details-control col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "52px" }}
                            aria-label="Details"
                          >
                            Details
                          </th>
                          <th
                            className="col-xs-1 col-sm-1 col-md-1 col-lg-1 select-checkbox sorting_asc"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "52px" }}
                            aria-label="Select"
                          >
                            Select
                          </th>
                          <th
                            className="col-xs-2 col-sm-2 col-md-2 col-lg-1 sorting"
                            tabIndex={0}
                            aria-controls="client-deadline"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "52px" }}
                            aria-label="Deadline: activate to sort column ascending"
                          >
                            Deadline
                          </th>
                          <th
                            className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                            tabIndex={0}
                            aria-controls="client-deadline"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "52px" }}
                            aria-label="Fin. Year: activate to sort column ascending"
                          >
                            Fin. Year
                          </th>
                          <th
                            className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                            tabIndex={0}
                            aria-controls="client-deadline"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "52px" }}
                            aria-label="D.Date: activate to sort column ascending"
                          >
                            D.Date
                          </th>
                          <th
                            className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                            tabIndex={0}
                            aria-controls="client-deadline"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "58px" }}
                            aria-label="Submitted: activate to sort column ascending"
                          >
                            Submitted
                          </th>
                          <th
                            className="col-xs-4 col-sm-4 col-md-4 col-lg-3 sorting"
                            tabIndex={0}
                            aria-controls="client-deadline"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "238px" }}
                            aria-label="Remarks: activate to sort column ascending"
                          >
                            Remarks
                          </th>
                          <th
                            className="col-xs-2 col-sm-2 col-md-2 col-lg-2 sorting"
                            tabIndex={0}
                            aria-controls="client-deadline"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "147px" }}
                            aria-label="Actions: activate to sort column ascending"
                          >
                            Actions
                          </th>
                        </tr>
                        <tbody></tbody>
                      </thead>
                    </table>

                    <div
                      id="client-deadline_processing"
                      className="dataTables_processing panel panel-default"
                      style={{ display: props.processModal }}
                    >
                      Processing...
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5" />
                  <div className="col-sm-7" />
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
      <AddDeadline />
      <EditDeadline />
      <Approve />
      <Nothing />
      <Delete />
      <NewTask />
      <EditTask />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { deadlineList, deadline_id, clientdeadline } = state.Deadline;
  return {
    deadlineList,
    deadline_id,
    clientdeadline,
    personalList: state.Personal.personalList,
    processModal: state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(deadline(k, v)),
    getDeadlineList: (data) => dispatch(getDeadlineList(data)),
    getDeadlineDrop: () => dispatch(getDeadlineDrop()),
    getPersonalList: (data) => dispatch(personalActions.getPersonalList(data)),
    setFieldValues: (f, v) => dispatch(personalActions.personal_data(f, v)),
    getReminderType: () => dispatch(getReminderType()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deadline);
