import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";

function EditDocumentDate(props) {
  console.log("props: ", props);

  const [updatedDate, setUpdatedDate] = useState(props.created_on);
  console.log(
    "updatedDate:  |||||||||||||||||||||||||||||||||||| ",
    updatedDate
  );
  const [conDate, setConDate] = useState(localStorage.getItem("convened_"));
  const [status, setStatus] = useState(localStorage.getItem("status_"));




  useEffect(() => {
    setStatus(localStorage.getItem("status_"));


  });

  

  const handleEditClick = () => {
    let dates = {};
  
    console.log("status: ", status);
  
    if (status === "1") {
      dates = {
        updatedDate,
        status,
      };
    } else if (status === "2") {
      dates = {
        conDate: formatDate(conDate),
        status,
      };
    } else if (status === "3") {
      dates = {
        updatedDate,
        conDate: formatDate(conDate),
        status,
      };
    }
  
    console.log("dates: ", dates);
  
    props.editResDate(props.document_id, dates);
  };
  
  // Function to format date as DD-MM-YYYY with hyphens
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensures two-digit day
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensures two-digit month
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  

  const handleClose = () => {
    console.log("handleClose:");
    props.sendValues("editDocumentModalDate", false);
    props.sendValues("created_on", "");
  };

  return (
    <Modal
      key={props.editDocumentModalDate ? "modal-open" : "modal-closed"}
      onHide={handleClose}
      show={props.editDocumentModalDate}
      className="modal bootstrap-dialog type-primary fade size-normal in"
      role="dialog"
      aria-hidden="true"
      id="Confirmpersonal"
      aria-labelledby="Confirmpersonal"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="bootstrap-dialog-header">
              <div className="bootstrap-dialog-title">Confirm Edit</div>
            </div>
          </div>

          <div className="modal-body">
            {props.clientApiStatus ? (
              <div
                className={`alert ${
                  props.clientApiStatus === 1 ? "" : "alert-danger"
                }`}
                role="alert"
              >
                {props.clientApiStatus === 1 ? "" : props.message}
              </div>
            ) : (
              ""
            )}

            {status === "1" && (
              <div
              className="bootstrap-dialog-body"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                justifyContent: "space-between" ,
                borderT:"1px solid gray "
              }}
            >
              <div
                className="bootstrap-dialog-message"
                style={{ marginRight: "10px", width: "150px" }}
              >
                Resolution Date:
              </div>


              <div>
                <p 
                 style={{ fontWeight:700 }}
                >Old Date</p>
                <p>{formatDate(localStorage.getItem("created_"))}</p>
                
                </div>


<div>
<p   style={{ fontWeight:700 }} >New Date</p>
<input
                type="date"
                className="form-control"
                id="dateInput"
                style={{ width: "150px" }}
                value={updatedDate}
                onChange={(e) => setUpdatedDate(e.target.value)}
              />

</div>
             
            </div>
            )}

            {status === "2" && (
               <div
               className="bootstrap-dialog-body"
               style={{
                 display: "flex",
                 alignItems: "center",
                 marginTop: "10px",
                 justifyContent: "space-between" ,
                 borderT:"1px solid gray "
               }}
             >
               <div
                 className="bootstrap-dialog-message"
                 style={{ marginRight: "10px", width: "150px" }}
               >
                 Convened Date:
               </div>


               <div>
                 <p  style={{ fontWeight:700 }} >Old Date</p>
                 <p>{localStorage.getItem("convened_")}</p>
                 
                 </div>


<div>
<p  style={{ fontWeight:700 }}  >New Date</p>
<input
                 type="date"
                 className="form-control"
                 id="dateInput"
                 style={{ width: "150px" }}
                 value={conDate}
                 onChange={(e) => setConDate(e.target.value)}
               />

</div>
              
             </div>
            )}

            {status === "3" && (
              <>




                <div
                  className="bootstrap-dialog-body"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    justifyContent: "space-between" ,
                    borderT:"1px solid gray "
                  }}
                >
                  <div
                    className="bootstrap-dialog-message"
                    style={{ marginRight: "10px", width: "150px" }}
                  >
                    Resolution Date:
                  </div>


                  <div>
                    <p 
                     style={{ fontWeight:700 }}
                    >Old Date</p>
                        <p>{localStorage.getItem("convened_")}</p>
                    
                    </div>


<div>
<p   style={{ fontWeight:700 }} >New Date</p>
<input
                    type="date"
                    className="form-control"
                    id="dateInput"
                    style={{ width: "150px" }}
                    value={updatedDate}
                    onChange={(e) => setUpdatedDate(e.target.value)}
                  />

</div>
                 
                </div>








                <div
                  className="bootstrap-dialog-body"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    justifyContent: "space-between" ,
                    borderT:"1px solid gray "
                  }}
                >
                  <div
                    className="bootstrap-dialog-message"
                    style={{ marginRight: "10px", width: "150px" }}
                  >
                    Convened Date:
                  </div>


                  <div>
                    <p  style={{ fontWeight:700 }} >Old Date</p>
                    <p>{localStorage.getItem("convened_")}</p>
                    
                    </div>


<div>
<p  style={{ fontWeight:700 }}  >New Date</p>
<input
                    type="date"
                    className="form-control"
                    id="dateInput"
                    style={{ width: "150px" }}
                    value={conDate}
                    onChange={(e) => setConDate(e.target.value)}
                  />

</div>
                 
                </div>
              </>
            )}
          </div>

          <div className="modal-footer">
            <button
              className="btn form-control btn-danger"
              onClick={handleEditClick}
            >
              <span className="glyphicon glyphicon-check" /> Update
            </button>
            <button className="btn form-control" onClick={handleClose}>
              <span className="glyphicon glyphicon-remove" /> Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  editDocumentModalDate: state.Document.editDocumentModalDate,
  document_id: state.Document.document_id,
  clientApiStatus: state.Client.clientApiStatus,
  message: state.Client.message,
  created_on: state.Document.created_on,
});

const mapDispatchToProps = (dispatch) => ({
  sendValues: (f, v) => dispatch(actions.documentFunction(f, v)),
  editResDate: (id, date) => dispatch(actions.editDate(id, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentDate);
