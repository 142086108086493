import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/ShareRegister";
import * as settings from "../../../actions/Settings";
import $ from "jquery";
import DatePicker from "react-datepicker";
import moment from "moment";

function EditShare(props) {
  console.log("EditShare: =============================== ", props);

  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });
    const { getDocumentClassification, getSettingsShareTypeList, sendValues } =
      props;
    // getShareTypeList();
    getDocumentClassification();
    getSettingsShareTypeList();
    sendValues("documentLink", "");
  }, []);

  useEffect(() => {
    // console.log("chinnu use", props)
    if (props.editAction === "transfer") {
      console.log('props.editAction === "transfer": ');
      // console.log("chinnu use if", props.transferShareHolder, props.transferShareType, props.transferValueShare);
      let sType = props.ShareTypeList.filter(
        (k) => props.transferShareType == k.Name
      );
      if (sType.length > 0) {
        props.getValuePerShareList(props.transferShareHolder, sType[0]["id"]);
        props.getShareLot({
          client_id: localStorage.getItem("personalId"),
          value_per_share: props.transferValueShare,
          share_holder: props.transferShareHolder,
          share_type: sType[0]["id"],
        });
      }
    } else if (props.editAction === "convert") {
      console.log('props.editAction === "convert": ');
      let sType = props.ShareTypeList.filter(
        (k) => props.convertShareType == k.Name
      );
      if (sType.length > 0) {
        props.getValuePerShareList(props.convertShareHolder, sType[0]["id"]);
        props.getShareLot({
          client_id: localStorage.getItem("personalId"),
          value_per_share: props.convertValueShare,
          share_holder: props.convertShareHolder,
          share_type: sType[0]["id"],
        });
      }
    } else if (props.editAction === "reduce") {
      console.log('props.editAction === "reduce": ');
      let sType = props.ShareTypeList.filter(
        (k) => props.reduceShareType == k.Name
      );
      if (sType.length > 0) {
        props.getValuePerShareList(props.reduceShareHolder, sType[0]["id"]);
        props.getShareLot({
          client_id: localStorage.getItem("personalId"),
          value_per_share: props.reduceValueShare,
          share_holder: props.reduceShareHolder,
          share_type: sType[0]["id"],
        });
      }
    }
  }, [
    props.transferNoOfShare,
    props.convertNoShares,
    props.reduceShareReduction,
  ]);

  const addShareFunc = (e) => {
    var userData = JSON.parse(localStorage.getItem("userData"));
    e.preventDefault();
    let document_date;
    if (props.documentDate != "") {
      document_date = moment(props.documentDate).format("YYYY-MM-DD");
    }
    let tran_share = [];
    if (props.editAction === "transfer") {
      let total = props.shareLots[0].initial_value;
      console.log("total: ", total);

      let total_noOfSharesHeld = props.noOfSharesHeld;
      console.log("total_noOfSharesHeld: ", total_noOfSharesHeld);

      tran_share = props.toShareHolderList.filter((item) => {
        return item.name == props.transferToShareHolder;
      });

      console.log("tran_share: ", tran_share);
    }

    console.log("props.ShareTypeList: ", props.ShareTypeList);
    console.log("props.reduceShareType: ", props.reduceShareType);

    const oldShareholder = localStorage.getItem("oldShareHolder");

    let input = {
      company_id: userData.company_id,
      id: props.shareId,
      client_id: localStorage.getItem("personalId"),
      resolution_date: moment(props.resolutionDate).format("YYYY-MM-DD"),
      acrafileddate: moment(props.acrafileddate).format("YYYY-MM-DD"),
      share_holder: props.shareHolder,
      old_shareholder: oldShareholder,
      share_type: props.shareType,
      value_per_share: props.valuePerShare,
      no_of_shares_held: props.editAction === "add" ? props.noOfSharesHeld : 0,
      remarks: props.remark,
      document_link: props.documentLink,
      document_id: props.documentId,
      attachment: props.attachMent,
      attachment_keyword: props.attachmentKeyword,
      document_type: props.documentType,
      document_date: document_date,
      notes: props.notes,
      active_tab: props.pageSelected,
      share_register_id: props.shareRegisterId,
      transferToShareHolder: tran_share.length > 0 ? tran_share[0].id : "",
      transferNoOfShare:
        props.editAction === "transfer" ? props.transferNoOfShare : 0,
      convertShareHolder: props.convertShareHolder,
      convertShareType: props.convertShareType,
      convertToShare: props.convertToShare,
      convertValueShare: props.convertValueShare,
      convertNoShares:
        props.editAction === "convert" ? props.convertNoShares : 0,
      reduceShareHolder: props.reduceShareHolder,
      reduceShareType: props.reduceShareType,
      reduceValueShare: props.reduceValueShare,
      reduceShareReduction:
        props.editAction === "reduce" ? props.reduceShareReduction : 0,
      splitShareType: props.splitShareType,
      splitValues: props.splitValues,
      splitTotalShare: props.splitTotalShare,
      splitTotalValue: props.splitTotalValue,
      editAction: props.editAction,
      lotId: props.shareLots.length ? props.shareLots[0]["id"] : null,
    };

    console.log("input: ", input);

    props
      .editShareRegister(input)
      .then((res) => {
        console.log("res:", res);
        if (res && res.status === 1) {
          resetFormFields();
        }
      })
      .catch((err) => console.error("Error:", err));
  };

  const handleClose = () => {
    console.log("handleClose: ");
    console.log("Modal editShares prop:", props.editShares);
    props.sendValues("editShares", false);
    props.sendValues("documentLink", "");

    resetFormFields();
  };

  const resetFormFields = () => {
    console.log("resetFormFields: ------>  ");

    props.sendValues("resolution_date", null);

    props.sendValues("acrafileddate", null);

    props.sendValues("documentLink", "");
    props.sendValues("shareHolder", "");
    props.sendValues("shareType", "");
    props.sendValues("valuePerShare", "");
    props.sendValues("noOfSharesHeld", "");
    props.sendValues("remark", "");
    props.sendValues("documentId", "");
    props.sendValues("attachMent", "");
    props.sendValues("attachmentKeyword", "");
    props.sendValues("documentType", "");
    props.sendValues("notes", "");
    props.sendValues("transferToShareHolder", "");
    props.sendValues("transferNoOfShare", "");
    props.sendValues("convertShareHolder", "");
    props.sendValues("convertShareType", "");
    props.sendValues("convertToShare", "");
    props.sendValues("convertValueShare", "");
    props.sendValues("convertNoShares", "");
    props.sendValues("reduceShareHolder", "");
    props.sendValues("reduceShareType", "");
    props.sendValues("reduceValueShare", "");
    props.sendValues("reduceShareReduction", "");
    props.sendValues("splitShareType", "");
    props.sendValues("splitValues", "");
    props.sendValues("splitTotalShare", "");
    props.sendValues("splitTotalValue", "");
  };

  return (
    <Modal
      key={props.editShares ? "modal-open" : "modal-closed"}
      show={props.editShares}
      onHide={handleClose}
      // onHide={() => props.sendValues("addShares", false)}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="CreateRegister"
      aria-labelledby="CreateRegister"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        {/* {console.log("chinnu props edit", props)} */}
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="e82ddc45-1abc-46f5-9283-744faa1591ab_title"
              >
                Edit
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <form
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    onSubmit={(e) => addShareFunc(e)}
                    id="form"
                    className="form-horizontal"
                  >
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                    />
                    <div className="form-group">
                      <label
                        htmlFor="recorddate"
                        className="control-label col-sm-4 required"
                      >
                        Resolution Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.resolutionDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },

                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("resolutionDate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("resolutionDate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="recorddate"
                        className="control-label col-sm-4 required"
                      >
                        Acra Filed Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.acrafileddate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("acrafileddate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("acrafileddate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>

                    {props.editAction === "add" ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="clientemployee_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Holder
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("shareHolder", e.target.value)
                              }
                              required="required"
                              id="clientemployee_id"
                              name="clientemployee_id"
                              value={props.shareHolder}
                            >
                              <option value="" selected="selected">
                                === Select Shareholder ===
                              </option>
                              {props.shareHolderList &&
                                props.shareHolderList.map((val) => {
                                  return (
                                    <option value={val.id}>{val.name}</option>
                                  );
                                })}
                            </select>
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="sharetype_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("shareType", e.target.value)
                              }
                              style={{ backgroundColor: "#fff" }}
                              required="required"
                              id="sharetype_id"
                              name="sharetype_id"
                              value={props.shareType}
                            >
                              <option value="" selected="selected">
                                === Please Select ===
                              </option>
                              {props.List.map((shar) => {
                                return (
                                  <option
                                    value={shar.id}
                                    selected={`${
                                      shar.id == props.shareType
                                        ? "selected"
                                        : ""
                                    }`}
                                  >
                                    {shar.Name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="valuepershare"
                            className="control-label col-sm-4 required"
                          >
                            Value Per Share
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues(
                                  "valuePerShare",
                                  e.target.value
                                )
                              }
                              required="required"
                              value={props.valuePerShare}
                              name="valuepershare"
                              type="number"
                              id="valuepershare"
                            />
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="noofsharesheld"
                            className="control-label col-sm-4 required"
                          >
                            No of Shares Held
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues(
                                  "noOfSharesHeld",
                                  e.target.value
                                )
                              }
                              step={1}
                              required="required"
                              min={1}
                              name="noofsharesheld"
                              type="number"
                              id="noofsharesheld"
                              value={props.noOfSharesHeld}
                            />
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                      </>
                    ) : props.editAction === "transfer" ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="clientemployee_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Holder
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "transferShareHolder",
                                  e.target.value
                                );
                                props.getShareTypeList(e.target.value);
                                // props.getValuePerShareList(e.target.value, '')
                                let cbFunc = (ele) => ele.id == e.target.value;
                                let arr = [...props.shareHolderList];
                                let index = arr.findIndex(cbFunc);
                                arr.splice(index, 1);
                                props.sendValues("toShareHolderList", arr);
                              }}
                              required="required"
                              id="clientemployee_id"
                              name="clientemployee_id"
                            >
                              <option value="" selected="selected">
                                === Select Shareholder ===
                              </option>
                              {props.shareHolderList &&
                                props.shareHolderList.map((val) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={
                                        props.transferShareHolder == val.id
                                          ? true
                                          : false
                                      }
                                    >
                                      {val.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                        {/* {console.log("chinnu transfer", props)} */}
                        <div className="form-group">
                          <label
                            htmlFor="toclientemployee_id"
                            className="control-label col-sm-4 required"
                          >
                            To Share Holder
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "transferToShareHolder",
                                  e.target.value
                                );
                              }}
                              required="required"
                              id="toclientemployee_id"
                              name="toclientemployee_id"
                            >
                              <option value="">
                                === Select Shareholder ===
                              </option>
                              {props.toShareHolderList &&
                                props.toShareHolderList.map((val) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={
                                        props.transferToShareHolder == val.name
                                          ? true
                                          : false
                                      }
                                    >
                                      {val.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="sharetype_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "transferShareType",
                                  e.target.value
                                );
                                props.getValuePerShareList(
                                  props.transferShareHolder,
                                  e.target.value
                                );
                              }}
                              style={{ backgroundColor: "#fff" }}
                              required="required"
                              id="sharetype_id"
                              name="sharetype_id"
                            >
                              <option value="">=== Please Select ===</option>
                              {props.ShareTypeList.map((shar) => {
                                return (
                                  <option
                                    value={shar.id}
                                    selected={
                                      props.transferShareType == shar.Name
                                        ? true
                                        : false
                                    }
                                  >
                                    {shar.Name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="valuepershare"
                            className="control-label col-sm-4 required"
                          >
                            Value Per Share
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "transferValueShare",
                                  e.target.value
                                );
                                // console.log("chinnu use initials called")
                                props.getShareLot({
                                  client_id: localStorage.getItem("personalId"),
                                  value_per_share: e.target.value,
                                  share_holder: props.transferShareHolder,
                                  share_type: props.transferShareType,
                                });
                              }}
                              required="required"
                              id="valuepershare"
                              name="valuepershare"
                            >
                              <option value="">=== Please Select ===</option>
                              {props.valuePerShareList &&
                                props.valuePerShareList.map((val, idx) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={
                                        props.valuePerShare == val.value
                                          ? true
                                          : false
                                      }
                                    >
                                      {val.value}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="lot"
                            className="control-label col-sm-4 required"
                          >
                            Select lot
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues("lotId", e.target.value);
                              }}
                              required="required"
                              id="lot"
                              name="valuepershare"
                            >
                              <option value="" selected="selected">
                                === Please Select ===
                              </option>
                              {props.shareLots &&
                                props.shareLots.map((val, idx) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={idx == 0 ? true : false}
                                    >
                                      Share held : {val.no_of_shares_held}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="noofsharesheld"
                            className="control-label col-sm-4 required"
                          >
                            No of Shares to Transfer
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              step={1}
                              onChange={(e) => {
                                props.sendValues(
                                  "transferNoOfShare",
                                  e.target.value
                                );
                              }}
                              required="required"
                              min={1}
                              name="noofsharesheld"
                              type="number"
                              id="noofsharesheld"
                              defaultValue={props.transferNoOfShare}
                            />
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                      </>
                    ) : props.editAction === "convert" ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="clientemployee_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Holder
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "convertShareHolder",
                                  e.target.value
                                );
                              }}
                              required="required"
                              id="clientemployee_id"
                              name="clientemployee_id"
                            >
                              <option value="">
                                === Select Shareholder ===
                              </option>
                              {props.shareHolderList &&
                                props.shareHolderList.map((val) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={
                                        props.convertShareHolder == val.id
                                          ? true
                                          : false
                                      }
                                    >
                                      {val.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="sharetype_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "convertShareType",
                                  e.target.value
                                );
                                props.getValuePerShareList(
                                  props.convertShareHolder,
                                  e.target.value
                                );
                                // let cbFunc = (ele) => ele.id == e.target.value
                                let arr = [...props.toShareTypeListMain];
                                let toList = arr.filter(
                                  (o) => o.id != e.target.value
                                );
                                // let index = arr.findIndex(cbFunc)
                                // arr.splice(index, 1)
                                props.sendValues("toShareTypeList", toList);
                              }}
                              style={{ backgroundColor: "#fff" }}
                              required="required"
                              id="sharetype_id"
                              name="sharetype_id"
                            >
                              <option value="">=== Please Select ===</option>
                              {props.ShareTypeList.map((shar) => {
                                return (
                                  <option
                                    value={shar.id}
                                    selected={
                                      props.convertShareType == shar.Name
                                        ? true
                                        : false
                                    }
                                  >
                                    {shar.Name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block">* Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="tosharetype_id"
                            className="control-label col-sm-4 required"
                          >
                            To Share Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "convertToShare",
                                  e.target.value
                                );
                              }}
                              required="required"
                              id="tosharetype_id"
                              name="tosharetype_id"
                            >
                              <option value="">
                                === Select Shareholder ===
                              </option>
                              {props.toShareTypeList.map((shar) => {
                                return (
                                  <option
                                    value={shar.id}
                                    selected={
                                      props.convertToShare == shar.Name
                                        ? true
                                        : false
                                    }
                                  >
                                    {shar.Name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="valuepershare"
                            className="control-label col-sm-4 required"
                          >
                            Value Per Share
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "convertValueShare",
                                  e.target.value
                                );
                                props.getShareLot({
                                  client_id: localStorage.getItem("personalId"),
                                  value_per_share: e.target.value,
                                  share_holder: props.convertShareHolder,
                                  share_type: props.convertShareType,
                                });
                              }}
                              required="required"
                              id="valuepershare"
                              name="valuepershare"
                            >
                              <option value="">=== Please Select ===</option>
                              {props.valuePerShareList &&
                                props.valuePerShareList.map((val, idx) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={
                                        props.valuePerShare == val.value
                                          ? true
                                          : false
                                      }
                                    >
                                      {val.value}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="lot"
                            className="control-label col-sm-4 required"
                          >
                            Select lot
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues("lotId", e.target.value);
                              }}
                              required="required"
                              id="lot"
                              name="valuepershare"
                            >
                              <option value="" selected="selected">
                                === Please Select ===
                              </option>
                              {props.shareLots &&
                                props.shareLots.map((val, idx) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={idx == 0 ? true : false}
                                    >
                                      Share held : {val.no_of_shares_held}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="noofsharesheld"
                            className="control-label col-sm-4 required"
                          >
                            No of Shares to Convert
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "convertNoShares",
                                  e.target.value
                                );
                              }}
                              step={1}
                              required="required"
                              min={1}
                              name="noofsharesheld"
                              type="number"
                              id="noofsharesheld"
                              defaultValue={props.convertNoShares}
                            />
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                      </>
                    ) : props.editAction === "reduce" ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="clientemployee_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Holder
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "reduceShareHolder",
                                  e.target.value
                                );
                              }}
                              required="required"
                              id="clientemployee_id"
                              name="clientemployee_id"
                            >
                              <option value>=== Select Shareholder ===</option>
                              {props.shareHolderList &&
                                props.shareHolderList.map((val) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={
                                        props.reduceShareHolder == val.id
                                          ? true
                                          : false
                                      }
                                    >
                                      {val.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="sharetype_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "reduceShareType",
                                  e.target.value
                                );
                                props.getValuePerShareList(
                                  props.reduceShareHolder,
                                  e.target.value
                                );
                              }}
                              style={{ backgroundColor: "#fff" }}
                              required="required"
                              id="sharetype_id"
                              name="sharetype_id"
                            >
                              <option value>=== Please Select ===</option>
                              {props.ShareTypeList.map((shar) => {
                                return (
                                  <option
                                    value={shar.id}
                                    selected={
                                      props.reduceShareType == shar.Name
                                        ? true
                                        : false
                                    }
                                  >
                                    {shar.Name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="valuepershare"
                            className="control-label col-sm-4 required"
                          >
                            Value Per Share
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "reduceValueShare",
                                  e.target.value
                                );
                                props.getShareLot({
                                  client_id: localStorage.getItem("personalId"),
                                  value_per_share: e.target.value,
                                  share_holder: props.reduceShareHolder,
                                  share_type: props.reduceShareType,
                                });
                              }}
                              required="required"
                              id="valuepershare"
                              name="valuepershare"
                            >
                              <option value="">=== Please Select ===</option>
                              {props.valuePerShareList &&
                                props.valuePerShareList.map((val, idx) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={
                                        props.valuePerShare == val.value
                                          ? true
                                          : false
                                      }
                                    >
                                      {val.value}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="lot"
                            className="control-label col-sm-4 required"
                          >
                            Select lot
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues("lotId", e.target.value);
                              }}
                              required="required"
                              id="lot"
                              name="valuepershare"
                            >
                              <option value="">=== Please Select ===</option>
                              {props.shareLots &&
                                props.shareLots.map((val, idx) => {
                                  return (
                                    <option
                                      value={val.id}
                                      selected={idx == 0 ? true : false}
                                    >
                                      Share held : {val.no_of_shares_held}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="noofsharesheld"
                            className="control-label col-sm-4 required"
                          >
                            No of Shares After Reduction
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                props.sendValues(
                                  "reduceShareReduction",
                                  e.target.value
                                );
                              }}
                              step={1}
                              required="required"
                              min={1}
                              name="noofsharesheld"
                              type="number"
                              id="noofsharesheld"
                              defaultValue={props.reduceShareReduction}
                            />
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                      </>
                    ) : props.editAction === "split" ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="sharetype_id"
                            className="control-label col-sm-4 required"
                          >
                            Share Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                let obj = {
                                  share_type_id: e.target.value,
                                  resolution_date: moment(
                                    props.resolutionDate
                                  ).format("YYYY-MM-DD"),
                                  action: "edit",
                                };
                                // console.log("chinnu obj", obj)
                                props.splitShareBasedApi(obj);
                                props.sendValues(
                                  "splitShareType",
                                  e.target.value
                                );
                              }}
                              style={{ backgroundColor: "#fff" }}
                              required="required"
                              id="sharetype_id"
                              name="sharetype_id"
                            >
                              <option value="">=== Please Select ===</option>
                              {props.ShareTypeList.map((shar) => {
                                return (
                                  <option
                                    value={shar.id}
                                    selected={
                                      props.splitShareType == shar.Name
                                        ? true
                                        : false
                                    }
                                  >
                                    {shar.Name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="valuepershare"
                            className="control-label col-sm-4 required"
                          >
                            Split Value
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              value={props.splitValues}
                              onChange={(e) => {
                                props.sendValues("splitValues", e.target.value);
                                if (
                                  e.target.value != "" &&
                                  e.target.value != undefined &&
                                  e.target.value != null
                                ) {
                                  let setval = props.dummy / e.target.value;
                                  props.sendValues(
                                    "splitTotalShare",
                                    setval != "Infinity" ? setval : 0
                                  );
                                } else {
                                  props.sendValues(
                                    "splitTotalShare",
                                    props.dummy
                                  );
                                }
                              }}
                              required="required"
                              name="valuepershare"
                              type="number"
                              id="valuepershare"
                            />
                            <p className="help-block"> * Required </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="shareaftersplit"
                            className="control-label col-sm-4"
                          >
                            Total Share After Split
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              disabled="disabled"
                              value={props.splitTotalShare}
                              onChange={(e) => {
                                props.sendValues(
                                  "splitTotalShare",
                                  e.target.value
                                );
                              }}
                              name="shareaftersplit"
                              type="text"
                              id="shareaftersplit"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="total_value"
                            className="control-label col-sm-4"
                          >
                            Total Value
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              disabled="disabled"
                              value={props.splitTotalValue}
                              onChange={(e) => {
                                props.sendValues(
                                  "splitTotalValue",
                                  e.target.value
                                );
                              }}
                              name="total_value"
                              type="text"
                              id="total_value"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="form-group">
                      <label
                        htmlFor="remarks"
                        className="control-label col-sm-4"
                      >
                        Remarks
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          value={props.remark}
                          onChange={(e) =>
                            props.sendValues("remark", e.target.value)
                          }
                          rows={3}
                          name="remarks"
                          cols={50}
                          id="remarks"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="document_link"
                        className="control-label col-sm-4"
                      >
                        Document Link
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("documentLink", e.target.value)
                          }
                          id="document_link"
                          name="document_link"
                          value={props.documentLink}
                        >
                          <option value="">
                            View from the drop-down list / Select New Doc to
                            upload new
                          </option>
                          <option value={0}>New Document</option>
                          {props.DocumentTypeList.map((val) => {
                            return <option value={val.id}>{val.Name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    {props.documentLink === "0" ? (
                      <div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_id"
                            className="control-label col-sm-4"
                          >
                            Document ID
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("documentId", e.target.value)
                              }
                              name="document_id"
                              type="text"
                              id="document_id"
                              value={props.documentId}
                            />
                            <p className="help-block">
                              Must be unique ID with this client{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="original_filename"
                            className="control-label col-sm-4"
                          >
                            Attachment
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                // console.log("file", e.target.files[0]);
                                props.sendValues(
                                  "attachMent",
                                  e.target.files[0]
                                );
                              }}
                              maxLength={4096}
                              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                              name="original_filename"
                              type="file"
                              id="original_filename"
                            />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="keywords"
                            className="control-label col-sm-4"
                          >
                            Attachment Keyword for Searching Purposes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues(
                                  "attachmentKeyword",
                                  e.target.value
                                )
                              }
                              rows={3}
                              name="keywords"
                              cols={50}
                              value={props.attachmentKeyword}
                              id="keywords"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_type_id"
                            className="control-label col-sm-4"
                          >
                            Document Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("documentType", e.target.value)
                              }
                              value={props.documentType}
                              id="document_type_id"
                              name="document_type_id"
                            >
                              <option value="" selected="selected">
                                === Select ===
                              </option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_date"
                            className="control-label col-sm-4"
                          >
                            Resolution/Document Date
                          </label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.documentDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                props.sendValues("documentDate", Date);
                              }}
                            >
                              <div
                                class="trash-icon"
                                onClick={() =>
                                  props.sendValues("documentDate", "")
                                }
                              >
                                <span class="glyphicon glyphicon-trash"></span>{" "}
                              </div>
                            </DatePicker>
                            <p className="help-block">DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Notes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("notes", e.target.value)
                              }
                              value={props.notes}
                              rows={3}
                              name="document_tag"
                              cols={50}
                              id="document_tag"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="form-group">
                      <div
                        className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        id="errortext"
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="operationtype"
                        type="hidden"
                        defaultValue="A"
                      />
                    </div>
                    <div className="col-sm-12">
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${
                            props.clientApiStatus === 1 ? "" : "alert-danger"
                          }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      value="A"
                      name="action"
                      id="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />{" "}
                      Update Share
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control"
                  id="e3236b70-d42d-4c22-809e-7af2cf00fbce"
                  onClick={handleClose}
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    editShares,
    resolutionDate,
    acrafileddate,
    shareHolder,
    shareType,
    valuePerShare,
    noOfSharesHeld,
    remark,
    documentLink,
    documentId,
    attachMent,
    attachmentKeyword,
    documentType,
    documentDate,
    notes,
    ShareTypeList,
    DocumentTypeList,
    shareHolderList,
    shareId,
    shareRegisterId,
    editAction,
    toShareHolderList,
    valuePerShareList,
    transferShareHolder,
    transferShareType,
    shareLots,
    lotId,
    transferToShareHolder,
    transferNoOfShare,
    transferValueShare,
    convertShareHolder,
    convertShareType,
    convertToShare,
    convertValueShare,
    convertNoShares,
    toShareTypeListMain,
    toShareTypeList,
    reduceShareHolder,
    reduceShareType,
    reduceValueShare,
    reduceShareReduction,
    splitShareType,
    splitValues,
    splitTotalShare,
    splitTotalValue,
    dummy,
  } = state.ShareReg;
  return {
    editShares,
    resolutionDate,
    acrafileddate,
    shareHolder,
    shareType,
    valuePerShare,
    noOfSharesHeld,
    remark,
    documentLink,
    documentId,
    attachMent,
    attachmentKeyword,
    documentType,
    documentDate,
    notes,
    ShareTypeList,
    DocumentTypeList,
    shareHolderList,
    shareId,
    shareRegisterId,
    editAction,
    toShareHolderList,
    valuePerShareList,
    transferShareHolder,
    transferShareType,
    transferValueShare,
    shareLots,
    lotId,
    transferToShareHolder,
    transferNoOfShare,
    convertShareHolder,
    convertShareType,
    convertToShare,
    convertValueShare,
    convertNoShares,
    toShareTypeListMain,
    toShareTypeList,
    reduceShareHolder,
    reduceShareType,
    reduceValueShare,
    reduceShareReduction,
    splitShareType,
    splitValues,
    splitTotalShare,
    splitTotalValue,
    dummy,
    personalList: state.Personal.personalList,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    List: state.Settings.List,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.share_register(k, v)),
    editShareRegister: (input) => dispatch(actions.editShareRegister(input)),
    getShareTypeList: () => dispatch(actions.getShareTypeList()),
    getDocumentClassification: () =>
      dispatch(actions.getDocumentClassification()),
    getSettingsShareTypeList: () =>
      dispatch(settings.getSettingsShareTypeList()),
    getDocumentClassification: () =>
      dispatch(actions.getDocumentClassification()),
    getValuePerShareList: (data, type) =>
      dispatch(actions.getValuePerShareList(data, type)),
    getShareLot: (obj) => dispatch(actions.getShareLot(obj)),
    splitShareBasedApi: (val) => dispatch(actions.splitShareBasedApi(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditShare);
