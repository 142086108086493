import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/UserManagement";
import $ from "jquery";
import moment from "moment";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function Permission(props) {
  var table;

  useEffect(() => {
    const { getRolesList, getUserPermissionList, getPermissionList } = props;
    getRolesList();
    getUserPermissionList();
    getPermissionList();

    if (
      localStorage.getItem("permissions") &&
      localStorage.getItem("permissions").includes(68)
    ) {
      setUpdatePermission(false);
    }
  }, []);
  const [updatePermission, setUpdatePermission] = useState(true);

  const updateSelection = (key) => (e) => {
    const newRole = { ...selectedRole };
    newRole[key] = e.target.checked;
    setSelectedRole(newRole);
  };

  const addParamsValues = (e) => {
    e.preventDefault();
    // key: 1_1 1_2
    let obj = {};
    obj.permissions_list = [];
    props.usersLists.map((sts, idx) => {
      const role_name = sts.id; // 1
      const arr = [];
      const permission = props.permissionList.map(
        (permission) => permission.id
      );
      permission.map((permissionId) => {
        // const arr=[];
        const key = role_name + "_" + permissionId;
        if (selectedRole[key]) {
          arr.push(permissionId);
        }
      });
      let result = {
        role_id: role_name,
        permissions: arr,
      };
      obj.permissions_list.push(result);
    });
    console.log("rig", obj);
    props.addPermission(obj);
  };

  useEffect(() => {
    console.log(props.permissionList);
    let obj = {};
    props.permissionList.map((val, idx) => {
      props.usersLists.map((tab, idx) => {
        let permissions = [];
        for (let i = 0; i < props.userPermission.length; i++) {
          if (props.userPermission[i].role_name === tab.name) {
            permissions = props.userPermission[i].permissions; // [2, 3]
            break;
          }
        }
        let checked = permissions.includes(val.id);
        const key = `${tab.id}_${val.id}`;
        obj[key] = checked || false;
      });
    });
    setSelectedRole(obj);
    console.log(obj);
  }, [props.usersLists, props.permissionList, props.userPermission]);

  const [selectedRole, setSelectedRole] = useState({});

  console.log("props", props.permissionList, props.usersLists);

  return (
    <Fragment>
      <div className="tab-pane fade in active">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div className="collapse navbar-collapse" id="navigationbar">
                    <ul className="nav navbar-nav" />
                  </div>
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div className="box-body scrollable">
                <form
                  method="post"
                  id="rolespermissionform"
                  action="/admin/rolespermission"
                >
                  <table
                    className="table table-bordered table-striped scrollable"
                    id="permission"
                  >
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                    />
                    <thead>
                      <tr>
                        <th width={100} />
                        {props.usersLists.map((tab, idx) => {
                          return (
                            <th className="rotate-45" width={100}>
                              <strong>{tab.name}</strong>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {props.permissionList.map((val) => {
                        return (
                          <tr>
                            <td>{val.name}</td>
                            {props.usersLists.map((tab, idx) => {
                              // props.permissionStatus.map((sts, ind) => {
                              // if (tab.id === sts.role_id) {
                              // JSON.parse(sts.permissions).map((per) => {
                              //   if (per === idx+1) {
                              // // name + user id
                              // let permissions = [];
                              // for (let i =0; i<props.permissionStatus.length; i++) {
                              //     if (props.permissionStatus[i].role_name === tab.name) {
                              //       permissions = props.permissionStatus[i].permissions; // [2, 3]
                              //       break;
                              //     }
                              // }
                              // let checked = permissions.includes(val.id);
                              const key = `${tab.id}_${val.id}`;

                              return (
                                <td>
                                  <input
                                    type="checkbox"
                                    disabled={updatePermission}
                                    checked={selectedRole[key]}
                                    onChange={updateSelection(key)}
                                  />
                                </td>
                              );
                              //   }
                              // })
                              // }
                              // })
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                  <button
                    disabled={updatePermission}
                    className="buttons"
                    onClick={addParamsValues}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { usersLists, staticColumns, permissionList, userPermission } =
    state.UserDatas;
  return {
    usersLists,
    staticColumns,
    permissionList,
    userPermission,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),
    getRolesList: () => dispatch(actions.getRolesList()),
    getUserPermissionList: () => dispatch(actions.getUserPermissionList()),
    getPermissionList: () => dispatch(actions.getPermissionList()),
    addPermission: (data) => dispatch(actions.addPermission(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Permission);
