import React, { Fragment, useEffect, useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import { connect } from "react-redux";
import * as actions from "../../actions/clientDetails/Personal";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import AddPersonal from "./modals/AddNewPersonal";
import EditPersonal from "./modals/EditPersonal";
import Attachment from "./modals/AttachmentModal";
import * as client from "../../actions/Client";
import "../../styles/CommenStyle.css";
var _ = require("lodash");

function ShareHolder(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getPersonalList();
    props.getDesignation();
    props.getSalutation();

    if (
      localStorage.getItem("permissions") &&
      localStorage.getItem("permissions").includes(49)
    ) {
      setEdit("inline-block");
    }

    return () => {
      table && table.destroy(true);
    };
  }, []);

  const [edit, setEdit] = useState("none");

  useEffect(() => {
    function format(d) {
      // console.log("did", d);
      return (
        `<tr id="detail-view-${d?.id}"><td colspan="7" style="padding:0px;"><table id='detail-view-${d?.id}'  style="width:100%" >` +
        "<tr>" +
        "<td>Date of Birth : " +
        moment(d?.date_of_birth).format("DD-MM-YYYY") +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Email : " +
        d?.email +
        "</td>" +
        "</tr>" +
        "<tr>" +
        `<td> NRIC or Passport No` +
        " : " +
        d?.identification_no +
        " </td>" +
        "</tr>" +
        "<tr>" +
        "<td>Address : " +
        d?.address +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Mobile : " +
        d?.phone +
        "</td>" +
        "</tr>" +
        "</table></td></tr>"
      );
    }
    //   $('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
    //     $($.fn.dataTable.tables(true)).DataTable()
    //        .columns.adjust()
    //        .responsive.recalc();
    //  });
    $.fn.DataTable.ext.pager.numbers_length = 10;
    $(document).ready(async function () {
      let value = _(props.personalList)
        .groupBy((x) => `${x.identification_no}_${x.name}`)
        .map((value, key) => ({ name: key, lists: value }))
        // .sortBy((o) => o.name)
        .value();
      // console.log("chinnu ", value)
      table = $("#office").DataTable({
        pageLength: 100,
        lengthMenu: [100, 200],
        paging: true,
        // pagingType: "full_numbers",
        destroy: true,
        searching: true,
        ordering: true,
        data: value,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            orderable: false,
            searching: true,
            className: " col-xs-1 col-sm-1 col-md-1 col-lg-1",
            render: function (data, type, row) {
              console.log("chinnu row", row);

              const nameParts = row.name.split("_");
              const displayValue = nameParts[0];
              const hideValue = nameParts[1] || "";

              return `<span>${displayValue}<span style="display:none;">${hideValue}</span></span>`;

              // return `<span>${row.name}</span>`;
            },
          },
        ],
        select: true,
        dom: "lBfrtip",
        buttons: [
          {
            extend: "collection",
            text: "Export",
            buttons: ["excel", "csv", "pdf", "print"],
          },
        ],
        rowCallback: function (row, data, index) {
          $(row).css("background-color", "#A9A9A9");
        },
        createdRow: function (row, data, dataIndex) {
          $(row).css("background-color", "#00BFFF");

          // Add COLSPAN attribute
          $("td:eq(0)", row).attr("colspan", 7).css("color", "white");

          // Hide required number of columns
          // next to the cell with COLSPAN attribute
          $("td:eq(1)", row).css("display", "none").css("width", "75px");
          $("td:eq(2)", row).css("display", "none").css("width", "75px");
          $("td:eq(3)", row).css("display", "none").css("width", "75px");
          $("td:eq(4)", row).css("display", "none").css("width", "75px");
          // $("td:eq(5)", row).css("display", "none").css("width", "75px");
          // $("td:eq(6)", row).css("display", "none").css("width", "75px");
        },
      });

      $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
        $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
      });

      table.rows().every(function () {
        var rowdata = this.data();
        // console.log("chinnu rowdata", rowdata)
        // var dataChild = [rowdata];
        var dataChild = rowdata.lists;
        let designation = "";
        let salutation = "";
        let name = "";
        let activeName =
          props.designationList &&
          props.designationList.filter(
            (item) => item.id == rowdata.designation_id
          );
        if (activeName.length != 0) {
          designation = activeName && activeName[0].Name;
        }
        // console.log("props.salutationList", props.salutationList)
        let activeSalute =
          props.salutationList &&
          props.salutationList.filter(
            (item) => item.id == rowdata.salutation_id
          );
        if (activeSalute.length != 0) {
          salutation = activeSalute[0].Name;
        }
        // console.log("salutation", salutation)
        this.child(
          dataChild.map((item) => {
            if (
              item.attachment != "" &&
              item.attachment != null &&
              item.attachment != undefined
            ) {
              // name = `${item.name}<a><span class="document glyphicon glyphicon-paperclip" /></a>`;
              name = item.name;
            } else {
              name = item.name;
            }
            return $(
              `<tr id=${item.id} style="width:${"100%"}" class="${
                item.is_primary == 1 ? "alert-info" : ""
              }">` +
                `<td  id=expand${item.id} title=${item.id}  class="details-control ">` +
                "</td>" +
                // "<td >" + item.company_name + "</td>" +
                "<td >" +
                item.identification_no +
                "</td>" +
                "<td >" +
                item.salutation_name +
                "</td>" +
                "<td  >" +
                name +
                "</td>" +
                // "<td >" + designation + "</td>" +
                "<td  >" +
                `<button class="btnEdit btn btn-primary btn-xs" style="display:${edit}" id=edit${
                  item.id
                } title="Edit" name=${
                  item.id
                } ><span class="glyphicon glyphicon-pencil" /></button>
                ${
                  item.is_primary == 1
                    ? ""
                    : `<button class="btnMerge btn btn-primary btn-xs" id=merge${item.id} title="Merge" data-ino="${item.identification_no}" name=${item.id}  ><span class="glyphicon glyphicon-resize-small" /></button>
                 <button class="btnRecord btn btn-primary btn-xs" id=record${item.id} title="Set Primary" data-ino="${item.identification_no}" name=${item.id}><span class="glyphicon glyphicon-record" /></button>`
                }
                 ` +
                "</td>" +
                "</tr>"
              // <button class="btn btn-primary btn-xs"  ><span class="glyphicon glyphicon-resize-small" /></button>
              //           <button class="btn btn-primary btn-xs" ><span class="glyphicon glyphicon-record" /></button>
            );
          })
        ).show();
      });

      $("#office").on("click", ".btnEdit", function () {
        var data = table.row(this).data();
        props.personalList &&
          props.personalList.map((val, idx) => {
            if (val.id == this.name) {
              props.setFieldValues("idNo", val.identification_no);
              props.setFieldValues("idType", val.identification_type_id);
              if (
                val.id_date_of_issue != "" &&
                val.id_date_of_issue != undefined &&
                val.id_date_of_issue != null &&
                val.id_date_of_issue != "0000-00-00"
              ) {
                props.setFieldValues(
                  "dateIssue",
                  new Date(val.id_date_of_issue)
                );
              }
              if (
                val.id_date_of_expiry != "" &&
                val.id_date_of_expiry != undefined &&
                val.id_date_of_expiry != null &&
                val.id_date_of_expiry != "0000-00-00"
              ) {
                props.setFieldValues(
                  "dateExpiry",
                  new Date(val.id_date_of_expiry)
                );
              }
              props.setFieldValues("designation", val.designation_id);
              props.setFieldValues("salutation", val.salutation_id);
              props.setFieldValues("name", val.name);
              props.setFieldValues("status", val.status);
              props.setFieldValues("address", val.address);
              props.setFieldValues("nationality", val.nationality_id);
              if (
                val.apponitment_date != "" &&
                val.apponitment_date != undefined &&
                val.apponitment_date != null &&
                val.apponitment_date != "0000-00-00"
              ) {
                props.setFieldValues(
                  "dateAppointment",
                  new Date(val.apponitment_date)
                );
              }
              if (
                val.resignation_date != "" &&
                val.resignation_date != undefined &&
                val.resignation_date != null &&
                val.resignation_date != "0000-00-00"
              ) {
                props.setFieldValues(
                  "dateResignation",
                  new Date(val.resignation_date)
                );
              }
              if (
                val.date_of_birth != "" &&
                val.date_of_birth != undefined &&
                val.date_of_birth != null &&
                val.date_of_birth != "0000-00-00"
              ) {
                props.setFieldValues("dateBirth", new Date(val.date_of_birth));
              }
              props.setFieldValues("email", val.email);
              props.setFieldValues("phone", val.phone);
              props.setFieldValues("flag", val.flag);
              props.setFieldValues("flagRemark", val.flag_remarks);
              props.setFieldValues("documentUpload", val.document_upload);
              props.setFieldValues("documentLink", val.document_link);
              props.setFieldValues("personal_id", val.id);
              props.setFieldValues("officeBearer", true);
              props.setFieldValues("editPersonal", true);
              // props.setFieldValues("attachment", val.attachment);
              props.setFieldValues("documentId", val.document_id);
              props.setFieldValues("documentKey", val.attachment_keyword);
              props.setFieldValues("documentType", val.document_type);
              props.setFieldValues("clientId", val.client_id);
              if (
                val.document_date != "" &&
                val.document_date != undefined &&
                val.document_date != null &&
                val.document_date != "0000-00-00"
              ) {
                props.setFieldValues(
                  "documentDate",
                  new Date(val.document_date)
                );
              }
              props.setFieldValues("note", val.notes);
            }
          });
      });

      $("#office").on("click", ".btnRecord", function () {
        console.log("chinnu", this.name, this.dataset.ino);
        let obj = {
          id: this.name,
          is_primary: 1,
          identification_no: this.dataset.ino,
        };
        props.setPrimary(obj);
      });

      $("#office").on("click", ".btnMerge", function () {
        console.log("chinnu", this.name, this.dataset.ino);
        let obj = {
          id: this.name,
        };
        props.setMerge(obj);
      });

      $("#office").on("click", ".document", function () {
        props.setValue("attachmentModal", true);
      });

      $("#office").on("click", ".btnDel", function () {
        var data = table.row(this).data();

        props.setFieldValues("personal_id", this.name);
        props.setFieldValues("deletePersonal", true);
      });

      $("#office").on("click", ".btnbox", function (e) {
        var data = table.row(this).data();
        let arr = [...props.personalList];
        arr[table.row(this).index()].check = e.target.checked;
        // props.setFieldValues("personalList", arr);
        if (e.target.name != "" && e.target.name != undefined) {
          props.setFieldValues("personal_id", e.target.name);
        } else {
          props.setFieldValues("personal_id", "");
        }
      });

      //search separate

      // table.columns().every(function () {

      //   var that = this;
      //   var header = this.header();
      //   $("input", this.footer()).on("keyup change", function () {
      //     that
      //       .column(header.getAttribute("data-search-index") * 1)
      //       .search(this.value)
      //       .draw();
      //   });
      // });

      table.columns().every(function () {
        var that = this; // Reference to the current column
        var header = this.header(); // Get the column header element

        console.log("Processing Column Header:", header.textContent); // Log column header text

        $("input", this.footer()).on("keyup change", function () {
          var searchIndex = header.getAttribute("data-search-index") * 1;
          var searchValue = this.value;

          console.log("Searching in Column Index:", searchIndex);
          console.log("Search Value Entered:", searchValue);

          that.column(searchIndex).search(searchValue).draw();
        });
      });

      // Add event listener for opening and closing details
      $("#office").on("click", ".details-control", function () {
        let data;
        props.personalList &&
          props.personalList.map((val, idx) => {
            if (val.id == this.title) {
              data = val;
            }
          });
        var tr = $(this).closest("tr");
        var row = table.row(tr);

        // if (row.child.isShown()) {
        //   // This row is already open - close it
        //   row.child.hide();
        //   tr.removeClass("shown");
        //   $("tr.shown td.details-control").toggleClass(
        //     "tr.shown td.details-control"
        //   );
        // } else {
        // Open this row

        // document.getElementById(this.title).appendChild($.parseHTML(format(data)))
        // if (!$(`#detail-view-${this.title}`).length) {
        //   $(`#${this.title}`).after(format(data));
        //   tr.addClass("shown");
        // } else {
        //   tr.removeClass("shown");
        //   $(`#detail-view-${this.title}`).remove();
        // }

        if (!tr.hasClass("shown") && !$(`#detail-view-${this.title}`).length) {
          if (data && Object.keys(data).length > 0) {
            tr.after(format(data));
            tr.addClass("shown");
          }
        } else if (
          !tr.hasClass("shown") &&
          $(`#detail-view-${this.title}`).length
        ) {
          tr.addClass("shown");
          $(`#detail-view-${this.title}`).css("display", "");
        } else {
          tr.removeClass("shown");
          $(`#detail-view-${this.title}`).css("display", "none");
          // $(`#detail-view-${this.title}`).remove();
        }
      });
    });
  }, [props.personalList]);

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div
          className="content-wrapper"
          id="content-window"
          style={{ minHeight: "607px" }}
        >
          <section className="content">
            {props.clientApiStatus ? (
              <div
                className={`alert ${
                  props.clientApiStatus === 1 ? "alert-success" : ""
                }`}
                role="alert"
              >
                {props.clientApiStatus === 1 ? props.message : ""}
              </div>
            ) : (
              ""
            )}
            <div
              className="box box-primary box_align_left"
              style={{ maxHeight: "100vh" }}
            >
              <div className="box-header">
                {" "}
                <i className="glyphicon glyphicon-user" />
                <h3 className="box-title">Office Bearer/Shareholder</h3>
                <div>
                  <nav className="navbar navbar-default" role="navigation">
                    <div className="container-fluid" id="navfluid">
                      <div className="navbar-header">
                        <button
                          type="button"
                          className="navbar-toggle"
                          data-toggle="collapse"
                          data-target="#navigationbar"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle navigation</span>
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                        </button>
                        <a className="navbar-brand" href="#" />
                      </div>
                      <div
                        className="collapse navbar-collapse"
                        id="navigationbar"
                      >
                        <ul className="nav navbar-nav">
                          <ul className="nav navbar-nav">
                            <li>
                              <a
                                href="#"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Office Bearer
                                <span className="caret" />
                              </a>
                              <ul className="dropdown-menu">
                                {localStorage.getItem("permissions") &&
                                localStorage
                                  .getItem("permissions")
                                  .includes(48) ? (
                                  <li>
                                    <a
                                      onClick={() => {
                                        props.setFieldValues(
                                          "addPersonal",
                                          true
                                        );
                                        props.setFieldValues(
                                          "officeBearer",
                                          true
                                        );
                                      }}
                                    >
                                      New
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </nav>
                  <div className="box-tools pull-right" />
                </div>
                <div />
              </div>
              <div className="box-body" style={{ minHeight: "100vh" }}>
                <div className="table-responsive">
                  <div
                    id="office-bearer_wrapper"
                    className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                  >
                    {/* <div className="row">
                        <div className="col-sm-6">
                          <div className="dataTables_length" id="office-bearer_length"><label>Show <select name="office-bearer_length" aria-controls="office-bearer" className="form-control input-sm">
                                <option value={1000}>1,000</option>
                                <option value={1500}>1,500</option>
                                <option value={2000}>2,000</option>
                                <option value={3000}>3,000</option>
                              </select> entries</label></div>
                        </div>
                        <div className="col-sm-6">
                          <div id="office-bearer_filter" className="dataTables_filter"><label>Search:<input type="search" className="form-control input-sm" placeholder aria-controls="office-bearer" /></label></div>
                        </div>
                      </div> */}
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12 dataTable no-footer"
                          role="grid"
                          style={{ marginLeft: 0 }}
                          id="office"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="details-control col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_asc"
                                style={{ width: "62px" }}
                                aria-label="Details"
                              >
                                Details
                              </th>
                              {/* <th className="hidden-xs hidden-sm hidden-md hidden-lg sorting_disabled" rowSpan={1} colSpan={1} style={{ width: '0px' }} aria-label="Identification Type">Identification Type
                                      </th> */}
                              {/* <th
                                className="col-xs-3 col-sm-3 col-md-2 col-lg-1 sorting"
                                tabIndex={0}
                                aria-controls="office-bearer"
                                style={{ width: "65px" }}
                                aria-label="Company Name: activate to sort column ascending"
                              >
                                Company Name
                              </th> */}
                              <th
                                className="col-xs-2 col-sm-2 col-md-2 col-lg-2 sorting"
                                tabIndex={0}
                                aria-controls="office-bearer"
                                style={{ width: "80px" }}
                                aria-label="Identification: activate to sort column ascending"
                              >
                                Identification
                              </th>
                              <th
                                className="col-xs-2 col-sm-2 col-md-2 col-lg-2 sorting"
                                tabIndex={0}
                                aria-controls="office-bearer"
                                style={{ width: "65px" }}
                                aria-label="Salutation: activate to sort column ascending"
                              >
                                Salutation
                              </th>
                              <th
                                className="col-xs-2 col-sm-2 col-md-2 col-lg-2 sorting"
                                tabIndex={0}
                                aria-controls="office-bearer"
                                style={{ width: "266px" }}
                                aria-label="Employee Name: activate to sort column ascending"
                              >
                                Office Bearer
                              </th>
                              {/* <th className="hidden-xs hidden-sm hidden-md hidden-lg sorting" tabIndex={0} aria-controls="office-bearer" rowSpan={1} colSpan={1} style={{ width: '0px' }} aria-label="Date of Birth: activate to sort column ascending">
                                      Date of Birth</th> */}
                              {/* <th
                                className="hidden-xs hidden-sm col-md-2 col-lg-2 sorting"
                                tabIndex={0}
                                aria-controls="office-bearer"
                                style={{ width: "267px" }}
                                aria-label="Designation(s): activate to sort column ascending"
                              >
                                Designation(s)
                              </th> */}
                              <th
                                className="col-xs-2 col-sm-2 col-md-2 col-lg-2 sorting_disabled"
                                style={{ width: "64px" }}
                                aria-label="Actions"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer clearfix no-border"></div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* ./wrapper */}
      <AddPersonal />
      <EditPersonal />
      <Attachment />
      <div id="myModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <h4 className="modal-title">Title</h4>
            </div>
            <div className="modal-body">Loading ...</div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { personalList, designationList, salutationList } = state.Personal;
  return {
    personalList,
    designationList,
    salutationList,
    processModal: state.Client.processModal,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPersonalList: () => dispatch(actions.getPersonalList()),
    setPrimary: (v) => dispatch(actions.setPrimary(v)),
    setMerge: (v) => dispatch(actions.setMerge(v)),
    setFieldValues: (f, v) => dispatch(actions.personal_data(f, v)),
    getDesignation: () => dispatch(actions.getDesignation()),
    getSalutation: () => dispatch(actions.getSalutation()),
    setValue: (f, v) => dispatch(client.changeClientInfo(f, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareHolder);
