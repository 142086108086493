let prof = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  name: localStorage.getItem("user_name"),
  companyName: "",
  display: "none",
  cpStatus: 0,
  errorMessage: "",
};

export const Profile = (state = prof, action) => {
  switch (action.type) {
    case "PROFILE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
