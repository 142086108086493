import config from "../common/Api_Links";
import { headers } from "../common/Headers";
import { ifTokenExpire } from "../common/TokenValidation";
import history from "../../stores/history";
import { changeClientInfo } from "../Client";

export const remainder = (field, value) => {
  return {
    type: "REMAINDER_DATA",
    field,
    value,
  };
};

export const getRemainderList = () => {
  var header = headers();
  let data = {
    client_id: localStorage.getItem("personalId"),
  };

  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.reminders_list, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(remainder("remainder_list", responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(remainder("remainder_list", []));
          }
        }
      });
  };
};
