import config from "../common/Api_Links";
import { headers } from "../common/Headers";
import { ifTokenExpire } from "../common/TokenValidation";
import { changeClientInfo } from "../Client";
import { groupBy } from "lodash";
import { s3_file_upload } from "../common/S3_Upload";

var _ = require("lodash");

export const share_register = (field, value) => {
  return {
    type: "SHARE_INFO",
    field,
    value,
  };
};

export const addShareRegister = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_shareRegister, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              // dispatch(personal_data("addPersonal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(share_register("addShares", false));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(getShareRegisterlist());
                dispatch(getShareHistorylist());
                dispatch(share_register("documentLink", ""));
                dispatch(share_register("resolutionDate", ""));
                dispatch(share_register("acrafileddate", ""));
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const addSplitShare = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_splitShare, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              // dispatch(personal_data("addPersonal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(share_register("splitShares", false));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(getShareRegisterlist());
                dispatch(getShareHistorylist());
                dispatch(share_register("splitDocLink", ""));
                dispatch(share_register("splitResolutionDate", ""));
                dispatch(share_register("acrafileddate", ""));
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const addTransferShare = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_transferShare, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              // dispatch(personal_data("addPersonal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(share_register("transferShares", false));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(getShareRegisterlist());
                dispatch(getShareHistorylist());
                dispatch(share_register("transferDocLink", ""));
                dispatch(share_register("transferResolutionDate", ""));
                dispatch(share_register("acrafileddate", ""));
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    }
  };
};

export const addReduceShare = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_reduceShare, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              // dispatch(personal_data("addPersonal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(share_register("reduceShares", false));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(getShareRegisterlist());
                dispatch(getShareHistorylist());
                dispatch(share_register("reduceDocLink", ""));
                dispatch(share_register("reduceResolutionDate", ""));
                dispatch(share_register("acrafileddate", ""));
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const getShareLot = (obj) => {
  var header = headers();

  let url = `${config.get_share_lot}/${obj.client_id}/${obj.value_per_share}/${obj.share_holder}/${obj.share_type}`;
  return (dispatch) => {
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.data.length > 0) {
          // console.log("chinnu responseData", responseData);
          dispatch(share_register("shareLots", responseData.data));
        } else {
          dispatch(share_register("shareLots", []));
        }
      });
  };
};

export const addConvertShare = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_convertShare, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              // dispatch(personal_data("addPersonal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(share_register("convertShares", false));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(getShareRegisterlist());
                dispatch(getShareHistorylist());
                dispatch(share_register("convertDocLink", ""));
                dispatch(share_register("convertResolutionDate", ""));
                dispatch(share_register("acrafileddate", ""));
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const splitShareBasedApi = (obj) => {
  console.log("splitShareBasedApi: ");
  let input = {
    ...obj,
    client_id: localStorage.getItem("personalId"),
  };
  var header = headers();
  return (dispatch) => {
    return fetch(config.share_based_values, {
      method: "POST",
      body: JSON.stringify(input),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1 && responseData.data.length > 0) {
            dispatch(
              share_register(
                "splitTotalShare",
                responseData.data[0].toatl_share_count
              )
            );
            dispatch(share_register("dummy", responseData.data[0].total_amt));
            dispatch(
              share_register("splitTotalValue", responseData.data[0].total_amt)
            );
            dispatch(
              share_register(
                "splitValues",
                responseData.data[0].value_per_share
              )
            );

            // let round = Math.round(
            //   responseData.data[0].total_amt /
            //     responseData.data[0].toatl_share_count
            // );
            // dispatch(share_register("splitValues", round));
          } else {
            dispatch(share_register("splitTotalShare", 0));
            dispatch(share_register("dummy", 0));
            dispatch(share_register("splitTotalValue", 0));
            dispatch(share_register("splitValues", 0));
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(
              changeClientInfo("message", "You don't have shares in Share type")
            );
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const transfarShareBasedApi = (obj) => {
  console.log("splitShareBasedApi: ");
  let input = {
    ...obj,
    client_id: localStorage.getItem("personalId"),
  };
  var header = headers();
  return (dispatch) => {
    return fetch(config.transfer_based_values, {
      method: "POST",
      body: JSON.stringify(input),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 0) {
            // dispatch(
            //   share_register(
            //     "splitTotalShare",
            //     responseData.data[0].toatl_share_count
            //   )
            // );
            // dispatch(
            //   share_register("dummy", responseData.data[0].total_amt)
            // );
            // dispatch(
            //   share_register("splitTotalValue", responseData.data[0].total_amt)
            // );
            // dispatch(
            //   share_register("splitValues", responseData.data[0].value_per_share)
            // );

            // let round = Math.round(
            //   responseData.data[0].total_amt /
            //     responseData.data[0].toatl_share_count
            // );
            // dispatch(share_register("splitValues", round));
            // } else {
            // dispatch(share_register("splitTotalShare",0));
            // dispatch(share_register("dummy", 0));
            // dispatch(
            //   share_register("splitTotalValue", 0)
            // );
            // dispatch(
            //   share_register("splitValues", 0)
            // );
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(
              changeClientInfo("message", "You don't have shares in Share type")
            );
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const getShareTypeList = (data) => {
  let id;
  if (data == undefined) {
    id = "";
  } else {
    id = data;
  }

  let input = {
    share_holder_id: id,
    client_id: localStorage.getItem("personalId"),
  };
  var header = headers();
  return (dispatch) => {
    return fetch(config.share_type_list, {
      method: "POST",
      body: JSON.stringify(input),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(share_register("ShareTypeList", responseData.data));
            // dispatch(share_register("toShareTypeList", responseData.data));
          } else {
            dispatch(share_register("ShareTypeList", []));
            // dispatch(share_register("toShareTypeList", []));
          }
        }
      });
  };
};

export const getShareHolderList = (data) => {
  let input = {
    client_id: localStorage.getItem("personalId"),
  };
  var header = headers();
  return (dispatch) => {
    return fetch(config.share_holder_list, {
      method: "POST",
      body: JSON.stringify(input),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(share_register("shareHolderList", responseData.data));
            dispatch(share_register("toShareHolderList", responseData.data));
          } else {
            dispatch(share_register("shareHolderList", []));
            dispatch(share_register("toShareHolderList", []));
          }
        }
      });
  };
};
export const getShareHolderDirectorList = (data) => {
  let input = {
    client_id: localStorage.getItem("personalId"),
  };
  var header = headers();
  return (dispatch) => {
    return fetch(config.shareholderanddirecor_list, {
      method: "POST",
      body: JSON.stringify(input),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(share_register("shareHolderList", responseData.data));
            dispatch(share_register("toShareHolderList", responseData.data));
          } else {
            dispatch(share_register("shareHolderList", []));
            dispatch(share_register("toShareHolderList", []));
          }
        }
      });
  };
};
export const getValuePerShareList = (data, type) => {
  let id;
  let type_id;
  if (data == undefined) {
    id = "";
  } else {
    id = data;
  }

  if (type == undefined) {
    type_id = "";
  } else {
    type_id = type;
  }

  let input = {
    share_holder_id: id,
    share_type_id: type_id,
    client_id: localStorage.getItem("personalId"),
  };
  var header = headers();
  return (dispatch) => {
    return fetch(config.share_value_list, {
      method: "POST",
      body: JSON.stringify(input),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(share_register("valuePerShareList", responseData.data));
          } else {
            dispatch(share_register("valuePerShareList", []));
          }
        }
      });
  };
};

// export const getShareTypeList = () => {
//   var header = headers();
//   return (dispatch) => {
//     return fetch(config.settings_share_type_list, {
//       method: "GET",
//       headers: header,
//     })
//       .then((response) => {
//         if (response) {
//           return response.json();
//         }
//       })
//       .then((responseData) => {
//         if (responseData) {
//           ifTokenExpire(responseData);
//           if (responseData.status === 1) {
//             dispatch(share_register("ShareTypeList", responseData.data));
//           } else {
//             dispatch(share_register("ShareTypeList", []));
//           }
//         }
//       });
//   };
// };

export const getDocumentClassification = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_document_classification_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(share_register("DocumentTypeList", responseData.data));
          } else {
            dispatch(share_register("DocumentTypeList", []));
          }
        }
      });
  };
};

export const getShareRegisterlist = () => {
  var header = headers();
  let data = localStorage.getItem("personalId");
  let url =
    "https://api-gbs.smitiv.co/api/v1/shareregister/list?client_id=" + data;
    // "https://localhost:4444/api/v1/shareregister/list?client_id=" + data;

  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            // console.log("repon", responseData.data);
            var result = _(responseData.data)
              .groupBy((x) => x.share_type)
              .map((value, key) => ({ name: key, lists: value }))
              .value();
            // console.log("result", result);
            var groupByUserAndShareType = result.map((item) => {
              var userId = item.name;
              var byShare = _(item.lists)
                .groupBy((x) => x.share_holder)
                .map((value, key) => ({ name: key, lists: value }))
                .value();
              // console.log("share", userId, { [userId]: byShare });
              return { [userId]: byShare };
            });

            // console.log("groupByUserAndShareType", groupByUserAndShareType);
            var type = _(responseData.data)
              .groupBy((x) => x.share_type)
              .map((value, key) => ({ name: key, list: value }))
              .value();
            // type.map((val)=>{
            //   val.list.map((ag)=>{
            //     arr.push(ag.no_of_shares_held);
            //   })
            // })
            // let red =   arr.reduce((acc, cur) => {
            //   return acc + cur;
            // });
            // result.map((res)=>{
            //   res.lists.map((lis,idx)=>{

            //        lis.total=red

            //   })
            // })
            dispatch(share_register("shareRegisterListDuplicate", type));

            // console.log("cod", type);
            dispatch(share_register("shareRegisterList", responseData.data));
            dispatch(getShareTypeList());
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(share_register("shareRegisterList", []));
            dispatch(share_register("shareRegisterListDuplicate", []));
          }
        }
      });
  };
};

export const getShareHistorylist = () => {
  var header = headers();
  let data = { client_id: localStorage.getItem("personalId") };
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.share_history_list, {
      method: "POST",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then(async (responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            var result = [];
            result = await Promise.all(
              await responseData.data.map((item) => {
                var res = item;
                res.list = _(item.list)
                  .groupBy((x) => x.share_type)
                  .map((value, key) => ({ name: key, lists: value }))
                  .value();
                res.shisData = _(item.shisData)
                  .groupBy((x) => x.share_type)
                  .map((value, key) => ({ name: key, lists: value }))
                  .value();
                return res;
              })
            );
            // .sortBy((o) => o.date)
            //   console.log("chinnu result", result);
            // let resVal = _(result).sortBy((o) => o.date)
            dispatch(share_register("shareHistoryList", result));
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(share_register("shareHistoryList", []));
          }
        }
      });
  };
};

export const getShareResolutionlist = () => {
  var header = headers();
  let data = { client_id: localStorage.getItem("personalId") };
  return (dispatch) => {
    dispatch(share_register("shareResolutionList", []));
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.share_history_list, {
      method: "POST",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(share_register("shareResolutionList", responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(share_register("shareResolutionList", []));
          }
        }
      });
  };
};

// export const editShareRegister = (data) => {
//   console.log("*************************** editShareRegister Starts *************************************");
//   var header = headers();
//   return async (dispatch) => {
//     var response = {
//       status: 1,
//     };
//     if (data.attachment != "") {
//       response = await s3_file_upload(data);
//     }
//     console.log("persona", response);
//     if (response.status === 1) {
//       data.attachment = response.original_link;
//       return fetch(config.edit_shareRegister, {
//         method: "POST",
//         body: JSON.stringify(data),
//         headers: header,
//       })
//         .then((response) => {
//           if (response) {
//             return response.json();
//           }
//         })
//         .then((responseData) => {
//           if (responseData) {
//             ifTokenExpire(responseData);
//             console.log('if (responseData) ');

//             if (responseData.status === 1) {
//               console.log('if (responseData.status === 1) ');
//               // dispatch(personal_data("addPersonal", false));
//               dispatch(changeClientInfo("message", responseData.message));
//               dispatch(share_register("editShares", false));
//               dispatch(changeClientInfo("clientApiStatus", 1));

//               setTimeout(() => {
//                 // window.location.reload();
//                 dispatch(changeClientInfo("clientApiStatus", 0));
//                 dispatch(getShareRegisterlist());
//                 dispatch(getShareResolutionlist());
//                 dispatch(getShareHistorylist());
//                 dispatch(share_register("documentLink", ""));
//               }, 5000);
//             } else {

//               console.log('else (responseData.status === 1)');
//               dispatch(changeClientInfo("clientApiStatus", 2));
//             dispatch(changeClientInfo("message", "You don't have shares in Share type"));
//             setTimeout(() => {
//               dispatch(changeClientInfo("clientApiStatus", 0));
//               dispatch(changeClientInfo("message", ""));
//             }, 2500);
//             }
//           } else{
//             console.log('else (responseData) ');
//             dispatch(changeClientInfo("clientApiStatus", 2));
//             dispatch(changeClientInfo("message", "You don't have shares in Share type"));
//             setTimeout(() => {
//               dispatch(changeClientInfo("clientApiStatus", 0));
//               dispatch(changeClientInfo("message", ""));
//             }, 2500);
//           }
//         });
//     } else {
//       dispatch(changeClientInfo("clientApiStatus", 2));
//             dispatch(changeClientInfo("message", "You don't have shares in Share type"));
//             setTimeout(() => {
//               dispatch(changeClientInfo("clientApiStatus", 0));
//               dispatch(changeClientInfo("message", ""));
//             }, 2500);
//     }
//   };
// };
export const editShareRegister = (data) => {
  console.log(
    "*************************** editShareRegister Starts *************************************"
  );
  var header = headers();

  return async (dispatch) => {
    var response = { status: 1 };

    if (data.attachment !== "") {
      response = await s3_file_upload(data);
    }

    console.log("persona", response);

    if (response.status === 1) {
      data.attachment = response.original_link;

      return fetch(config.edit_shareRegister, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        }) // Ensure JSON parsing
        .then((responseData) => {
          ifTokenExpire(responseData);

          console.log("API Response:", responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(share_register("editShares", false));
            dispatch(changeClientInfo("clientApiStatus", 1));

            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(getShareRegisterlist());
              dispatch(getShareResolutionlist());
              dispatch(getShareHistorylist());
              dispatch(share_register("documentLink", ""));
            }, 5000);

            return responseData; // Ensure the Promise resolves with data
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));

            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);

            return responseData; // Still return responseData for handling
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          throw error; // Ensure the error is propagated
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(
        changeClientInfo("message", "You don't have shares in Share type")
      );

      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);

      return Promise.reject("Attachment upload failed"); // Return a rejected Promise
    }
  };
};

export const approveShare = (val) => {
  var header = headers();
  let value = { resolution_id: val };

  return (dispatch) => {
    return fetch(config.approve_resolution_share, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status === 1) {
          ifTokenExpire(responseData);
          dispatch(share_register("approveShareModal", false));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));

            dispatch(
              getShareResolutionlist(localStorage.getItem("personalId"))
            );
            dispatch(share_register("resolution_id", ""));
          }, 2500);
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const deleteResolutionById = (val, rdate, action_type) => {
  var header = headers();
  // let value = { client_charge_id: val };
  let client_id = localStorage.getItem("personalId");
  let url = `${config.delete_resolution}/${val}/${rdate}/${client_id}/${action_type}`;
  return (dispatch) => {
    return fetch(url, {
      method: "DELETE",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(share_register("deleteShareModal", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(
                getShareResolutionlist(localStorage.getItem("personalId"))
              );
              dispatch(share_register("shareId", ""));
              dispatch(share_register("resolutionDate", ""));
            }, 2500);
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
              dispatch(share_register("shareId", ""));
              dispatch(share_register("resolutionDate", ""));
            }, 2500);
          }
        }
      });
  };
};
