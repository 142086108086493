import config from "./common/Api_Links";
import { headers } from "./common/Headers";
import { ifTokenExpire } from "./common/TokenValidation";
import { share_register } from "./clientDetails/ShareRegister";
import { company_list } from "./CompanySetups";

export const settings_data = (field, value) => {
  return {
    type: "SETTINGS_DATA",
    field,
    value,
  };
};
export const documentFunction = (field, value) => {
  return {
    type: "DOCUMENT_DATA",
    field,
    value,
  };
};
export const changeDocInfo = (field, value) => {
  return {
    type: "CHANGE_DOC_INFO",
    field,
    value,
  };
};
export const updateCommonSettingErrors = (data) => {
  return {
    type: "UPDATE_COMMON_ERRORS",
    data,
  };
};
export const singleperson_list = (data) => {
  return {
    type: "SINGLE_PERSON",
    data,
  };
};
export const changeCommonInfo = (field, value) => {
  return {
    type: "CHANGE_COMMON_INFO",
    field,
    value,
  };
};

export const reset_common_info = () => {
  return {
    type: "RESET_COMMON_INFO",
  };
};

export const getCurrencyList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_currency_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getNationalitiesList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_nationality_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getEmployeeTypeList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_employee_type_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getSettingsShareTypeList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_share_type_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
            dispatch(share_register("toShareTypeList", responseData.data));
            dispatch(share_register("toShareTypeListMain", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getTaskSettingsList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_task_setting_List, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getInvoiceTypeList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_invoice_category_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getServices = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_service_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getIdTypes = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_id_type_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getSalutation = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_salutation_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getDocumentClassification_new = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.document_category, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
            dispatch(settings_data("DocumentTypeListnew", responseData.data));
          } else {
            dispatch(settings_data("List", []));
            dispatch(settings_data("DocumentTypeListnew", []));
          }
        }
      });
  };
};

export const getDocumentClassification = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_document_classification_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
            dispatch(settings_data("DocumentTypeList", responseData.data));
          } else {
            dispatch(settings_data("List", []));
            dispatch(settings_data("DocumentTypeList", []));
          }
        }
      });
  };
};

export const getClientStatus = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_client_status_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getDocumentStatus = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_document_status_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getIdendificationTypes = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_idendification_types_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getFlag = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_flag_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};

export const getDocumentClassificationbyid = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(
      config.document_categorybyId +
        data +
        "/" +
        localStorage.getItem("personalId"),
      {
        method: "GET",
        headers: header,
      }
    )
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(documentFunction("documentTypeList", responseData.data));
          } else {
            dispatch(documentFunction("documentTypeList", []));
          }
        }
      });
  };
};
export const getDocumentClassificationbyidnew = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.document_categorybyIdnew + data, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        console.log("responseData", responseData);
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("DocumentTypeListnew", responseData.data));
          } else {
            dispatch(settings_data("DocumentTypeListnew", []));
          }
        }
      });
  };
};
export const getDocumentClassificationbyidFilter = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.filterDocument, {
      method: "POST",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        console.log("responseDataaa", responseData);
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("DocumentTypeListnew", responseData.data));
          } else {
            dispatch(settings_data("DocumentTypeListnew", []));
          }
        }
      });
  };
};
export const getDocumentCountbyidnew = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.document_countbyId + data, {
      method: "POST",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        console.log("responseDataaa", responseData);
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("DocumentTypeListcount", responseData.data));
          } else {
            dispatch(settings_data("DocumentTypeListcount", []));
          }
        }
      });
  };
};

export const getCommonSettingsList = (data) => {
  var header = headers();
  const { page_selected } = data;
  let apiname = `settings_${page_selected}_list`;
  console.log("api", config[apiname]);
  return (dispatch) => {
    return fetch(config[apiname], {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List", responseData.data));
          } else {
            dispatch(settings_data("List", []));
          }
        }
      });
  };
};
export const getIdentificationno = (data) => {
  var header = headers();
  var jsonData = {
    identification_no: data,
  };
  return (dispatch) => {
    return fetch(config.getbyidentification_no, {
      method: "POST",
      body: JSON.stringify(jsonData),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            let add = [responseData.data];
            dispatch(singleperson_list(add));
          } else {
            dispatch(singleperson_list([]));
            console.log(responseData);
          }
        }
      });
  };
};
export const addNewSettingItem = (data) => {
  var header = headers();
  const { description, setting_type } = data;
  let apiname = `add_${setting_type}`;
  let key = `${setting_type.replace("category", "type")}_code`;
  var jsonData = {
    [key]: description,
  };
  return (dispatch) => {
    return fetch(config[apiname], {
      method: "POST",
      body: JSON.stringify(jsonData),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(
              getCommonSettingsList({
                page_selected: setting_type,
              })
            );
            console.log("success");
            dispatch(settings_data("addCommonErrors", {}));
            dispatch(settings_data("addCommonStatus", 1));
          } else {
            dispatch(settings_data("addCommonStatus", 2));
            dispatch(settings_data("message", responseData.message));
          }
        }
      });
  };
};

export const editSettingItem = (data) => {
  var header = headers();
  const { description, setting_type, id } = data;
  let apiname = `edit_${setting_type}`;
  console.log("api", config[apiname]);
  let key = `${setting_type.replace("category", "type")}`;
  var jsonData = {
    [`${key}_code`]: description,
    [`${key}_id`]: id,
  };
  return (dispatch) => {
    return fetch(config[apiname], {
      method: "POST",
      body: JSON.stringify(jsonData),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(
              getCommonSettingsList({
                page_selected: setting_type,
              })
            );
            console.log("success");
            dispatch(settings_data("addCommonErrors", {}));
            dispatch(settings_data("addCommonStatus", 1));
          } else {
            dispatch(settings_data("addCommonStatus", 2));
            dispatch(settings_data("message", responseData.message));
          }
        }
      });
  };
};

export const addNewServiceItem = (data) => {
  var header = headers();

  return (dispatch) => {
    return fetch(config.add_service, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(
              getCommonSettingsList({
                page_selected: "service",
              })
            );
            dispatch(settings_data("addCommonErrors", {}));
            dispatch(settings_data("addCommonStatus", 1));
          } else {
            dispatch(settings_data("addCommonStatus", 2));
            dispatch(settings_data("message", responseData.message));
          }
        }
      });
  };
};

export const editServiceItem = (data) => {
  var header = headers();

  return (dispatch) => {
    return fetch(config.edit_service, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(
              getCommonSettingsList({
                page_selected: "service",
              })
            );
            console.log("success");
            dispatch(settings_data("addCommonErrors", {}));
            dispatch(settings_data("addCommonStatus", 1));
          } else {
            dispatch(settings_data("addCommonStatus", 2));
            dispatch(settings_data("message", responseData.message));
          }
        }
      });
  };
};

export const addNewEmailItem = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.add_email_template, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(
              getCommonSettingsList({
                page_selected: "email_template",
              })
            );
            console.log("success");
            dispatch(settings_data("addCommonErrors", {}));
            dispatch(settings_data("addCommonStatus", 1));
          } else {
            dispatch(settings_data("addCommonStatus", 2));
            dispatch(settings_data("message", responseData.message));
          }
        }
      });
  };
};

export const editEmailItem = (data) => {
  var header = headers();

  return (dispatch) => {
    return fetch(config.edit_email_template, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(
              getCommonSettingsList({
                page_selected: "email_template",
              })
            );
            console.log("success");
            dispatch(settings_data("addCommonErrors", {}));
            dispatch(settings_data("addCommonStatus", 1));
          } else {
            dispatch(settings_data("addCommonStatus", 2));
            dispatch(settings_data("message", responseData.message));
          }
        }
      });
  };
};
