import React, { Component, Fragment, useEffect, useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import history from "../../stores/history";
import AddNewClient from "./modals/AddNewClientCompny";
import EditClient from "./modals/EditClientCompany";
import DeleteClient from "./modals/DeleteClientCompany";
import ApproveClient from "./modals/ApproveClient";
import Nothing from "./modals/NothingSelected";
import Attachment from "./modals/AttachmentModal";
import * as personalActions from "../../actions/clientDetails/Personal";
import * as actions from "../../actions/Client";
import { connect } from "react-redux";
import moment from "moment";
import * as settings from "../../actions/Settings";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function Dashboard(props) {
  // let { history } = props;

  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getList();
    props.getUserPermissionsList();
    props.getDocumentList();

    if (
      localStorage.getItem("permissions") &&
      localStorage.getItem("permissions").includes(15)
    ) {
      setEdit("inline-block");
    }

    if (
      localStorage.getItem("permissions") &&
      localStorage.getItem("permissions").includes(16)
    ) {
      setDelet("inline-block");
    }
  }, []);

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");
  const [permissionError, setPermissionError] = useState(false);

  useEffect(() => {}, [props.clientList]);

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />

        <Footer />
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    clientList: state.Client.clientList,
    client_key: state.Client.client_key,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    company_id: state.Client.company_id,
    userPermissionsList: state.Client.userPermissionsList,
    processModal: state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeClientInfo: (field, value) =>
      dispatch(actions.changeClientInfo(field, value)),
    sendValues: (f, v) => dispatch(personalActions.personal_data(f, v)),
    getList: (data) => dispatch(actions.getClientList(data)),
    getUserPermissionsList: () => dispatch(actions.getUserPermissionsList()),
    getDocumentList: () => dispatch(settings.getDocumentClassification()),
    generatepdf: (data) => dispatch(actions.generatepdf(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
