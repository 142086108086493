import React, { Fragment } from "react";
import { Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import * as actions from "../../../actions/UserManagement";



function Deleteuser(props) {

  const delFunc = () => {

    props.deleteUser(props.user_id);
    window.location.reload();

  };



  
  return (
    <Modal show={props.deleteUserShow} onHide={() => props.setValues('deleteUserShow', false)} className="modal bootstrap-dialog type-primary fade size-normal in" role="dialog" aria-hidden="true" id="de941d4e-9234-4d50-bf7f-494129ca3a81" aria-labelledby="de941d4e-9234-4d50-bf7f-494129ca3a81_title" tabIndex={-1} style={{ zIndex: 1050, display: 'block' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="bootstrap-dialog-header">
              <div className="bootstrap-dialog-close-button" style={{ display: 'none' }}><button className="close" aria-label="close">×</button></div>
              <div className="bootstrap-dialog-title" id="de941d4e-9234-4d50-bf7f-494129ca3a81_title">Confirm
                  Delete</div>
            </div>
          </div>
          <div className="modal-body">
            {props.userApiStatus ? (
              <div
                className={`alert ${props.userApiStatus === 1 ? "" : "alert-danger"
                  }`}
                role="alert"
              >
                {props.userApiStatus === 1 ? '' : props.message}
              </div>
            ) : (
              ""
            )}
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">Confirm to delete?</div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons"><button onClick={delFunc} className="btn form-control btn-danger" id="42c361fe-c029-4928-8252-8aa409078b18"><span className="bootstrap-dialog-button-icon glyphicon glyphicon-check" />Delete</button><button onClick={() => { props.setValues('deleteUserShow', false) ;  window.location.reload(); }} className="btn form-control" id="a15fda14-74a5-4c5e-ae29-a97f1f6c2642"><span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};




const mapStateToProps = (state, ownProps) => {
  const { deleteUserShow, user_id } = state.UserDatas;
  return {
    deleteUserShow, user_id,
    userApiStatus: state.UserDatas.userApiStatus,
    message: state.UserDatas.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),
    deleteUser: (data) => dispatch(actions.deleteUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deleteuser);