import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  deadline,
  editDeadline,
} from "../../../actions/clientDetails/Deadline";
import * as client from "../../../actions/Client";
import * as settings from "../../../actions/Settings";
import { MultiSelect } from "react-multi-select-component";



function EditDeadline(props) {
  const [newPersonalList, setnewPersonalList] = useState([]);
  const [toPersonalList, setToPersonalList] = useState([]);
  const [ccPersonalList, setCcPersonalList] = useState([]);
  useEffect(() => {
    props.allListDocuments(sessionStorage.getItem("personalId"))
    props.getDocumentList();
    let pList = [];
    props.personalList.map(personal => (pList.push({ label: personal.name + "-" + personal.designation_name, value: personal.id })))
    setnewPersonalList(pList);
  }, []);

  useEffect(() => {
    let toList = [], ccList = [];
    var filterList = props.personalList.filter(o => props.to.includes(o.id.toString()));
    filterList.map((e) => {
      toList.push(({ label: e.name + "-" + e.designation_name, value: e.id }))
    });
    setToPersonalList(toList);

    var filterListcc = props.personalList.filter(o => props.cc.includes(o.id.toString()));
    filterListcc.map((e) => {
      ccList.push(({ label: e.name + "-" + e.designation_name, value: e.id }))
    });
    setCcPersonalList(ccList);

  }, [props.to, props.cc]);

  const getfilename = (e) => {
    var splitedval = (e.target.value).split("_")
    props.sendValues("docLink", e.target.value)

    var index = e.nativeEvent.target.selectedIndex;

    if (e.target.value != 0 && e.target.value != '') {
      props.sendValues("docLink", e.target.value)
      props.alldocumentclientList && props.alldocumentclientList.map((filepathresultnn, indexvalnn) => {

        if (splitedval[0] == filepathresultnn.id) {
          document.getElementById("viewdocument").style.display = "block";
          document.getElementById("viewdocument").href = filepathresultnn.file_path;
        }
      })
    } else {
      document.getElementById("viewdocument").style.display = "none";
    }

  }
  const editFunc = () => {
    var userData = JSON.parse(localStorage.getItem("userData"));
    let rem_start;
    let complete_key;
    let completed_fs;
    if (props.clickStart == true) {
      rem_start = 1;
    } else {
      rem_start = 0;
    }
    if (props.isCompleted == true) {
      complete_key = 1;
    } else {
      complete_key = 0;
    };
    if (props.completedfs == true) {
      completed_fs = 1;
    } else {
      completed_fs = 0;
    };

    let document_date = '';
    if (props.docDate != '') {
      document_date = moment(props.docDate).format('YYYY-MM-DD')
    }
    var to_ids = [];
    if (props.to != undefined || props.to.length > 0) {
      props.to.map((t) => {
        to_ids.push(t.toString());

      })
    }
    var cc_ids = [];
    if (props.cc != undefined || props.cc.length > 0) {
      props.cc.map((t) => {
        cc_ids.push(t.toString());

      })
    }
    let data = {
      company_id: userData.company_id,
      deadline_id: props.deadline_id,
      client_id: props.client_id ? props.client_id : sessionStorage.getItem("personalId"),
      client_dedadline_task_id: props.clientDeadline,
      financial_year: moment(props.finYear).format("YYYY-MM-DD"),
      deadline_date: moment(props.deadlineDate).format("YYYY-MM-DD"),
      remainder_date: moment(props.remainderDate).format("YYYY-MM-DD"),
      frequency: props.frequency,
      reminder_type_id: props.remType,
      receipent_to: to_ids,
      receipent_cc: cc_ids,
      remarks: props.remark,
      date_submitted: moment(props.submitDate).format("YYYY-MM-DD"),
      is_completed: complete_key,
      is_start_reminder: rem_start,
      active_tab: props.pageSelected,
      document_link: props.docLink,
      document_id: props.docId,
      attachment: props.attachment,
      attachment_keyword: props.attachmentKey,
      document_type: props.docType,
      document_date: document_date,
      notes: props.notes,
      description: props.description,
      completedfs: completed_fs,

    };
    props.editDeadline(data);
  };
  return (
    <Modal
      show={props.editDeadlineModal}
      onHide={() => {
        props.sendValues("editDeadlineModal", false)
        props.sendValues("deadline_id", '');
      }}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="editdeadline"
      aria-labelledby="editdeadline"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="ec746694-f16c-429a-ab2b-83b613d285d5_title"
              >
                Edit Deadline
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div className="bootstrap-dialog-body">
                  <form
                    onSubmit={editFunc}
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    id="form"
                    className="form-horizontal"
                  >
                    <input name="_method" type="hidden" />
                    <input name="_token" type="hidden" />
                    <div className="form-group">
                      <label
                        htmlFor="financialyear"
                        className="control-label col-sm-4 required"
                      >
                        Financial Year
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.finYear}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("finYear", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() => props.sendValues("finYear", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="deadlinedate"
                        className="control-label col-sm-4 required"
                      >
                        Deadline Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.deadlineDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("deadlineDate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() => props.sendValues("deadlineDate", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="datesubmitted"
                        className="control-label col-sm-4"
                      >
                        Date Submitted
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.submitDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("submitDate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() => props.sendValues("submitDate", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="description"
                        className="control-label col-sm-4"
                      >
                        Remarks
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          value={props.remark}
                          onChange={(e) =>
                            props.sendValues("remark", e.target.value)
                          }
                          rows={3}
                          name="description"
                          cols={50}
                          id="description"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="isstopped" className="control-label col-sm-4">
                        Completed
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="isstopped"
                          name="isstopped"
                          onChange={(e) =>
                            props.sendValues("isCompleted", e.target.checked)
                          }
                          type="checkbox"
                          checked={props.isCompleted}
                          value="1"
                          className="magic-checkbox"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div
                        className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        id="errortext"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="tolist[]"
                        className="control-label col-sm-4"
                      >
                        To
                      </label>
                      <div className="col-sm-8">
                        <MultiSelect
                          options={newPersonalList}
                          value={toPersonalList}
                          onChange={to =>{
                            let ms = to.map(k => k.value.toString());
                            props.sendValues("to", ms);
                          }}
                          labelledBy="Select"
                        >
                          
                        </MultiSelect>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="cclist"
                        className="control-label col-sm-4"
                      >
                        CC
                      </label>
                      <div className="col-sm-8">
                        <MultiSelect
                          options={newPersonalList}
                          value={ccPersonalList}
                          onChange={cc =>{
                            let ms = cc.map(k => k.value.toString());
                            props.sendValues("cc", ms)
                          }}
                          labelledBy="Select"
                        >
                        </MultiSelect>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="durationtype"
                        className="control-label col-sm-4 required"
                      >
                        Frequency
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.frequency}
                          onChange={(e) =>
                            props.sendValues("frequency", e.target.value)
                          }
                          required="required"
                          id="durationtype"
                          name="durationtype"
                        >
                          <option value={1}>Daily</option>
                          <option value={2}>Weekly</option>
                          <option value={3}>Monthly</option>
                          <option value={4}>Yearly</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="template_id"
                        className="control-label col-sm-4 required"
                      >
                        Reminder Type
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.remType}
                          onChange={(e) =>
                            props.sendValues("remType", e.target.value)
                          }
                          required="required"
                          id="template_id"
                          name="template_id"
                        >
                          <option value="">===select===</option>
                          {props.remTypeList.map((val) => {
                            return (
                              <option value={val.id}>{val.email_tag}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="remainderdate"
                        className="control-label col-sm-4 required"
                      >
                        Remainder Start Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.remainderDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("remainderDate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() => props.sendValues("remainderDate", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="isstopped" className="control-label col-sm-4">
                        Click to Stop Reminder
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="isstopped"
                          checked={props.clickStart}
                          onChange={(e) =>
                            props.sendValues("clickStart", e.target.checked)
                          }
                          name="isstopped"
                          type="checkbox"
                          value="1"
                          className="magic-checkbox"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="completedfs" className="control-label col-sm-4">
                        Completed FS
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="completedfs"
                          name="completedfs"
                          onChange={(e) =>
                            props.sendValues("completedfs", e.target.checked)
                          }
                          type="checkbox"
                          value="1"
                          className="magic-checkbox"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="document_link"
                        className="control-label col-sm-4"
                      >
                        Document Link
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.docLink}
                          // onChange={(e) =>
                          //   props.sendValues("docLink", e.target.value)
                          // }
                          onChange={(e) => getfilename(e)}
                          id="document_link"
                          name="document_link"
                        >
                          <option value="" selected="selected">
                          View from the drop-down list / Select New Doc to upload new
                          </option>
                          <option value={0}>New Document</option>
                          {
                            props.alldocumentclientList && props.alldocumentclientList.map((filepathresult, index) => {
                              return (<>
                                <option value={filepathresult.id + "_" + filepathresult.document_type + "_" + filepathresult.indexno}> {filepathresult.file_path.split('/').pop().split('?')[0].split('#')[0].replace(/[/\\?%*:|"<>]/g, '-')}</option>
                              </>);
                            })
                          }
                        </select>
                        <a href="" id="viewdocument" style={{ display: "none" }} target="_blank">View Selected Document</a>
                      </div>
                    </div>
                    {props.docLink == "0" ? (
                      <div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_id"
                            className="control-label col-sm-4"
                          >
                            Document ID
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              value={props.docId}
                              onChange={(e) =>
                                props.sendValues("docId", e.target.value)
                              }
                              name="document_id"
                              type="text"
                              id="document_id"
                            />
                            <p className="help-block">
                              Must be unique ID with this client{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="original_filename"
                            className="control-label col-sm-4"
                          >
                            Attachment
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                if (e.target.files[0].size <= 2000000) {
                                  props.sendValues(
                                    "attachment",
                                    e.target.files[0]
                                  )
                                } else {
                                  props.sendValues("attachment", '');
                                  e.target.value = '';
                                  props.sendError("clientApiStatus", 2);
                                  props.sendError("message", "File size limit only 2Mb");
                                  setTimeout(() => {
                                    props.sendError("clientApiStatus", 0);
                                    props.sendError("message", "");
                                  }, 2500);
                                }
                              }
                              }
                              maxLength={4096}
                              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                              name="original_filename"
                              type="file"
                              id="original_filename"
                            />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="document_tag"
                            className="control-label col-sm-4">Description</label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              value={props.description}
                              rows={3}
                              onChange={(e) => { props.sendValues("description", e.target.value) }}
                              name="document_tag"
                              cols={50}
                              id="document_tag"
                              defaultValue={""} />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="keywords"
                            className="control-label col-sm-4"
                          >
                            Attachment Keyword for Searching Purposes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              value={props.attachmentKey}
                              onChange={(e) =>
                                props.sendValues(
                                  "attachmentKey",
                                  e.target.value
                                )
                              }
                              rows={3}
                              name="keywords"
                              cols={50}
                              id="keywords"
                            />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_type_id"
                            className="control-label col-sm-4"
                          >
                            Document Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              value={props.docType}
                              onChange={(e) =>
                                props.sendValues("docType", e.target.value)
                              }
                              id="document_type_id"
                              name="document_type_id"
                            >
                              <option value='' >
                                === Select ===
                              </option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="document_date"
                            className="control-label col-sm-4"
                          >
                            Resolution/Document Date
                          </label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.docDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                console.log(
                                  "e",
                                  moment(Date).format("DD/MM/YYYY")
                                );
                                props.sendValues("docDate", Date);
                              }}
                            >
                              <div
                                class="trash-icon"
                                onClick={() => props.sendValues("docDate", "")}
                              >
                                <span class="glyphicon glyphicon-trash"></span>{" "}
                              </div>
                            </DatePicker>
                            <p className="help-block">DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Notes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              value={props.notes}
                              onChange={(e) =>
                                props.sendValues("notes", e.target.value)
                              }
                              rows={3}
                              name="document_tag"
                              cols={50}
                              id="document_tag"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="selectedtolist"
                        type="hidden"
                        defaultValue
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="selectedcclist"
                        type="hidden"
                        defaultValue
                      />
                    </div>
                    <div className="col-sm-12" >
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                            }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      id="button"
                      value="update"
                      name="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  onClick={() => {
                    props.sendValues("editDeadlineModal", false)
                    props.sendValues("to", []);
                    props.sendValues("cc", []);
                    props.sendValues("submitDate", "");
                    props.sendValues("deadline_id", '');
                    window.location.reload();
                  }}
                  className="btn form-control"
                  id="7af0c56b-c3f6-4ab6-a487-62e2d934d524"
                  data-dismiss="modal"
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    editDeadlineModal,
    clientDeadline,
    finYear,
    deadlineDate,
    remainderDate,
    remark,
    to,
    cc,
    frequency,
    remType,
    clickStart,
    docLink,
    docId,
    attachment,
    attachmentKey,
    docType,
    docDate,
    notes,
    description,
    editDeadlineStatus,
    submitDate,
    isCompleted,
    deadline_id,
    remTypeList,
    completedfs,
    client_id
  } = state.Deadline;
  return {
    editDeadlineModal,
    clientDeadline,
    finYear,
    deadlineDate,
    remainderDate,
    remark,
    to,
    cc,
    frequency,
    remType,
    clickStart,
    docLink,
    docId,
    attachment,
    attachmentKey,
    docType,
    docDate,
    notes,
    description,
    editDeadlineStatus,
    submitDate,
    isCompleted,
    deadline_id,
    remTypeList,
    personalList: state.Personal.personalList,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
    alldocumentclientList: state.Client.alldocumentclientList,
    completedfs,
    client_id
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    allListDocuments: (data) => dispatch(client.allListDocuments(data)),
    sendValues: (k, v) => dispatch(deadline(k, v)),
    sendError: (f, v) => dispatch(client.changeClientInfo(f, v)),
    editDeadline: (input) => dispatch(editDeadline(input)),
    getDocumentList: () => dispatch(settings.getDocumentClassification()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDeadline);
