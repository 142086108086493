import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../../actions/UserManagement";

function Newuser(props) {
  const addUserFunc = (e) => {
    e.preventDefault();
    let input = {
      name: props.name,
      user_name: props.userName,
      email: props.email,
      password: props.changePassword,
      // account_status: props.accountStatus,
      // hourly_rate: props.hourlyRate,
    };
    props.addUser(input);
  };

  return (
    <Modal
      show={props.addUserShow}
      onHide={() => props.setValues("addUserShow", false)}
      className="modal bootstrap-dialog type-primary fade size-normal in"
      role="dialog"
      aria-hidden="true"
      id="2e17b74e-0885-4d61-9920-da935b688fe6"
      aria-labelledby="2e17b74e-0885-4d61-9920-da935b688fe6_title"
      tabIndex={-1}
      style={{ zIndex: 1050, display: "block" }}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="2e17b74e-0885-4d61-9920-da935b688fe6_title"
            >
              Create New
            </div>
          </div>
        </div>
        <div className="modal-body">
          {props.userApiStatus ? (
            <div
              className={`alert ${props.userApiStatus === 1 ? "" : "alert-danger"
                }`}
              role="alert"
            >
              {props.userApiStatus === 1 ? '' : props.message}
            </div>
          ) : (
            ""
          )}
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div>
                <form
                  onSubmit={(e) => addUserFunc(e)}
                  acceptCharset="UTF-8"
                  className="form-horizontal"
                >
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                  />
                  <div className="form-group">
                    <label htmlFor="name" className="control-label col-sm-4">
                      Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        minLength={3}
                        name="name"
                        onChange={(e) =>
                          props.setValues("name", e.target.value)
                        }
                        type="text"
                        id="name"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="username"
                      className="control-label col-sm-4"
                    >
                      Username
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        minLength={6}
                        onChange={(e) =>
                          props.setValues("userName", e.target.value)
                        }
                        name="username"
                        type="text"
                        id="username"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="control-label col-sm-4">
                      Email
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        rows={3}
                        name="email"
                        onChange={(e) =>
                          props.setValues("email", e.target.value)
                        }
                        type="email"
                        id="email"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className="control-label col-sm-4"
                    >
                      Change Passwords
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        placeholder="Leave blank if unchanged"
                        onChange={(e) =>
                          props.setValues("changePassword", e.target.value)
                        }
                        minLength={6}
                        name="password"
                        type="password"
                        id="password"
                      />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label
                      htmlFor="hourlyrate"
                      className="control-label col-sm-4"
                    >
                      Hourly Rate
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        step="0.01"
                        name="hourlyrate"
                        onChange={(e) =>
                          props.setValues("hourlyRate", e.target.value)
                        }
                        type="number"
                        id="hourlyrate"
                      />
                    </div>
                  </div> */}
                  {/* <div className="form-group">
                    <label htmlFor="confirmed" className="control-label col-sm-4">Account status</label>
                    <div className="col-sm-8">
                      <select className="form-control" id="confirmed" onChange={(e) => props.setValues('accountStatus', e.target.value)} name="confirmed">
                        <option value={1}>Enable</option>
                        <option value={0} selected="selected">Disable</option>
                      </select>
                    </div>
                  </div> */}
                  <div className="form-group">
                    <div
                      className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      id="errortext"
                    />
                  </div>
                  <button
                    className="form-control"
                    type="submit"
                    value="new"
                    name="action"
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                className="btn form-control"
                onClick={() =>{  props.setValues("addUserShow", false) ;  window.location.reload();  }}
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    addUserShow,
    name,
    userName,
    email,
    changePassword,
    hourlyRate,
    accountStatus,
  } = state.UserDatas;
  return {
    addUserShow,
    name,
    userName,
    email,
    changePassword,
    hourlyRate,
    accountStatus,
    userApiStatus:state.UserDatas.userApiStatus,
    message:state.UserDatas.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),
    addUser: (data) => dispatch(actions.addUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Newuser);
