import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import {
  deadline,
  addDeadline,
  getDeadlineDrop,
} from "../../../actions/clientDetails/Deadline";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import * as client from "../../../actions/Client";
import * as settings from "../../../actions/Settings";
import DatePicker from "react-datepicker";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
// import { Multiselect } from 'multiselect-react-dropdown';
import $ from "jquery";

function AddDeadline(props) {
  const [newPersonalList, setnewPersonalList] = useState([]);
  useEffect(() => {
    props.getDeadlineDrop();
    props.getDocumentList();
    props.allListDocuments(localStorage.getItem("personalId"));
    let ccList = [];
    props.sendValues("docLink", "");
    if (props.personalList.length > 0) {
      props.personalList.forEach((personal) => {
        if (personal.person_copied_status) {
          ccList.push(personal.id);
        }
      });
      props.sendValues("cc", [...props.cc, ...ccList]);
    }
    let pList = [];
    // {props.personalList.length > 0 &&
    //   props.personalList.map((personal) => {
    //     return (
    //       <option value={personal.id}>
    //         {personal.name}
    //       </option>
    //     );
    //   })}
    props.personalList.map((personal) =>
      pList.push({
        label: personal.name + "-" + personal.designation_name,
        value: personal.id,
      })
    );
    setnewPersonalList(pList);
  }, []);

  useEffect(() => {
    let personal = [...props.personalList];
    props.personalList &&
      props.personalList.map((per) => {
        per.toCheck = false;
        per.ccCheck = false;
      });
    setmailList([...personal]);
  }, [props.personalList]);

  const [emailList, setmailList] = useState([]);
  const [toList, setToList] = useState([]);
  const [ccList, setCcList] = useState([]);

  useEffect(() => {
    // const toFilterFunc = ()=>{
    let selectedToMail = [];
    var fill = emailList.filter((lis) => {
      if (lis.toCheck == true) {
        return true;
      } else {
        return false;
      }
    });
    fill.map((val) => {
      selectedToMail.push(val.id);
    });
    setToList([...selectedToMail]);
  }, [emailList]);

  const getfilename = (e) => {
    var splitedval = e.target.value.split("_");

    props.sendValues("docLink", e.target.value);
    var index = e.nativeEvent.target.selectedIndex;

    if (e.target.value != 0 && e.target.value != "") {
      props.sendValues("docLink", e.target.value);
      props.alldocumentclientList &&
        props.alldocumentclientList.map((filepathresultnn, indexvalnn) => {
          if (splitedval[0] == filepathresultnn.id) {
            document.getElementById("viewdocument").style.display = "block";
            document.getElementById("viewdocument").href =
              filepathresultnn.file_path;
          }
        });
    } else {
      document.getElementById("viewdocument").style.display = "none";
    }
  };
  // window.onmousedown = (e) => {

  //   console.log("e1", e)
  //   var el = e.target;
  //   if (el.parentNode.id == 'tolist[]') {
  //     if (el.tagName.toLowerCase() == 'option' && el.parentNode.hasAttribute('multiple')) {
  //       e.preventDefault();
  //       // let value = Array.from(
  //       //   e.target.selectedOptions,
  //       //   (option) => option.value
  //       // );
  //       let found = props.to && props.to.find((ele) => {
  //         return ele == e.target.value;
  //       });
  //       if (found == undefined) {
  //         let wholeValue = [e.target.value, ...props.to];
  //         let filteredValue = wholeValue && wholeValue.filter(function (
  //           item,
  //           pos
  //         ) {
  //           return wholeValue.indexOf(item) == pos;
  //         });
  //         props.sendValues("to", filteredValue);
  //       } else {
  //         let valfunc = (id) => id == found;
  //         let res = props.to && props.to.findIndex(valfunc);
  //         props.to.splice(res, 1);
  //         props.sendValues("to", props.to);
  //       }

  //       // toggle selection
  //       if (el.hasAttribute('selected')) el.removeAttribute('selected');
  //       else el.setAttribute('selected', '');

  //       // hack to correct buggy behavior
  //       var select = el.parentNode.cloneNode(true);
  //       el.parentNode.parentNode.replaceChild(select, el.parentNode);
  //     }
  //   };

  //   if(el.parentNode.id =="cclist"){
  //     if (el.tagName.toLowerCase() == 'option' && el.parentNode.hasAttribute('multiple')) {
  //       e.preventDefault();

  //       let found = props.cc && props.cc.find((ele) => {
  //         return ele == e.target.value;
  //       });
  //       if (found == undefined) {
  //         let wholeValue = [e.target.value, ...props.cc];
  //         let filteredValue = wholeValue && wholeValue.filter(function (
  //           item,
  //           pos
  //         ) {
  //           return wholeValue.indexOf(item) == pos;
  //         });
  //         props.sendValues("cc", filteredValue);
  //       } else {
  //         let valfunc = (id) => id == found;
  //         let res = props.cc && props.cc.findIndex(valfunc);
  //         props.cc.splice(res, 1);
  //         props.sendValues("cc", props.cc);
  //       }

  //       // toggle selection
  //       if (el.hasAttribute('selected')) el.removeAttribute('selected');
  //       else el.setAttribute('selected', '');

  //       // hack to correct buggy behavior
  //       var select = el.parentNode.cloneNode(true);
  //       el.parentNode.parentNode.replaceChild(select, el.parentNode);
  //     }

  //   }

  // }
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const handleChangeTo = (e) => {
    let found =
      props.to &&
      props.to.find((ele) => {
        return ele == e.target.value;
      });
    let alreadyIn =
      props.cc &&
      props.cc.find((ele) => {
        console.log(ele, "---", e.target.value);
        return ele == e.target.value;
      });
    if (alreadyIn == undefined) {
      if (found == undefined) {
        let wholeValue = [e.target.value, ...props.to];
        let filteredValue =
          wholeValue &&
          wholeValue.filter(function (item, pos) {
            return wholeValue.indexOf(item) == pos;
          });
        props.sendValues("to", filteredValue);
      } else {
        let valfunc = (id) => id == found;
        let res = props.to && props.to.findIndex(valfunc);
        props.to.splice(res, 1);
        props.sendValues("to", props.to);
      }
    } else {
      alert("already Selected in CC");
    }
  };
  const handleChangeCC = (e) => {
    var alreadyinTo = 0;
    let found =
      props.cc &&
      props.cc.find((ele) => {
        console.log(ele, "---", e.target.value);
        return ele == e.target.value;
      });
    let alreadyIn =
      props.to &&
      props.to.find((ele) => {
        console.log(ele, "---", e.target.value);
        return ele == e.target.value;
      });

    if (alreadyIn == undefined) {
      if (found == undefined) {
        let wholeValue = [e.target.value, ...props.cc];
        let filteredValue =
          wholeValue &&
          wholeValue.filter(function (item, pos) {
            return wholeValue.indexOf(item) == pos;
          });
        props.sendValues("cc", filteredValue);
      } else {
        let valfunc = (id) => id == found;
        let res = props.cc && props.cc.findIndex(valfunc);
        props.cc.splice(res, 1);
        props.sendValues("cc", props.cc);
      }
    } else {
      alert("Already in To Email");
    }
  };

  const addDeadlineFunc = () => {
    let rem_start;
    let completed_fs;

    if (props.clickStart == true) {
      rem_start = 1;
    } else {
      rem_start = 0;
    }
    if (props.completedfs == true) {
      completed_fs = 1;
    } else {
      completed_fs = 0;
    }
    var userData = JSON.parse(localStorage.getItem("userData"));
    let document_date = "";
    if (props.docDate != "") {
      document_date = moment(props.docDate).format("YYYY-MM-DD");
    }
    var to_ids = [];
    if (props.to != undefined || props.to.length > 0) {
      props.to.map((t) => {
        to_ids.push(t.value.toString());
      });
    }
    var cc_ids = [];
    if (props.cc != undefined || props.cc.length > 0) {
      props.cc.map((t) => {
        cc_ids.push(t.value.toString());
      });
    }
    let input = {
      company_id: userData.company_id,
      client_id: localStorage.getItem("personalId"),
      client_dedadline_task_id: props.clientDeadline,
      financial_year: moment(props.finYear).format("YYYY-MM-DD"),
      deadline_date: moment(props.deadlineDate).format("YYYY-MM-DD"),
      remainder_date: moment(props.remainderDate).format("YYYY-MM-DD"),
      frequency: props.frequency,
      reminder_type_id: props.remType,
      receipent_to: to_ids,
      receipent_cc: cc_ids,
      remarks: props.remark,
      repeatfor: props.repeatfor,
      reminder_status: rem_start,
      active_tab: props.pageSelected,
      document_link: props.docLink,
      document_id: props.docId,
      attachment: props.attachment,
      attachment_keyword: props.attachmentKey,
      document_type: props.docType,
      document_date: document_date,
      notes: props.notes,
      description: props.description,
      completedfs: completed_fs,
    };

    props.addDeadline(input);
  };

  const handleClose = () => {
    console.log("handleClose: ");
    console.log("Modal addDeadlineModal prop:", props.addDeadlineModal);

    props.sendValues("addDeadlineModal", false);
    props.sendValues("docLink", "");
    props.sendValues("finYear", "");
    props.sendValues("deadlineDate", "");
    props.sendValues("to", "");
    props.sendValues("cc", "");
    props.sendValues("frequency", "");
    props.sendValues("repeatfor", "");
    props.sendValues("remType", "");
    props.sendValues("remainderDate", "");
  };

  return (
    <Modal
      key={props.addDeadlineModal ? "modal-open" : "modal-closed"}
      onHide={handleClose}
      show={props.addDeadlineModal}
      // onHide={() => props.sendValues("addDeadlineModal", false)}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="adddeadlines"
      aria-labelledby="adddeadlines"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="3f86e5f5-88ac-463a-9a01-c04b381ac678_title"
              >
                Add Deadlines
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <form
                    onSubmit={addDeadlineFunc}
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    id="form"
                    className="form-horizontal"
                  >
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                    />
                    <div className="form-group">
                      <label
                        htmlFor="deadline_id"
                        className="control-label col-sm-4 required"
                      >
                        Client Deadline
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("clientDeadline", e.target.value)
                          }
                          required="required"
                          id="deadline_id"
                          name="deadline_id"
                        >
                          <option value="" selected="selected">
                            === Select Task Type ===
                          </option>
                          {props.clientdeadline &&
                            props.clientdeadline.map((val) => {
                              return <option value={val.id}>{val.Name}</option>;
                            })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="financialyear"
                        className="control-label col-sm-4 required"
                      >
                        Financial Year
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          required
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.finYear}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            // let deathdate = "";
                            let dtext = props.clientdeadline.filter(
                              (k) => k.id == props.clientDeadline
                            )[0];
                            let deathdate = "";
                            if (dtext["Name"] === "ACRA")
                              deathdate = moment(Date).add(6, "month");
                            if (dtext["Name"] === "ECI")
                              deathdate = moment(Date).add(3, "month");
                            if (dtext["Name"] === "TAX") {
                              let y = moment().get("year");
                              deathdate = moment(`${y}-11-30`).add("year", 1);
                            }
                            props.sendValues("finYear", Date);
                            props.sendValues(
                              "deadlineDate",
                              moment(deathdate)._d
                            );
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() => props.sendValues("finYear", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="deadlinedate"
                        className="control-label col-sm-4 required"
                      >
                        Deadline Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          required
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.deadlineDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("deadlineDate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() => props.sendValues("deadlineDate", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="description"
                        className="control-label col-sm-4"
                      >
                        Remarks
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("remark", e.target.value)
                          }
                          rows={3}
                          name="description"
                          cols={50}
                          id="description"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="tolist[]"
                        className="control-label col-sm-4"
                      >
                        To
                      </label>
                      <div className="col-sm-8">
                        {/* <pre>{JSON.stringify(props.to)}</pre> */}
                        {/* <MultiSelect
                          options={ newPersonalList }
                          value={props.to}
                          onChange={to =>
                            props.sendValues("to", to)}
                            labelledBy="Select"
                           
                        /> */}
                        <MultiSelect
                          options={newPersonalList}
                          value={props.to}
                          onChange={(to) => props.sendValues("to", to)}
                          labelledBy="Select"

                          // onChange ={(e)=>{
                          //   let tomail =[...emailList]
                          //   tomail && tomail.map((mail)=>{
                          //         if(mail.id == e.target.value){
                          //            mail.toCheck = !mail.toCheck
                          //         }
                          //   })
                          //   setmailList([...tomail])
                          //   // toFilterFunc()
                          // }}
                          // id="tolist[]"
                          // name="tolist[]"
                        >
                          {/* {props.personalList.length > 0 &&
                            props.personalList.map((personal) => {
                              return (
                                <option value={personal.id}>
                                  {personal.name}
                                </option>
                              );
                            })} */}
                        </MultiSelect>
                        {/* <Multiselect
                                      className="dropdown-toggle btn form-control"
                                      // selectedValues={this.state.addCondition[idx].value}
                                      options={emailList}
                                      // displayValue="name"
                                      // onRemove={this.onRemoveText(idx)}
                                      // onSelect={this.onSelectText(idx)}
                                      // style={{
                                      //   searchBox: {
                                      //     background: "white"
                                      //   }
                                      // }}
                                    /> */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="cclist"
                        className="control-label col-sm-4"
                      >
                        CC
                      </label>
                      <div className="col-sm-8">
                        <MultiSelect
                          options={newPersonalList}
                          value={props.cc}
                          onChange={(cc) => props.sendValues("cc", cc)}
                          labelledBy="Select"
                        >
                          {/* {props.personalList.length > 0 &&
                            props.personalList.map((personal) => {
                              return (
                                <option
                                  value={personal.id}
                                  
                                >
                                  {personal.name}
                                </option>
                              );
                            })} */}
                        </MultiSelect>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="durationtype"
                        className="control-label col-sm-4 required"
                      >
                        Frequency
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.frequency}
                          onChange={(e) =>
                            props.sendValues("frequency", e.target.value)
                          }
                          required="required"
                          id="durationtype"
                          name="durationtype"
                        >
                          <option value={0}>Please Select Frequency</option>
                          <option value={1}>Daily</option>
                          <option value={2}>Weekly</option>
                          <option value={3}>Monthly</option>
                          <option value={4}>Yearly</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="durationtype"
                        className="control-label col-sm-4 required"
                      >
                        Repeat For
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("repeatfor", e.target.value)
                          }
                          required="required"
                          id="repeatfor"
                          name="repeatfor"
                        >
                          <option value="">===select===</option>
                          <option value={2}>2 years</option>
                          <option value={3}>3 years</option>
                          <option value={5}>5 years</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="template_id"
                        className="control-label col-sm-4 required"
                      >
                        Reminder Type
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("remType", e.target.value)
                          }
                          required="required"
                          id="template_id"
                          name="template_id"
                        >
                          <option value="">===select===</option>
                          {props.remTypeList.map((val) => {
                            return (
                              <option value={val.id}>{val.email_tag}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="remainderdate"
                        className="control-label col-sm-4 required"
                      >
                        Remainder Start Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          required
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.remainderDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("remainderDate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("remainderDate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="isstopped" className="control-label col-sm-4">
                        Click to Stop Reminder
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="isstopped"
                          name="isstopped"
                          onChange={(e) =>
                            props.sendValues("clickStart", e.target.checked)
                          }
                          type="checkbox"
                          value="1"
                          className="magic-checkbox"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        for="completedfs"
                        className="control-label col-sm-4"
                      >
                        Completed FS
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="completedfs"
                          name="completedfs"
                          onChange={(e) =>
                            props.sendValues("completedfs", e.target.checked)
                          }
                          type="checkbox"
                          value="1"
                          className="magic-checkbox"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="document_link"
                        className="control-label col-sm-4"
                      >
                        Document Link
                      </label>
                      {/* onChange={(e) =>
                            props.sendValues("docLink", e.target.value)
                          } */}
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange={(e) => getfilename(e)}
                          id="document_link"
                          name="document_link"
                        >
                          <option value="" selected="selected">
                            View from the drop-down list / Select New Doc to
                            upload new
                          </option>
                          <option value={0}>New Document</option>
                          {props.alldocumentclientList &&
                            props.alldocumentclientList.map(
                              (filepathresult, index) => {
                                return (
                                  <>
                                    <option
                                      value={
                                        filepathresult.id +
                                        "_" +
                                        filepathresult.document_type +
                                        "_" +
                                        filepathresult.indexno
                                      }
                                    >
                                      {" "}
                                      {filepathresult.file_path
                                        .split("/")
                                        .pop()
                                        .split("?")[0]
                                        .split("#")[0]
                                        .replace(/[/\\?%*:|"<>]/g, "-")}
                                    </option>
                                  </>
                                );
                              }
                            )}
                        </select>
                        <a
                          href=""
                          id="viewdocument"
                          style={{ display: "none" }}
                          target="_blank"
                        >
                          View Selected Document
                        </a>
                      </div>
                    </div>
                    {props.docLink == "0" ? (
                      <div>
                        <div className="form-group">
                          <label
                            htmlFor="document_id"
                            className="control-label col-sm-4"
                          >
                            Document ID
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("docId", e.target.value)
                              }
                              name="document_id"
                              type="text"
                              id="document_id"
                            />
                            <p className="help-block">
                              Must be unique ID with this client{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="original_filename"
                            className="control-label col-sm-4"
                          >
                            Attachment
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                if (e.target.files[0].size <= 2000000) {
                                  props.sendValues(
                                    "attachment",
                                    e.target.files[0]
                                  );
                                } else {
                                  props.sendValues("attachment", "");
                                  e.target.value = "";
                                  props.sendError("clientApiStatus", 2);
                                  props.sendError(
                                    "message",
                                    "File size limit only 2Mb"
                                  );
                                  setTimeout(() => {
                                    props.sendError("clientApiStatus", 0);
                                    props.sendError("message", "");
                                  }, 2500);
                                }
                              }}
                              maxLength={4096}
                              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                              name="original_filename"
                              type="file"
                              id="original_filename"
                            />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Description
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              rows={3}
                              onChange={(e) => {
                                props.sendValues("description", e.target.value);
                              }}
                              name="document_tag"
                              cols={50}
                              id="document_tag"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="keywords"
                            className="control-label col-sm-4"
                          >
                            Attachment Keyword for Searching Purposes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues(
                                  "attachmentKey",
                                  e.target.value
                                )
                              }
                              rows={3}
                              name="keywords"
                              cols={50}
                              id="keywords"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_type_id"
                            className="control-label col-sm-4"
                          >
                            Document Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("docType", e.target.value)
                              }
                              id="document_type_id"
                              name="document_type_id"
                            >
                              <option value="">=== Select ===</option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_date"
                            className="control-label col-sm-4"
                          >
                            Resolution/Document Date
                          </label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.docDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                props.sendValues("docDate", Date);
                              }}
                            >
                              <div
                                class="trash-icon"
                                onClick={() => props.sendValues("docDate", "")}
                              >
                                <span class="glyphicon glyphicon-trash"></span>{" "}
                              </div>
                            </DatePicker>
                            <p className="help-block">DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Notes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("notes", e.target.value)
                              }
                              rows={3}
                              name="document_tag"
                              cols={50}
                              id="document_tag"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="selectedtolist"
                        type="hidden"
                        defaultValue
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="selectedcclist"
                        type="hidden"
                        defaultValue
                      />
                    </div>
                    <div className="col-sm-12">
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${
                            props.clientApiStatus === 1 ? "" : "alert-danger"
                          }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      id="addnew"
                      value="new"
                      name="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control"
                  id="3d93cf1d-cef7-4a8a-b2db-07bced4c4a60"
                  onClick={handleClose} //
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    addDeadlineModal,
    clientDeadline,
    finYear,
    deadlineDate,
    remainderDate,
    remark,
    to,
    cc,
    frequency,
    repeatfor,
    remType,
    clickStart,
    docLink,
    docId,
    attachment,
    attachmentKey,
    docType,
    docDate,
    notes,
    description,
    addDeadlineStatus,
    clientdeadline,
    remTypeList,
    completedfs,
  } = state.Deadline;
  return {
    addDeadlineModal,
    clientDeadline,
    finYear,
    deadlineDate,
    remainderDate,
    remark,
    to,
    cc,
    frequency,
    repeatfor,
    remType,
    clickStart,
    docLink,
    docId,
    attachment,
    attachmentKey,
    docType,
    docDate,
    notes,
    description,
    addDeadlineStatus,
    clientdeadline,
    remTypeList,
    personalList: state.Personal.personalList,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
    alldocumentclientList: state.Client.alldocumentclientList,
    completedfs,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(deadline(k, v)),
    sendError: (f, v) => dispatch(client.changeClientInfo(f, v)),
    addDeadline: (input) => dispatch(addDeadline(input)),
    getDeadlineDrop: () => dispatch(getDeadlineDrop()),
    getDocumentList: () => dispatch(settings.getDocumentClassification()),
    allListDocuments: (data) => dispatch(client.allListDocuments(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDeadline);
