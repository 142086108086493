/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";
import * as settings from "../../../actions/Settings";
import { changeClientInfo } from "../../../actions/Client";
import { MultiSelect } from "react-multi-select-component";
function DocumentType(props) {
  useEffect(() => {
    //props.getDocumentTypeList();
    props.getDocumentList();
  }, []);
  const [newPersonalList, setnewPersonalList] = useState([]);
  useEffect(() => {
    let pList = [];
    props.sendValues("to", []);
    props.documentTypeList.map((personal) =>
      pList.push({ label: personal.Name, value: personal.id })
    );
    setnewPersonalList(pList);
  }, [props.documentTypeList]);
  const [documentCategoryField, setdocumentCategoryField] = useState("");
  const getalltypesbyid = (id) => {
    props.changeClientInfo("selectedid", id);
    props.getDocumentListbyid(id);

    localStorage.removeItem("select_category");

    let select_category = "";

    select_category = props.DocumentTypeListnew.find(
      (item) => String(item.id) === String(id)
    );
    if (select_category) {
      localStorage.setItem("select_category", select_category.name);
      console.log("select_category: ", select_category.name);
    } else {
      console.warn("No category found for the given id.");
    }
  };
  // console.log(props.selectedid, "selectedid");
  const continueFunc = () => {
    let lengthOfSelectedItems = props.to.length;

    if (lengthOfSelectedItems === undefined) {
      console.log("if values ==========", props.to[0].value);
      props.changeClientInfo("clientApiStatus", 2);
      props.changeClientInfo("message", "Please Select Document Type ");
      setTimeout(() => {
        props.changeClientInfo("clientApiStatus", 0);
        props.changeClientInfo("message", "");
      }, 2500);
    } else {
      if (lengthOfSelectedItems > 0) {
        props.sendValues("lengtharray", lengthOfSelectedItems);
        props.sendValues("nextValue", 1);
        props.sendValues("arrayselectDocumentType", props.to);
        props.sendValues("selectDocumentType", props.to[0].value);
        console.log("else values ==========", props.to[0].value);
        props.sendDocTypeAndGetDocument(props.to[0].value);
        props.getDefaultPlaceHolder(props.to[0].value);
      } else {
        props.changeClientInfo("clientApiStatus", 2);
        props.changeClientInfo("message", "Please Select Document Type ");
        setTimeout(() => {
          props.changeClientInfo("clientApiStatus", 0);
          props.changeClientInfo("message", "");
        }, 2500);
      }
    }
  };
  const handleToChange = (to) => {
    props.sendValues("updatedTo", to);
  };

  const handleRefresh = () => {
    let newTo = [...props.to, ...props.updatedTo];
    props.sendValues("to", newTo);
    props.sendValues("updatedTo", []);
  };

  const handleRemove = (value, index) => {
    const updatedTo = props.to.filter((item, i) => i !== index);
    props.sendValues("to", updatedTo);
  };
  const handleRemoveTo = (value, index) => {
    const NewupdatedTo = props.updatedTo.filter((item, i) => i !== index);
    props.sendValues("updatedTo", NewupdatedTo);
  };

  const handleClose = () => {
    console.log("handleClose: ");
      props.sendValues("selectDocumentModal", false);

  };

  return (
    <Modal
      show={props.selectDocumentModal}
      key={props.selectDocumentModal ? "modal-open" : "modal-closed"}
    
      onHide={handleClose}

      id="myModal"
      className="modal fade in"
      style={{ display: "block" }}
    >
      <div className="modal-content wd-100">
        <div className="modal-header text-center">
          {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
          <h4 className="modal-title blue-text">Select Document Type</h4>
        </div>
        <div className="modal-body wd-100">
          {props.clientApiStatus ? (
            <div
              className={`alert ${
                props.clientApiStatus === 1 ? "" : "alert-danger"
              }`}
              role="alert"
            >
              {props.clientApiStatus === 1 ? "" : props.message}
            </div>
          ) : (
            ""
          )}
          <div className="wd-95">
            <div className="form-group">
              <label
                htmlFor="Document"
                className="control-label col-sm-4 required"
              >
                Document Category
              </label>
              <div className="col-sm-8">
                <select
                  className="form-control"
                  name="document_category"
                  id="document_category"
                  onChange={(e) => {
                    getalltypesbyid(e.target.value);
                  }}
                >
                  <option value="0">=== Select ===</option>
                  {props.DocumentTypeListnew &&
                    props.DocumentTypeListnew.map((res, index) => {
                      return (
                        <>
                          <option value={res.id}>{res.name}</option>
                        </>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="Document"
                className="control-label col-sm-4 required"
              >
                Document Type
              </label>
              <div className="col-sm-8">
                <MultiSelect
                  options={newPersonalList}
                  value={props.updatedTo}
                  onChange={handleToChange}
                />
                <div style={{ marginLeft: "70px", marginBottom: "20px" }}>
                  <button
                    onClick={handleRefresh}
                    style={{
                      paddingLeft: "10px",
                      borderRadius: "20px",
                      paddingRight: "10px",
                      marginRight: "10px",
                      marginTop: "20px",
                    }}
                  >
                    Add
                  </button>
                  <button
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "20px",
                    }}
                    onClick={() => props.sendValues("to", [])}
                  >
                    Delete All
                  </button>
                </div>
                <ul>
                  {props.updatedTo.map((k, i) => (
                    <li key={i}>
                      {k.label}
                      {"  "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveTo(k.value, i)}
                      >
                        X
                      </span>
                    </li>
                  ))}
                  <span>------------------------------------------------</span>
                  {props.to.map((k, i) => (
                    <li key={i}>
                      {k.label}
                      {"  "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemove(k.value, i)}
                      >
                        X
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="button-wrapper mt-70 wd-100">
              <button
                className="btn gray-border-btn  "
                onClick={handleClose} 
              >
                Cancel
              </button>
              <button className="btn blue-btn ml-10" onClick={continueFunc}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    selectDocumentModal,
    documentTypeList,
    selectDocumentType,
    to,
    updatedTo,
    lengtharray,
  } = state.Document;

  return {
    selectDocumentModal,
    documentTypeList,
    to,
    updatedTo,
    selectDocumentType,
    lengtharray,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeListnew: state.Settings.DocumentTypeListnew,
    selectedid: state.Client.selectedid,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentList: () => dispatch(settings.getDocumentClassification_new()),
    getDocumentListbyid: (data) =>
      dispatch(settings.getDocumentClassificationbyid(data)),
    getDocumentTypeList: () => dispatch(actions.getDocumentTypeList()),
    sendDocTypeAndGetDocument: (type) =>
      dispatch(actions.sendDocTypeAndGetDocument(type)),
    getDefaultPlaceHolder: (type) =>
      dispatch(actions.getDefaultPlaceHolder(type)),
    changeClientInfo: (k, d) => dispatch(changeClientInfo(k, d)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentType);
