const init = {
  otpstatus:2,
  documentlist:"",
  signatureListdata: [],
  agreeListdata:[],
  singlePersonList:[],
  previewSign:[],
  clientList: [],
  alldocumentclientList: [],
  alldocumentclientList_show: [],
  pageSelected: "personal",
  company_modal_show: false,
  bulk_upload: false,
  client_drop: false,
  company_name: "",
  registration_no: "",
  incorporation_date: "",
  idshowelement:"",
  currency: "",
  clientstatus: "",
  address: "",
  referral: "",
  remarks: "",
  flag: "",
  document_id: "",
  attachment: "",
  attachment_keyword: "",
  document_type: "",
  document_date: "",
  notes: "",
  addClientErrors: {},
  addClientStatus: 0,
  company_edit_modal_show: false,
  document_comment_modal_show: false,
  updated_comment:"",
  updated_status:0,
  updated_doc_id:"",
  document_link: 0,
  company_id: "",
  docId:"",
  docFlag:"",
  client_id: "",
  directorcount:0,
  secratorycount:0,
  shareholdercount:0,
  corporaterepresentativecount:0,
  deleteCompany: false,
  EditClientStatus: 0,
  deleteClientStatus: 0,
  currencyList: [],
  clientApiStatus: 0,
  message: "",
  selectedid: "",
  approve_modal: false,
  approveStatus: "",
  userPermissionsList: [],
  attachmentModal:false,
  editdocumentmodel:false,
  adddocumentmodel:false,
  client_key:"",
  processModal:"none",
  List1: [],
  clientIdList : []
  
};

export const Client = (state = init, action) => {
  switch (action.type) {
    case "SETTINGS_DATA":
      return { ...state, [action.field]: action.value };
    case "CLIENT_LIST":
      return { ...state, clientList: action.data };
    case "ALL_DOCUMENT":
      return { ...state, alldocumentclientList: action.data };
    case "ALL_DOCUMENT_SHOW":
        return { ...state, alldocumentclientList_show: action.data };
    case "AGREE_LIST":
      return { ...state, agreeListdata: action.data };  
    case "SIGN_LIST":
      return { ...state, signatureListdata: action.data };
    case "SINGLE_PERSON":
      return { ...state, singlePersonList: action.data };
    case "PREVIEW_SIGN":
      return { ...state, previewSign: action.data };
    case "CHANGE_CLIENT_INFO":
      return { ...state, [action.field]: action.value };
    case "UPDATE_CLIENT_ERRORS":
      return {
        ...state,
        addClientErrors: { ...state.addClientErrors, ...action.data },
      };
    case "RESET_CLIENT_INFO":
      return {
        ...state,
        company_name: "",
        registration_no: "",
        incorporation_date: "",
        currency: "",
        address: "",
        referral: "",
        remarks: "",
        flag: "",
        document_id: "",
        attachment: "",
        attachment_keyword: "",
        document_type: "",
        document_date: "",
        notes: "",
      };
    default:
      return state;
  }
};
