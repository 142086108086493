import React, { Fragment } from "react";
import { Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import * as actions from "../../../actions/UserManagement";



function Edituser(props) {

  const editUserFunc = (e) => {
    e.preventDefault();
    let input = {
      user_id: props.user_id,
      name: props.name,
      user_name: props.userName,
      email: props.email,
      password: props.changePassword,
      account_status: props.accountStatus,
      hourly_rate: props.hourlyRate,
    };
    props.editUser(input);

  };

  return (
    <Modal show={props.editUserShow} onHide={() => props.setValues('editUserShow', false)} className="modal bootstrap-dialog type-primary fade size-normal in" role="dialog" aria-hidden="true" id="fec59728-a3a6-46f1-a45c-e82c555c2d33" aria-labelledby="fec59728-a3a6-46f1-a45c-e82c555c2d33_title" tabIndex={-1} style={{ zIndex: 1050, display: 'block' }}>
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div className="bootstrap-dialog-close-button" style={{ display: 'none' }}><button className="close" aria-label="close">×</button></div>
            <div className="bootstrap-dialog-title" id="fec59728-a3a6-46f1-a45c-e82c555c2d33_title">Edit</div>
          </div>
        </div>
        <div className="modal-body">
          {props.userApiStatus ? (
            <div
              className={`alert ${props.userApiStatus === 1 ? "" : "alert-danger"
                }`}
              role="alert"
            >
              {props.userApiStatus === 1 ? '' : props.message}
            </div>
          ) : (
            ""
          )}
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div className="bootstrap-dialog-body">
                <form onSubmit={(e) => editUserFunc(e)} acceptCharset="UTF-8" className="form-horizontal"><input name="_method" type="hidden" defaultValue="PUT" /><input name="_token" type="hidden" defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446" />
                  <div className="form-group">
                    <label htmlFor="name" className="control-label col-sm-4">Name</label>
                    <div className="col-sm-8">
                      <input className="form-control" minLength={3} value={props.name} onChange={(e) => props.setValues('name', e.target.value)} name="name" type="text" defaultValue="Gerard" id="name" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="username" className="control-label col-sm-4">Username</label>
                    <div className="col-sm-8">
                      <input className="form-control" minLength={6} name="username" value={props.userName} onChange={(e) => props.setValues('userName', e.target.value)} type="text" defaultValue="gerard" id="username" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="control-label col-sm-4">Email</label>
                    <div className="col-sm-8">
                      <input className="form-control" rows={3} name="email" type="email" value={props.email} onChange={(e) => props.setValues('email', e.target.value)} defaultValue="gerard@gbsc.com.sg" id="email" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="control-label col-sm-4">Change Passwords</label>
                    <div className="col-sm-8">
                      <input className="form-control" placeholder="Leave blank if unchanged" value={props.changePassword} onChange={(e) => props.setValues('changePassword', e.target.value)} minLength={6} name="password" type="password" id="password" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="hourlyrate" className="control-label col-sm-4">Hourly Rate</label>
                    <div className="col-sm-8">
                      <input className="form-control" step="0.01" name="hourlyrate" type="number" value={props.hourlyRate} defaultValue={200} onChange={(e) => props.setValues('hourlyRate', e.target.value)} id="hourlyrate" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmed" className="control-label col-sm-4">Account status</label>
                    <div className="col-sm-8">
                      <select className="form-control" id="confirmed" name="confirmed" value={props.accountStatus} onChange={(e) => props.setValues('accountStatus', e.target.value)}>
                        <option value={1} selected="selected">Enable</option>
                        <option value={0}>Disable</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12" id="errortext" />
                  </div>
                  <button className="btn form-control btn-success"  type="submit" value="update" name="action"><span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons"><button className="btn form-control" onClick={() => {props.setValues('editUserShow', false);   window.location.reload();} }><span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />Close</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};



const mapStateToProps = (state, ownProps) => {
  const {
    editUserShow,
    name,
    userName,
    email,
    changePassword,
    hourlyRate,
    accountStatus,
    user_id,
  } = state.UserDatas;
  return {
    editUserShow,
    name,
    userName,
    email,
    changePassword,
    hourlyRate,
    accountStatus,
    user_id,
    userApiStatus: state.UserDatas.userApiStatus,
    message: state.UserDatas.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),
    editUser: (data) => dispatch(actions.editUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edituser);